// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  // ffColorIdを指定する
  colorId: number
}

export default function({ colorId, ...rest }: Props) {
  return <Color colorId={colorId} {...rest} />
}

const Color = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: ${({ colorId }) => `url(/img/colors/${colorId}.png)`};
  background-size: cover;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: bottom;
`
