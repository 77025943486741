// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, lifecycle, withState } from 'recompose'
import { feetActionCreators } from '@src/redux/modules/feet'
import { appActionCreators } from '@src/redux/modules/app'
import FootModel from '@src/models/FootModel'
import FavoriteItemModel from '@src/models/FavoriteItemModel'
const CONFIG: any = process.env.CONFIG

type ExternalProps = {
  match: any
}

// eslint-disable-next-line
type Props = ExternalProps & {
  loadData: string => Promise<void>,

  foot: ?FootModel,
  fitItems: Array<FavoriteItemModel>,
  appActions: { [string]: Function },
  feetActions: { [string]: Function },
  updateIs404PageShown: boolean => void,
  updatePumaUrl: string => void
}

export const enhanceConnection = compose(
  connect(
    state => ({
      foot: state.feet.foot,
      fitItems: state.feet.fitItems
    }),
    dispatch => ({
      feetActions: bindActionCreators({ ...feetActionCreators }, dispatch),
      appActions: bindActionCreators({ ...appActionCreators }, dispatch)
    })
  ),

  withState('is404PageShown', 'updateIs404PageShown', false),
  withState('pumaUrl', 'updatePumaUrl', null),

  lifecycle({
    async componentDidMount() {
      const {
        appActions,
        feetActions,
        match,
        updateIs404PageShown,
        updatePumaUrl
      } = this.props
      if (!match.params || !match.params.encoded_id) {
        updateIs404PageShown(true)
        return
      }
      const encodedId: string = match.params.encoded_id

      try {
        await feetActions.clear()
        await feetActions.fetchFromQRParameter(encodedId)
      } catch (err) {
        console.log(err)
        updateIs404PageShown(true)
        return
      }

      // QRコードから流入の場合、登録・ログイン時のひも付け処理用にfootUuidを保管する
      appActions.setFootUuidInflowedFromQrCode({
        footUuid: window.atob(encodedId)
      })

      // PUMAの判定処理
      const clientcode: array= window.atob(encodedId).split("-")[0]
      let pumaData = CONFIG.puma
      if (pumaData[clientcode]){

        console.log("URL -->", clientcode, pumaData[clientcode])
        updatePumaUrl(pumaData.url+pumaData[clientcode].params)

      }



      // QRコードから読み込みしたら、画面遷移する。
      //window.location.href = '/signup';

    }
  })
)
