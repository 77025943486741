// @flow
import produce from 'immer'
import api from '@src/api'
// import { createAction } from 'redux-actions'
import { createActionThunk } from 'redux-thunk-actions'

import { withProps } from 'recompose'

type TagStandard = {
  id: number,
  name: string
}
type HeelHeight = {
  label: string,
  lower: null | number,
  upper: null | number
}
type ShoeType = {
  id: number,
  name: string,
  parent: number
}
type ExistOrNot = 'あり' | 'なし'

type TagsRaw = {
  category: Array<TagStandard>,
  color: Array<TagStandard>,
  heelHeight: Array<HeelHeight>,
  heelType: Array<TagStandard>,
  material: Array<TagStandard>,
  pattern: Array<TagStandard>,
  shoeType: Array<ShoeType>,
  shoelace: Array<ExistOrNot>,
  strap: Array<ExistOrNot>,
  target: Array<TagStandard>,
  toes: Array<TagStandard>
}

/*==================================
* Actions
==================================*/
const THUNK = {}
const REDUCER = {}

// Public
THUNK.FETCH = 'master/THUNK.FETCH'

// Private
REDUCER.SET_TAGS = 'store/REDUCER.SET_TAGS'

/*==================================
* Action Creators
==================================*/
export const masterActionCreators = {
  fetch: createActionThunk(
    THUNK.FETCH,
    async ({ dispatch }): Promise<void> => {
      const result = await api.getRecommendationKeyword()
      dispatch({
        type: REDUCER.SET_TAGS,
        payload: {
          tags: result.data.tags
        }
      })
    }
  )
}

/*==================================
Initial State
==================================*/
type State = {
  tags: ?TagsRaw
}

const initialState = {
  tags: null
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.SET_TAGS: {
        draft.tags = action.payload.tags
        return
      }
    }
  })
}

/*==================================
* Selector
==================================*/
type WithCategoryNames = {
  categoryNames: Array<string>
}
export const withCategoryNames = withProps(
  ({ master }): WithCategoryNames => {
    const categoryNames = master.tags.category.map(
      (category: TagStandard) => category.name
    )
    return {
      categoryNames
    }
  }
)

type WithRecommendationTagsCollection = {
  recommendationTagsCollection: {
    [tagKey: string]: Array<TagStandard>
  }
}
export const withRecommendationTagsCollection = withProps(
  ({ master }): WithRecommendationTagsCollection => {
    return {
      recommendationTagsCollection: {
        categoryName: master.tags.category,
        targetName: master.tags.target,
        shoeTypeName: master.tags.shoeType
      }
    }
  }
)
