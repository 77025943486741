// @flow
import React from 'react'
import styled from 'styled-components'

import DefaultPageLayout from '@src/components/templates/DefaultPageLayout'
import Section from '@src/components/templates/Section'
import Inner from '@src/components/templates/Inner'
import PageTitle from '@src/components/molecules/PageTitle'
import FormRow from '@src/components/molecules/FormRow'
import CheckListItem from '@src/components/molecules/CheckListItem'
import Input from '@src/components/atoms/forms/Input'
import Select from '@src/components/atoms/forms/Select'
import Radio from '@src/components/atoms/forms/Radio'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'

import theme from '@src/misc/theme'

import { FormConstants } from '@src/constants'
const options = FormConstants.selectOptions
const inputBlockTitles = FormConstants.inputBlockTitles

type ExternalProps = {
  formData: {
    nickname: string,
    email: string,
    password: string,
    passwordRepeated: string,
    birthdayYear: string,
    birthdayMonth: string,
    birthdayDay: string,
    gender: string,
    address: string,
    occupation: string
  },
  // options: {
  //   birthdayYear: Array<{ value: number, label: number }>,
  //   birthdayMonth: Array<{ value: number, label: number }>,
  //   birthdayDay: Array<{ value: number, label: number }>,
  //   address: Array<{ value: string, label: string }>,
  //   occupation: Array<{ value: string, label: string }>
  // },
  validationErrorInfo: { [errorInfoKey: string]: string },
  setFormValue: (parameterKey: string, value: string) => void,

  isTermsRead: boolean,
  setTermsRead: boolean => void,
  handleNext: () => void
}
type Props = ExternalProps

export default function Presentation({
  formData,
  setFormValue,
  validationErrorInfo,
  isTermsRead,
  setTermsRead,
  handleNext
}: Props) {
  return (
    <DefaultPageLayout>
      <Section>
        <Inner>
          <PageTitle title="新規メンバー登録" marginBottom={5} />
          <Text textAlign={'center'} margin={15}>
            ※必須項目です。
          </Text>

          <FormRow
            label={inputBlockTitles.nickname}
            errorMessage={validationErrorInfo.nickname}
            required={true}
          >
            <Input
              type="text"
              placeholder="20字以内で入力"
              value={formData.nickname}
              onChange={e => {
                setFormValue('nickname', e.target.value)
              }}
            />
          </FormRow>

          <FormRow
            label={inputBlockTitles.email}
            errorMessage={validationErrorInfo.email}
            note={'※登録後はメールアドレスは変更できません'}
            required={true}
          >
            <Input
              type="email"
              placeholder="半角英数字で入力"
              value={formData.email}
              onChange={e => {
                setFormValue('email', e.target.value)
              }}
            />
          </FormRow>

          <FormRow
            label={inputBlockTitles.password}
            required={true}
            errorMessage={validationErrorInfo.password}
          >
            <Input
              type="password"
              placeholder="半角英数字（大文字・小文字・数字を含んだ）8文字以上で入力"
              value={formData.password}
              onChange={e => {
                setFormValue('password', e.target.value)
              }}
            />
            <Input
              type="password"
              placeholder="パスワード確認"
              value={formData.passwordRepeated}
              onChange={e => {
                setFormValue('passwordRepeated', e.target.value)
              }}
            />
          </FormRow>

          <FormRow
            label={inputBlockTitles.birthDate}
            errorMessage={validationErrorInfo.birthDate}
          >
            <Row>
              <Select
                options={options.birthdayYear}
                defaultOption={'年'}
                value={formData.birthdayYear}
                onChange={e => {
                  setFormValue('birthdayYear', e.target.value)
                }}
              />
              <Select
                options={options.birthdayMonth}
                defaultOption={'月'}
                value={formData.birthdayMonth}
                onChange={e => {
                  setFormValue('birthdayMonth', e.target.value)
                }}
              />
              <Select
                options={options.birthdayDay}
                defaultOption={'日'}
                value={formData.birthdayDay}
                onChange={e => {
                  setFormValue('birthdayDay', e.target.value)
                }}
              />
            </Row>
          </FormRow>

          <FormRow
            label={inputBlockTitles.gender}
            errorMessage={validationErrorInfo.gender}
          >
            <Radio
              name={'gender'}
              value={'男性'}
              checked={formData.gender === '男性'}
              label={'男性'}
              onChange={e => {
                setFormValue('gender', e.target.value)
              }}
            />
            <Radio
              name={'gender'}
              value={'女性'}
              checked={formData.gender === '女性'}
              label={'女性'}
              onChange={e => {
                setFormValue('gender', e.target.value)
              }}
            />
          </FormRow>

          <FormRow
            label={inputBlockTitles.address}
            errorMessage={validationErrorInfo.address}
          >
            <Row>
              <Select
                options={options.address}
                defaultOption={'都道府県を選択'}
                value={formData.address}
                onChange={e => {
                  setFormValue('address', e.target.value)
                }}
              />
            </Row>
          </FormRow>

          <FormRow
            label={inputBlockTitles.occupation}
            errorMessage={validationErrorInfo.occupation}
          >
            <Row>
              <Select
                options={options.occupation}
                defaultOption={'職業を選択'}
                value={formData.occupation}
                onChange={e => {
                  setFormValue('occupation', e.target.value)
                }}
              />
            </Row>
          </FormRow>

          <FormRow label={''} errorMessage={validationErrorInfo.checkTermsRead}>
            <CheckListItem
              name={'checkTermsRead'}
              checked={isTermsRead}
              onChange={e => {
                setTermsRead(e.target.checked)
              }}
            >
              <Text
                color={theme.color.gray}
                textAlign={'center'}
                size={12}
                margin={0}
              >
                <a
                  href={
                    'https://flicfit.zendesk.com/hc/ja/articles/360020710432'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  利用規約
                </a>
                に同意する
              </Text>
            </CheckListItem>
          </FormRow>

          <div>
            <Button onClick={() => handleNext()} type={'primary'}>
              登録する
            </Button>
          </div>
        </Inner>
      </Section>
    </DefaultPageLayout>
  )
}

const Row = styled.div`
  display: flex;
  select {
    margin-right: 5px;
  }
  select:nth-child(1) {
    flex-basis: auto;
    flex-grow: 1;
  }
  select:nth-child(2) {
    flex-basis: 6em;
  }
  select:nth-child(3) {
    flex-basis: 6em;
  }
  select:last-child {
    margin-right: 0;
  }
`
