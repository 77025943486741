// @flow
import api from '@src/api'
import FormValidation from '@src/helpers/FormValidation'
import commonHandlers from '@src/commonHandlers'

type Hash = { [key: string]: string }

const logicHelper = {
  //
  // パスワードリセット実行
  //
  submitResetPassword: async function(
    data: Hash,
    setValidationErrorInfo: Hash => void,
    appActions: any,
    code :string,
  ): Promise<boolean> {
    // (1) クライアントサイドバリデーション
    const validation = new FormValidation(data)
    validation
      .setRequirements({
        email: true,
        newPassword: { errorInfoKey: 'newPassword' },
        newPasswordRepeated: { errorInfoKey: 'newPassword' }
      })
      .checkRequirements()
      .checkEquality(
        'newPassword',
        'newPasswordRepeated',
        'パスワードが一致しません'
      )

    if (validation.hasValidationError) {
      setValidationErrorInfo(validation.validationErrorInfo)
      return false
    }

    // (2) リクエスト
    appActions.showLoading()
    const result = await api.resetPasswordSubmit(
      data.email,
      code,
      data.newPassword
    )
    appActions.hideLoading()

    if (result.isSucceeded) {
      // 自動ログイン用にlocalStorageに保存
      localStorage.setItem('flicfitAuthToken', result.data.jwt)
      localStorage.setItem('flicfitType', 'email')
      return true
    }

    const error =  result.error.responseData 
    if(  error.message ){
      if( error.message.email){
          setValidationErrorInfo({
            email: "メールアドレスが間違っているか、URLの認証が切れている可能性があります。",
          })
      }
    }

    // (3) Amplifyエラーハンドリング
    /*
    commonHandlers.handleAmplifyErrorWithAnalyzeErrorCode(
      result.error,
      (code: string, PRESET_MESSAGES: Object) => {
        if (
          code === 'CodeMismatchException' ||
          code === 'ExpiredCodeException'
        ) {
          // メールアドレス無効, 期限切れ, 有効なコードではない
          setValidationErrorInfo({
            email: PRESET_MESSAGES.NO_MESSAGE,
            code: PRESET_MESSAGES.INVALID_CONFIRMATION_CODE
          })
        } else if (
          code === 'InvalidParameterException' ||
          code === 'InvalidPasswordException'
        ) {
          // 新しいパスワードのバリデーションエラー
          setValidationErrorInfo({
            newPassword: PRESET_MESSAGES.INVALID_PASSWORD
          })
        } else {
          return true
        }
      }
    )
    */
    //
    return false
  }
}

export default logicHelper
