// @flow
import * as React from 'react'
import styled from 'styled-components'

import Text from '@src/components/atoms/Text'

import icoShopImg from '@src/assets/img/ico_shop.png'

type Props = {
  name: string,
  isFitted: boolean
}

export default function({ name, isFitted }: Props) {
  return (
    <StoreName>
      <Text size={10}>{name}</Text>
      {isFitted && <img src={icoShopImg} alt="" width={13} />}
    </StoreName>
  )
}

const StoreName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
