// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { appActionCreators } from '@src/redux/modules/app'
import { userActionCreators } from '@src/redux/modules/user'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import logicHelper from './logicHelper'
import api from '@src/api'
import commonHandlers from '@src/commonHandlers'

import UserInfoModel from '@src/models/UserInfoModel'

type FormData = {
  nickname: string,
  oldPassword: string,
  newPassword: string,
  newPasswordRepeated: string,
  birthdayYear: string,
  birthdayMonth: string,
  birthdayDay: string,
  gender: string,
  address: string,
  occupation: string
}

const defaultFormData = function(): FormData {
  return {
    nickname: '',
    oldPassword: '',
    newPassword: '',
    newPasswordRepeated: '',
    birthdayYear: '',
    birthdayMonth: '',
    birthdayDay: '',
    gender: '',
    address: '',
    occupation: ''
  }
}

type ValidationErrorInfo = {
  [name: string]: string
}

type Props = {
  history: any,
  match: any,
  appActions: any,
  userActions: any,

  currentPropertyName: string,
  formData: FormData,
  validationErrorInfo: ValidationErrorInfo,

  setCurrentPropertyName: any => any,
  setFormData: any => any,
  setValidationErrorInfo: any => any,

  userInfo: UserInfoModel,
  userProfileImgUrl: '' | string
}

export const enhanceConnection = compose(
  connect(
    state => ({
      userInfo: state.user.data.info,
      userProfileImgUrl: state.user.data.info.profileImgUrl
    }),
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      userActions: bindActionCreators({ ...userActionCreators }, dispatch)
    })
  ),

  withState('currentPropertyName', 'setCurrentPropertyName', ''),
  withState('formData', 'setFormData', defaultFormData()),
  withState('validationErrorInfo', 'setValidationErrorInfo', {}),

  withHandlers({
    setFormValue: ({ formData, setFormData }: Props) => (
      key: string,
      value: string
    ) => {
      setFormData({
        ...formData,
        [key]: value
      })
    },

    handleProfileImageFileChange: ({
      appActions,
      userActions
    }: Props) => async e => {
      const file = e.target.files[0]
      const fileName = file.name
      const formData = new window.FormData()
      formData.append('upfile', file)
      formData.append('filename', fileName)
      //
      appActions.showLoading()
      try {
        await api.updateUserProfileImage(formData)
        await userActions.fetchUser()
      } catch (err) {
        commonHandlers.handleAsyncLogicError(err)
        return
      } finally {
        appActions.hideLoading()
      }
    },

    handleClickCancel: ({ history }: Props) => () => {
      history.goBack()
    },

    handleClickSave: ({
      history,
      appActions,
      userActions,
      userInfo,
      currentPropertyName,
      formData,
      setValidationErrorInfo
    }: Props) => async () => {
      const data = { ...formData }

      // バリデーションエラー表示の初期化
      setValidationErrorInfo({})

      if (currentPropertyName === 'password') {
        const isSucceeded = await logicHelper.savePassword(
          userInfo.email,
          data,
          setValidationErrorInfo,
          appActions
        )
        if (!isSucceeded) return
      } else {
        const isSucceeded = await logicHelper.saveAccountInfo(
          data,
          setValidationErrorInfo,
          appActions,
          userActions
        )
        if (!isSucceeded) return
      }

      history.goBack()
    }
  }),

  lifecycle({
    async componentDidMount() {
      const {
        match,
        formData,
        setFormData,
        setCurrentPropertyName
      } = this.props
      const userInfo = { ...this.props.userInfo }



      console.log("AAGE BIRTH DATE ---- ", userInfo)
      const [year, month, day] = userInfo.birthDate ? userInfo.birthDate
        .split('-')
        .map(string => parseInt(string, 10).toString()) : ["", "", ""]

      setFormData({
        ...formData,
        nickname: userInfo.nickname,
        oldPassword: '',
        newPassword: '',
        newPasswordRepeated: '',
        birthdayYear: year,
        birthdayMonth: month,
        birthdayDay: day,
        gender: userInfo.gender,
        address: userInfo.address,
        occupation: userInfo.occupation
      })

      setCurrentPropertyName(match.params.propertyName)
    }
  })
)
