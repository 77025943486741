// @flow

import FormConstants from './FormConstants'

export { FormConstants }

export default {
  // レイアウト用の固定値
  LAYOUT_MAX_WIDTH: 640,

  // アクセス解析用 モーダル・フルモーダルに割り当てた擬似Path
  PSEUDO_PAGE_PATH_MODAL_SHOES_DETAIL: '/modal_shoes_detail/:itemUuid',
  PSEUDO_PAGE_PATH_MODAL_FEEDBACK: '/modal_feedback/:itemUuid',
  PSEUDO_PAGE_PATH_MODAL_FEET_TYPES: '/modal_feet_types',
  PSEUDO_PAGE_PATH_MODAL_FILTERING_CATEGORY: '/modal_filtering_category',
  PSEUDO_PAGE_PATH_MODAL_FILTERING_SHOP: '/modal_filtering_shop',

  // アクセス解析用 特定処理の実行に割り当てた擬似Path
  PSEUDO_PAGE_PATH_EXEC_LOGOUT: '/exec_logout',
  PSEUDO_PAGE_PATH_EXEC_FEEDBACK: '/exec_feedback/:itemUuid',

  // エラー名
  HTTP_FLICFIT_CONNECTION_ERROR: 'HttpFlicfitConnectionError',
  HTTP_FLICFIT_RESPONSE_ERROR: 'HttpFlicfitResponseError',
  HTTP_FLICFIT_FAILURE_RESULT_GOT: 'HttpFlicfitFailureResultGot'
}
