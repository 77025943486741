// @flow
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import thunk from 'redux-thunk'

import app from './modules/app'
import master from './modules/master'
import user from './modules/user'
import store from './modules/store'
import feet from './modules/feet'
import favorite from './modules/favorite'
import recommendation from './modules/recommendation'
import toast from './modules/toast'
import fitting from './modules/fitting'

import specifics from './modules/specifics'

const composeEnhancers = composeWithDevTools({})
const reducer = combineReducers({
  app,
  master,
  user,
  store,
  feet,
  favorite,
  fitting,
  recommendation,
  toast,
  specifics
})
const sagaMiddleware = createSagaMiddleware()
const middleweare = [sagaMiddleware, thunk]

function* rootSaga() {
  yield all([])
}

export default (initialState: Object) => {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleweare))
  )
  const runSaga = () => {
    sagaMiddleware.run(rootSaga)
  }
  return { store, runSaga }
}
