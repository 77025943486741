// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  name: string,
  value: string | number,
  label: string | number
}

export default function({ name, value, label, ...rest }: Props) {
  return (
    <Radio type={'radio'}>
      <input type="radio" name={name} value={value} {...rest} />
      <span>{label}</span>
    </Radio>
  )
}

const Radio = styled.label`
  display: inline-block;
  font-size: 14px;
  margin-right: 35px;
  margin-bottom: 10px;
  input {
    display: none;
  }
  span {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1.5;
    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-right: 10px;
      border: 1px solid ${({ theme }) => theme.color.inputBorderGray};
      box-sizing: border-box;
      border-radius: 50%;
    }
  }
  input:checked + span::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 4px;
    display: block;
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.color.black};
    border-radius: 50%;
  }
`
