export default {
  color: {
    white: '#fff',
    yellow: '#fff000',
    black: '#333333',
    gray: '#777777',
    lightGray: '#cccccc',
    borderGray: '#e0e0e0',
    inputBorderGray: '#e9e9e9',
    blue: '#00a0e9',
    red: '#dd0000'
  },
  font: {
    regular: 'Hiragino Kaku Gothic ProN',
    bold: 'HiraKakuPro-W6',
    english: 'Helvetica Neue',
    englishBold: 'HelveticaNeue-Bold'
  }
}
