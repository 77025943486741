// @flow
import React from 'react'
import styled from 'styled-components'
import { compose, withState } from 'recompose'

import CheckButton from '@src/components/atoms/CheckButton'
import SpinnerThumbnailLoading from '@src/components/atoms/SpinnerThumbnailLoading'

import fitFitImg from '@src/assets/img/fit_fit.png'
import fitTightImg from '@src/assets/img/fit_tight.png'
import fitLooseImg from '@src/assets/img/fit_loose.png'
import noShoesImg from '@src/assets/img/no_shoes.png'
import spacerRectImg from '@src/assets/img/spacer_rect.png'

type ExternalProps = {}

type Props = ExternalProps & {
  fit: string,
  imgPath: string,
  isFavorite?: boolean,
  shouldShowFavIco?: boolean,
  onClickShoes?: () => void,
  onClickFavIco?: () => void,

  loaded: boolean,
  setLoaded: boolean => void
}

export const enhancePresentation = compose(
  withState('loaded', 'setLoaded', false)
)

export default function({
  fit,
  imgPath,
  isFavorite,
  shouldShowFavIco,
  onClickShoes,
  onClickFavIco,

  loaded,
  setLoaded
}: Props) {
  let fitImg
  switch (fit) {
    case 'tight':
      fitImg = fitTightImg
      break
    case 'loose':
      fitImg = fitLooseImg
      break
    default:
      fitImg = fitFitImg
  }
  return (
    <Shoes
      isFavorite={isFavorite}
      onClick={() => {
        if (onClickShoes) onClickShoes()
      }}
    >
      {fit != null && fit != "outside" && (
      <p>
        <img src={fitImg} alt="" />
      </p>
      )}
      <figure>
        <SpinnerThumbnailLoading loaded={loaded} size={10} />
        <React.Fragment>
          {!loaded && <img src={spacerRectImg} />}
          <Thumbnail
            src={imgPath ? imgPath : noShoesImg}
            onLoad={() => setLoaded(true)}
            style={{
              display: loaded ? 'inline' : 'none'
            }}
          />
        </React.Fragment>
      </figure>
      {shouldShowFavIco && (
        <CheckButton
          onClick={(e: any) => {
            e.stopPropagation()
            if (onClickFavIco) onClickFavIco()
          }}
          isFavorite={isFavorite || false}
        />
      )}
    </Shoes>
  )
}

const Shoes = styled.div`
  position: relative;
  p {
    position: absolute;
    left: 5px;
    top: 10px;
    img {
      width: 65px;
    }
  }
  figure {
    border-radius: 50%;
    border: 2px solid
      ${({ theme, isFavorite }) =>
        isFavorite ? theme.color.yellow : theme.color.borderGray};
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  i {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
`

const Thumbnail = styled.img`
  object-fit: contain;
`
