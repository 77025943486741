// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { appActionCreators } from '@src/redux/modules/app'
import { userActionCreators } from '@src/redux/modules/user'
import { loginProcessActionCreators } from '@src/redux/modules/specifics/loginProcess'
import { lineState } from '@src/helpers/string'
import commonHandlers from '@src/commonHandlers'
const CONFIG: any = process.env.CONFIG

type LoginErrorInfo = {
  message: string
}

interface Props {
  location: Object;
  history: Object;
  appActions: { [string]: Function };
  loginProcessActions: { [string]: Function };
  loginErrorInfo: LoginErrorInfo;
  updateLoginErrorInfo: LoginErrorInfo => any;
}

export const enhanceConnection = compose(
  connect(
    null,
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      loginProcessActions: bindActionCreators(
        { ...loginProcessActionCreators },
        dispatch
      )
    })
  ),

  withState('loginErrorInfo', 'updateLoginErrorInfo', { message: '' }),

  withHandlers({
    handleLoginAsync: (props: Props) => async (mailAddr, password) => {
      const {
        location,
        history,
        appActions,
        loginProcessActions,
        updateLoginErrorInfo
      } = props

      updateLoginErrorInfo({
        message: ''
      })

      appActions.showLoading()
      try {
        // ログイン実行
        const loginResult = await loginProcessActions.loginByEmail(
          mailAddr,
          password
        )
        if (!loginResult.isSucceeded) {
          // パスワード再設定
          if (loginResult.hasOwnProperty('isResetPassword') && loginResult.isResetPassword) {
            const orguser = lineState(8)
            return history.push(`/reset_password/${orguser}` )
          }
          updateLoginErrorInfo({
            message: 'ログインに失敗しました'
          })
          return
        }
      } catch (err) {
        commonHandlers.handleAsyncLogicError(err)
        return
      } finally {
        appActions.hideLoading()
      }

      if (!location.state) {
        console.warn('props.location.stateなし')
        return history.push('/')
      }
      if (location.state.isGuardedCauseNeedLoggedIn) {
        // リダイレクト
        const path = location.state.redirectUrl
        history.push(path)
      } else {
        history.push('/')
      }
    },

    handleLineLoginErrorAsync: (props : Props) => async (err)  => {
      const {
        updateLoginErrorInfo
      } = props
      updateLoginErrorInfo({
            message: 'ログインに失敗しました'
      })
      return
    },
    
    handleLineLoginAsync: (props: Props) => async () => {
      const {
        history,
        loginProcessActions
      } = props

      // LINEのログイン前にJWTのトークンで認証チェック
      const loginType = localStorage.getItem('flicfitType') ||  null
      if (loginType == 'LINE' ){
        const loginResult = await loginProcessActions.autoLogin()
        if (loginResult.isSucceeded) {
          history.push('/')
          return
        }
      }

      let authData = CONFIG.authData
      const baseUrl = 'https://access.line.me/oauth2/v2.1/authorize'
      const redirecturi = encodeURI(authData.redirecturi)
      const scope = encodeURI(authData.TokenScopesArray.join(' '))
      // TODO footuuidをセットする場合,,,
      let state = lineState(8) 
      const json = localStorage.getItem('flicfitData') || '{}'
      const flicfitData = JSON.parse(json)
      if ( flicfitData.hasOwnProperty('footUuidInflowedFromQrCode') ) {
         state =  flicfitData.footUuidInflowedFromQrCode
      }
      const loginurl = `${baseUrl}?response_type=code&client_id=${authData.ClientId}&redirect_uri=${authData.RedirectUriSignIn}&state=${state}&scope=${scope}&bot_prompt=aggressive`

      flicfitData['state'] = state
      localStorage.setItem('flicfitData', JSON.stringify(flicfitData)) 

      window.location.href = loginurl
    }
  }),
  lifecycle({
    async componentDidMount() {
      const { setLineDisplay } = this.props
      const json = localStorage.getItem('flicfitData') || '{}'
      const flicfitData = JSON.parse(json)
      if ( flicfitData.footUuidInflowedFromQrCode ) {
        const words = flicfitData.footUuidInflowedFromQrCode.split('-');
        const footuuid = words[0]
      }
    }
  })
  
)
