// @flow
import React from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'

import FullModalPageLayout from '@src/components/templates/FullModalPageLayout'
import FullModal from '@src/components/organisms/FullModal'
import Inner from '@src/components/templates/Inner'
import CheckBoxList from '@src/components/molecules/CheckBoxList'
import HalfButtons from '@src/components/molecules/HalfButtons'
import Button from '@src/components/atoms/Button'

// 外部供給のProps
type ExternalProps = {
  formRef: { current: null | HTMLFormElement },
  checkBoxes: Array<{ value: string, label: string, defaultChecked: boolean }>,
  handleSave: (e: any) => void,
  handleCancel: (e: any) => void
}

// このファイルで定義しているProps
type Props = ExternalProps & {}

/*==================================
* enhance
==================================*/

export const enhancePresentation = compose()

/*==================================
* View
==================================*/

export default function Presentation({
  formRef,
  checkBoxes,
  handleSave,
  handleCancel
}: Props) {
  return (
    <FullModalPageLayout>
      <FullModal
        title={'お気に入りのショップ'}
        isShown={true}
        onClose={handleCancel}
      >
        <Inner>
          <form ref={formRef}>
            <CheckBoxList name={'store'} checkBoxes={checkBoxes} />
          </form>
        </Inner>
        <Footer>
          <HalfButtons>
            <Button type={'secondary'} onClick={handleCancel}>
              キャンセル
            </Button>
            <Button type={'primary'} onClick={handleSave}>
              保存する
            </Button>
          </HalfButtons>
        </Footer>
      </FullModal>
    </FullModalPageLayout>
  )
}

const Footer = styled(Inner)`
  border-top: 1px solid ${({ theme }) => theme.color.lightGray};
  padding-top: 10px;
  padding-bottom: 10px;
`
