// @flow
import React from 'react'
import styled from 'styled-components'
import { compose, withProps, withState, lifecycle } from 'recompose'
import withRefererableMethods from '@src/helpers/withRefererableMethods'
import analytics from '@src/analytics'
import constants from '@src/constants'

import ImageGallery from 'react-image-gallery'
import '@src/assets/css/image-gallery.css'
import theme from '@src/misc/theme'
import noShoesImg from '@src/assets/img/no_shoes.png'

import Inner from '@src/components/templates/Inner'
import Tag from '@src/components/atoms/Tag'
import Color from '@src/components/atoms/Color'
import Button from '@src/components/atoms/Button'
import CheckButton from '@src/components/atoms/CheckButton'
import Price from '@src/components/atoms/Price'
import Text from '@src/components/atoms/Text'
import Modal from '@src/components/organisms/Modal'
import SpinnerThumbnailLoading from '@src/components/atoms/SpinnerThumbnailLoading'

import RecommendationItemModel from '@src/models/RecommendationItemModel'
import type { DisplayItemInterface } from '@src/types'

type Refererable = {
  showModal: () => void
}

type ExternalProps = {
  mode: 'favorite-detail' | 'recommendation-detail',
  modalRef: { current: null | Refererable },
  items: Array<DisplayItemInterface>,
  onClickTag?: (tagKey: string, itemUuid: number) => void,
  onClickFeedback?: (item: DisplayItemInterface) => void,
  onClickFavIco: (item: DisplayItemInterface) => void
}

type Props = ExternalProps & {
  isShown: boolean,
  setIsShown: boolean => void,
  itemUuid: number,
  setItemUuid: number => void,
  item: null | DisplayItemInterface,
  images: Array<{ original: string, thumbnail: string }>,
  loaded: boolean,
  setLoaded: boolean => void
}

export const enhancePresentation = compose(
  // モーダルの開閉
  withState('isShown', 'setIsShown', false),
  // 表示アイテム
  withState('itemUuid', 'setItemUuid', null),
  //
  withState('loaded', 'setLoaded', false),

  // modalRefから呼べるメソッド定義
  withRefererableMethods('modalRef', {
    showModal(itemUuid: number) {
      const { setIsShown, setItemUuid, setLoaded } = this.props

      // アクセス解析 送信
      const pageId = constants.PSEUDO_PAGE_PATH_MODAL_SHOES_DETAIL.replace(
        ':itemUuid',
        String(itemUuid)
      )
      analytics.sendAnalyticsData(pageId, itemUuid)

      setLoaded(false)
      setItemUuid(itemUuid)
      setIsShown(true)
    }
  }),

  // item, images
  withProps(({ items, itemUuid }: Props) => {
    const item = items.find(item => item.itemUuid === itemUuid) || null

    let images
    if (!item) {
      images = []
    } else if (!item || item.pictureUrls.length === 0) {
      // 0件の可能性あり
      images = [
        {
          original: noShoesImg,
          thumbnail: noShoesImg
        }
      ]
    } else {
      images = item.pictureUrls.map(url => ({
        original: url || noShoesImg, // 空文字の可能性あり
        thumbnail: url || noShoesImg // 空文字の可能性あり
      }))
    }

    return {
      item,
      images
    }
  }),

  lifecycle({
    async componentDidUpdate() {
      const { isShown, items, itemUuid, setIsShown } = this.props
      if (!isShown) return
      if (!items.find(item => item.itemUuid === itemUuid)) {
        // お気に入り削除により表示中アイテムが一覧から無くなった時一応モーダルを閉じる
        setIsShown(false)
      }
    }
  })
)

export default function Presentation({
  mode,
  onClickFavIco,
  onClickFeedback,
  onClickTag,
  isShown,
  setIsShown,
  loaded,
  setLoaded,
  images,
  item
}: Props) {
  if (!item) return ''

  // 型キャスト
  const itemWithTag =
    mode === 'recommendation-detail'
      ? ((item: any): RecommendationItemModel)
      : (null: null)

  const handleClickTag = (tagKey: string) => {
    if (!onClickTag) return
    onClickTag(tagKey, item.itemUuid)
    setIsShown(false)
  }

  return (
    <Modal isShown={isShown} onClose={() => setIsShown(false)}>
      <Inner>
        <Gallery loaded={loaded}>
          <Loading>
            <SpinnerThumbnailLoading loaded={loaded} size={16} />
          </Loading>
          <ImageGallery
            items={images}
            startIndex={0}
            onImageLoad={_ => setLoaded(true)}
          />
        </Gallery>

        <ShoesText>
          <StyledCheckButton
            isFavorite={item.isFavorite}
            onClick={() => onClickFavIco(item)}
          />
          <div>
            <Text color={theme.color.gray} size={12} margin={8}>
              {item.brandName}
              <br />
              {`${item.productNumber} / ${item.makerSize}${item.unit} / ${
                item.makerWidth
              }`}
              <br />
              {`${item.productName}`}
              <br />
              {`${item.makerColor} / ${item.makerMaterial}`}
            </Text>

            { item.price  > 0 && 
            <Price
              price={item.price}
              taxDispSetting={item.taxDispSetting || ''}
            />
            }
            <Text size={12}>{item.storeName}</Text>
            { ( item.ecUrl  != null ) && 
            <EcButton
            onClick={() => {
              window.open(item.ecUrl, '_blank');
            }}
            size="large"
            type="success"
            height='50px'
            >詳しく見る</EcButton>
             }
          </div>
          {itemWithTag && (
            <ShoesTags>
              <Tag
                label={itemWithTag.targetName}
                onClick={_ => handleClickTag('targetName')}
              />
              <Tag
                label={itemWithTag.categoryName}
                onClick={_ => handleClickTag('categoryName')}
              />
              {// 仕様: shoeTypeNameタグで値が"none"のタグは絞り込み・商品詳細に表示しない
              itemWithTag.shoeTypeName !== 'none' && (
                <Tag
                  label={itemWithTag.shoeTypeName}
                  onClick={_ => handleClickTag('shoeTypeName')}
                />
              )}
              <Tag
                label={itemWithTag.heelTypeName}
                onClick={_ => handleClickTag('heelTypeName')}
              />
              <Color
                colorId={itemWithTag.colorId}
                onClick={_ => handleClickTag('colorId')}
              />
              <Tag
                label={itemWithTag.material}
                onClick={_ => handleClickTag('material')}
              />
              {
                //<Tag label={itemWithTag.patternName} />
                //<Tag label={itemWithTag.toe} />
              }
              <Tag
                label={`ヒール高 ${itemWithTag.heelHeight} mm`}
                onClick={_ => handleClickTag('heelHeight')}
              />
              <Tag
                label={`ストラップ${itemWithTag.strap ? 'あり' : 'なし'}`}
                onClick={_ => handleClickTag('strap')}
              />
              <Tag
                label={`靴紐${itemWithTag.shoelace ? 'あり' : 'なし'}`}
                onClick={_ => handleClickTag('shoelace')}
              />
            </ShoesTags>
          )}
        </ShoesText>
      </Inner>
    </Modal>
  )
}

const Loading = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vw - 80px);
  max-height: 100vh;
`

const Gallery = styled.div`
  > ${Loading} {
    display: ${props => (props.loaded ? 'none' : 'block')};
  }
  > .image-gallery {
    display: ${props => (props.loaded ? 'block' : 'none')};
  }
`

const ShoesText = styled.div`
  text-align: center;
  position: relative;
  > div {
    display: inline-block;
  }
`
const StyledCheckButton = styled(CheckButton)`
  position: absolute;
  top: 0;
  right: 0;
`

const ShoesTags = styled.div`
  text-align: center;
  margin-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.color.borderGray};
  padding-top: 15px;
  > * {
    margin: 3px 4px;
  }
`

const EcButton  = styled(Button)`
  margin-top: 10px;
`
