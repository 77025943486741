// @flow
import React from 'react'
import styled from 'styled-components'

import CheckBox from '@src/components/atoms/forms/CheckBox'

type Props = {
  name: string,
  checkBoxes: Array<{ value: string, label: string, defaultChecked: boolean }>
}

export default function({ name, checkBoxes }: Props) {
  return (
    <CheckBoxList>
      {checkBoxes.map((checkBox, index) => (
        <CheckBoxRow key={index}>
          <StyledCheckBox
            name={name}
            value={checkBox.value}
            label={checkBox.label}
            defaultChecked={checkBox.defaultChecked}
          />
        </CheckBoxRow>
      ))}
    </CheckBoxList>
  )
}

const CheckBoxList = styled.div``
const CheckBoxRow = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.lightGray};
  padding: 15px 0;
`
const StyledCheckBox = styled(CheckBox)`
  margin: 0;
  display: block;
`
