// @flow
import React from 'react'
import styled from 'styled-components'
import { withState, compose } from 'recompose'
import constants from '@src/constants'
import { Link } from 'react-router-dom'
import icoChevronLeftImg from '@src/assets/img/ico_chevron_left_white.png'

import ListMenu from '@src/components/molecules/ListMenu'

import theme from '@src/misc/theme'

function _scrollTop() {
  let tgt
  if ('scrollingElement' in document) {
    tgt = document.scrollingElement
  } else {
    tgt = document.documentElement
  }
  if (tgt) tgt.scrollTop = 0
}

type ExternalProps = {
  backUrl: ?string,
  handleLogout: () => any
}

type Props = ExternalProps & {
  isActive: boolean,
  setIsActive: boolean => any,
  history : Object
}

export const enhancePresentation = compose(
  withState('isActive', 'setIsActive', false)
)

export default function Presentation({
  isActive,
  setIsActive,
  handleLogout,
  backUrl,
  history
}: Props) {
  console.log("GNavi Presentations", backUrl)
  const items = [
    { path: '/', text: 'マイページTOP' },
    { path: '/feet', text: '足カルテ' },
    { path: '/recommendation', text: 'おすすめ' },
    { path: '/fitting', text: '試着履歴' },
    { path: '/favorites', text: 'お気に入り' },
    { path: '/account', text: 'アカウント情報' }
  ]

  return (
    <Root isActive={isActive}>
      <Layout>
      { backUrl && 
        <BtnBack
          to={backUrl || ''}
        >
          <img src={icoChevronLeftImg} alt="" width={7} onClick={() => history.push(backUrl)}  />
        </BtnBack>
      }
            

      { !backUrl && 
        <BtnMenu
          isActive={isActive}
          onClick={() => {
            _scrollTop()
            setIsActive(!isActive)
          }}
        >
          <span />
          <span />
        </BtnMenu>
      }
        <Nav isActive={isActive}>
          <ScrollFrame>
            <ListMenu
              items={items}
              handleClickFinally={_ => {
                // リンク先が今と同じ場合、単にメニューが閉じる
                setIsActive(!isActive)
              }}
            />
            <ListSub>
              <li>
                <a
                  href="https://flicfit.zendesk.com/hc/ja/categories/360001223232"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  よくあるご質問
                </a>
              </li>
              <li>
                <a
                  href="https://flicfit.zendesk.com/hc/ja/articles/360020710432a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  利用規約
                </a>
              </li>
              <li>
                <a
                  href="https://flicfit.zendesk.com/hc/ja/articles/360020710412"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  プライバシーポリシー
                </a>
              </li>
            </ListSub>
            <ListMenu
              items={[
                {
                  text: 'ログアウト',
                  color: theme.color.blue,
                  handleClick: handleLogout
                }
              ]}
            />
          </ScrollFrame>
        </Nav>
      </Layout>
    </Root>
  )
}

const Root = styled.div`
  button {
    z-index: 99;
  }
  z-index: 98;
  text-align: center;
  position: ${({ isActive }) => (isActive ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  height: auto
  width: 100%;
`
const Layout = styled.div`
  position: relative;
  height: auto
  width: 100%;
  max-width: ${constants.LAYOUT_MAX_WIDTH}px;
  margin: 0 auto;
`

const Nav = styled.nav`
  transition: 0.2s;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  padding-top: 80px;
  background: ${({ theme }) => theme.color.white};

  text-align: left;
`

const ScrollFrame = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: auto;
`

const ListSub = styled.ul`
  padding: 10px 0;
  li {
    font-size: 12px;
    border-bottom: none;
    a {
      color: ${({ theme }) => theme.color.gray};
      display: block;
      padding: 4px 20px;
    }
  }
`
const BtnMenu = styled.button`
  position: absolute;
  left: 20px;
  top: 18px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: ${({ isActive, theme }) =>
    isActive ? theme.color.white : theme.color.black};
  cursor: pointer;
  transition: 0.2s;
  span {
    width: 18px;
    height: 3px;
    background: ${({ isActive, theme }) =>
      isActive ? theme.color.black : theme.color.white};
    display: inline-block;
    border-radius: 1.5px;
    position: absolute;
    left: ${({ isActive }) => (isActive ? '8px' : '8px')};
    transition: 0.2s;
    &:first-child {
      top: ${({ isActive }) => (isActive ? '15px' : '11px')};
      transform: ${({ isActive }) => (isActive ? 'rotate(45deg)' : '')};
    }
    &:last-child {
      top: ${({ isActive }) => (isActive ? '15px' : '21px')};
      transform: ${({ isActive }) => (isActive ? 'rotate(-45deg)' : '')};
    }
  }
`

const BtnBack = styled(Link)`
  position: absolute;
  left: 20px;
  top: 18px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.black};
  cursor: pointer;
  > img {
    width: 34px;
  }
`
