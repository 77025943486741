import constants from '@src/constants'

export default function calcDrawingSize() {
  const w = window.innerWidth;
  const h = window.innerHeight;
  // ウインドウの高さか幅の小さい方の値
  let d = w < h ? w : h;
  if (d > constants.LAYOUT_MAX_WIDTH) d = constants.LAYOUT_MAX_WIDTH
  return {
    dw: window.parseInt(d * 1.0, 10),// 描画領域幅
    dh: window.parseInt(d * 0.8, 10) // 描画領域高さ
  };
}
