// @flow
import React from 'react'

// 指定のメソッドを持つコンポーネントにref属性でrefを渡すためのHOC
// statelessではないclassコンポーネントでないとrefを設置できないためこのようにした
export default function withRefererableMethods(
  refPropertyName: string,
  methods: { [methodKey: string]: (any) => void }
) {
  //
  class Refererable extends React.Component<any, any> {
    $key: string
    $value: any => void

    constructor() {
      super()
      Object.keys(methods).forEach((key: string) => {
        if (typeof methods[key] !== 'function') return
        this[key] = methods[key].bind(this)
      })
    }
    render() {
      return <React.Fragment>{this.props.children}</React.Fragment>
    }
  }

  return function(Wrapped: any): any => any {
    return function ReferenceWrapper(props: any) {
      return (
        <Refererable ref={props[refPropertyName]} {...props}>
          <Wrapped {...props} />
        </Refererable>
      )
    }
  }
}
