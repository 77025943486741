// @flow
import React from 'react'
import styled from 'styled-components'
import { compose, withState } from 'recompose'

import SpinnerThumbnailLoading from '@src/components/atoms/SpinnerThumbnailLoading'

import noShoesImg from '@src/assets/img/no_shoes.png'
import spacerRectImg from '@src/assets/img/spacer_rect.png'

type ExternalProps = {}

type Props = ExternalProps & {
  imgPath: string,
  shouldShowFavIco?: boolean,
  review: string,
  onClickShoes?: () => void,
  onClickFavIco?: () => void,

  loaded: boolean,
  setLoaded: boolean => void
}

export const enhancePresentation = compose(
  withState('loaded', 'setLoaded', false)
)

export default function({
  imgPath,
  review,
  shouldShowFavIco,
  onClickShoes,
  onClickFavIco,

  loaded,
  setLoaded
}: Props) {
  return (
    <Shoes
      onClick={() => {
        if (onClickShoes) onClickShoes()
      }}
    >
      <figure>
        <SpinnerThumbnailLoading loaded={loaded} size={10} />
        <React.Fragment>
          {!loaded && <img src={spacerRectImg} />}
          <Thumbnail
            src={imgPath ? imgPath : noShoesImg}
            onLoad={() => setLoaded(true)}
            style={{
              display: loaded ? 'inline' : 'none'
            }}
          />
        </React.Fragment>
      </figure>
      <Review>{review}</Review>
    </Shoes>
  )
}

const Shoes = styled.div`
  position: relative;
  p {
    position: absolute;
    left: 5px;
    top: 10px;
    img {
      width: 65px;
    }
  }
  figure {
    border-radius: 50%;
    border: 2px solid
      ${({ theme }) =>
        theme.color.borderGray};
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  i {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
`

const Thumbnail = styled.img`
  object-fit: contain;
`
const Review = styled.div`
  position: absolute;
  bottom: 10px;
  right: 0px;
  background-color : ${({ theme }) => theme.color.yellow};
  height: 30px;
  border-radius: 15px;
  min-width: 50px;
  text-align: center;
  vertical-align: middle;
  padding: 5px;

`
