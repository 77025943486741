// @flow
import React from 'react'

import DefaultPageLayout from '@src/components/templates/DefaultPageLayout'
import Inner from '@src/components/templates/Inner'
import PageTitle from '@src/components/molecules/PageTitle'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'

import theme from '@src/misc/theme'

import icoMailWhiteImg from '@src/assets/img/ico_mail_white.png'

type ExternalProps = {}
type Props = ExternalProps & {}

export default function Presentation({  }: Props) {
  return (
    <DefaultPageLayout>
      <React.Fragment>
        <Inner>
          <PageTitle title="Flicfitへようこそ！" />
          <Button href={'/signup/form'} type={'primary'}>
            <img src={icoMailWhiteImg} width={19} />
            メールアドレスで登録
          </Button>
          <Text
            color={theme.color.gray}
            textAlign={'center'}
            size={11}
            marginTop={15}
          >
            当社
            <a
              href={'https://flicfit.zendesk.com/hc/ja/articles/360020710432'}
              target="_blank"
              rel="noopener noreferrer"
            >
              利用規約
            </a>
            を読み、同意の上ご登録をお願いします。
          </Text>

          <br />
          <br />

          <div style={{ borderTop: `1px solid ${theme.color.borderGray}` }}>
            <br />
            <Text textAlign={'center'} size={14} style={{ fontWeight: 'bold' }}>
              すでにFlicfitメンバーの方はこちら
            </Text>
            <br />
            <Button href={'/login'} type={'secondary'}>
              ログイン
            </Button>
          </div>
        </Inner>
      </React.Fragment>
    </DefaultPageLayout>
  )
}
