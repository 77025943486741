// @flow
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import icoPencilImg from '@src/assets/img/ico_pencil.png'

type Props = {
  propertyName: string,
  label?: string
}

export default function({ propertyName, label, ...rest }: Props) {
  return (
    <EditLink {...rest}>
      <Link to={`/account/edit/${propertyName}`}>
        <img src={icoPencilImg} alt="" width={9} />
        <span>{label ? label : '変更'}</span>
      </Link>
    </EditLink>
  )
}

const EditLink = styled.p`
  span {
    display: inline-block;
    margin-left: 0.5em;
  }
`
