// @flow
import React from 'react'
import styled from 'styled-components'
import closeImg from './img/icon-close.png'

type Props = {
  colorId: number,
  selected?: boolean
}

const ButtonE = ({ colorId, selected, ...props }: Props) => (
  <HitArea {...props}>
    <ButtonStyle colorId={colorId} selected={selected}>
      {selected && <img src={closeImg} width={12} height={12} />}
    </ButtonStyle>
  </HitArea>
)

export default ButtonE

const HitArea = styled.button`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonStyle = styled.div`
  width: 30px;
  height: 30px;
  background-image: ${({ colorId }) => `url(/img/colors/${colorId}.png)`};
  background-size: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
