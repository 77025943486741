// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withHandlers, withProps, lifecycle } from 'recompose'
import { favoriteActionCreators } from '@src/redux/modules/favorite'
import {
  filteringConditionActionCreators,
  withFilteringConditionTexts
} from '@src/redux/modules/specifics/filteringCondition'
import commonHandlers from '@src/commonHandlers'
import FavoriteItemModel from '@src/models/FavoriteItemModel'

type Props = {
  favorite: any,
  filteringCondition: {
    shop: {
      isAll: boolean,
      selectedIds: Array<number>,
      selectedNames: Array<string>
    },
    category: {
      isAll: boolean,
      selectedNames: Array<string>
    }
  },
  favoriteActions: { [string]: Function },
  filteringConditionActions: { [string]: Function }
}

export const enhanceConnection = compose(
  connect(
    state => ({
      favorite: state.favorite,
      filteringCondition: state.specifics.filteringCondition
    }),
    dispatch => ({
      favoriteActions: bindActionCreators(
        { ...favoriteActionCreators },
        dispatch
      ),
      filteringConditionActions: bindActionCreators(
        { ...filteringConditionActionCreators },
        dispatch
      )
    })
  ),

  withFilteringConditionTexts,

  withProps(({ favorite, filteringCondition }: Props) => {
    //
    const favoriteItems: Array<FavoriteItemModel> = favorite.items.map(
      (itemRaw: any) => new FavoriteItemModel(itemRaw, true)
    )

    // 絞り込み
    const displayItems = favoriteItems
      .filter(item =>
        filteringCondition.shop.selectedNames.includes(item.storeName)
      )
      .filter(item =>
        filteringCondition.category.selectedNames.includes(item.categoryName)
      )

    // 検討: 一覧のソート処理

    return {
      displayItems
    }
  }),

  withHandlers({
    handleClickFavIco: ({ favoriteActions }: Props) => async (
      item: FavoriteItemModel
    ) => {
      const { itemUuid, isFavorite } = item
      if (isFavorite) {
        // お気に入り削除
        try {
          await favoriteActions.remove(itemUuid)
        } catch (err) {
          commonHandlers.handleAsyncLogicError(err)
        }
      }
    },

    handleClickClearFilteringSetting: ({
      filteringConditionActions
    }: Props) => async () => {
      // 絞り込みの設定をクリア
      filteringConditionActions.resetSelectedShop()
      filteringConditionActions.resetSelectedCategory()
    }
  }),

  lifecycle({
    async componentDidMount() {
      const { filteringConditionActions, favoriteActions } = this.props
      // 絞り込みの設定を初期化
      filteringConditionActions.resetSelectedShop()
      filteringConditionActions.resetSelectedCategory()
      try {
        // 一覧取得
        await favoriteActions.fetch()
      } catch (err) {
        commonHandlers.handleAsyncLogicError(err)
      }
    }
  })
)
