// @flow
import produce from 'immer'
import api from '@src/api'
// import { createAction } from 'redux-actions'
import { createActionThunk } from 'redux-thunk-actions'

import { withProps } from 'recompose'

import StoreModel from '@src/models/StoreModel'

/*==================================
* Actions
==================================*/
const THUNK = {}
const REDUCER = {}

// Public
THUNK.FETCH = 'store/THUNK.FETCH'
THUNK.UPDATE_FAVORITE_STORES = 'store/THUNK.UPDATE_FAVORITE_STORES'

// Private
REDUCER.SET_STORES = 'store/REDUCER.SET_STORES'

/*==================================
* Action Creators
==================================*/
export const storeActionCreators = {
  fetch: createActionThunk(
    THUNK.FETCH,
    async ({ dispatch }): Promise<void> => {
      const result = await api.getStores()
      dispatch({
        type: REDUCER.SET_STORES,
        payload: {
          stores: result.data
        }
      })
    }
  ),

  updateFavoriteStores: createActionThunk(
    THUNK.UPDATE_FAVORITE_STORES,
    async (storeIdList, favoriteFlag, {}): Promise<void> => {
      await api.updateFavoriteStores(storeIdList, favoriteFlag)
    }
  )
}

/*==================================
Initial State
==================================*/
type State = {
  stores: Array<StoreModel>
}

const initialState = {
  stores: []
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.SET_STORES: {
        draft.stores = action.payload.stores
        return
      }
    }
  })
}

/*==================================
* Selector
==================================*/
type WithStoreCodeMap = {
  storeCodeMap: { [storeCode: string]: StoreModel }
}
export const withStoreCodeMap = withProps(
  ({ store }): WithStoreCodeMap => {
    const storeCodeMap = {}
    store.stores.forEach(store => (storeCodeMap[store.storeCode] = store))
    return {
      storeCodeMap
    }
  }
)
