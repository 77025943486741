// @flow
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { pure } from 'recompose'

type Props = {
  title: string,
  subTitle?: string,
  marginBottom?: number
}

function PageTitle({ title, subTitle, ...rest }: Props) {
  return (
    <Fragment>
      <Title {...rest}>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </Fragment>
  )
}

const Title = styled.h1`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 35px;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '20px'};
`

const SubTitle = styled.p``

export default pure(PageTitle)
