
class glType {

  constructor() {

    this.SCENE = {
      INTRO:0,
      READY:1,
      MAKING:2,
      SHOES:3,
      NONE:4
    };

  }

}

export default new glType();
