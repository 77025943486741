// @flow
import React from 'react'
import styled, { css } from 'styled-components'
import { pure } from 'recompose'

import FeedbackFootPoint from '../FeedbackFootPoint'

import leftBaseImg from '@src/assets/img/feedback-l-base.png'
import leftFitImg from '@src/assets/img/feedback-l-fit.png'
import rightBaseImg from '@src/assets/img/feedback-r-base.png'
import rightFitImg from '@src/assets/img/feedback-r-fit.png'

type Props = {
  whetherLR: 'L' | 'R',
  isFit: null | boolean,
  points: {
    // L1: { tightOrLoose: null }
    [pointKey: string]: {
      tightOrLoose: null | 'tight' | 'loose'
    }
  },
  onClickPoint: (pointKey: string) => void,
  onSelectTightOrLoose: (
    pointKey: string,
    tightOrLoose: 'tight' | 'loose'
  ) => void,
  currentSelectedPoint: null | string
}

function FeedbackFoot({
  whetherLR,
  isFit,
  points,
  onClickPoint,
  onSelectTightOrLoose,
  currentSelectedPoint
}: Props) {
  const images = {
    base: whetherLR === 'L' ? leftBaseImg : rightBaseImg,
    fit: whetherLR === 'L' ? leftFitImg : rightFitImg
  }
  return (
    <React.Fragment>
      <ImageWrapper isShown={isFit}>
        <FootImage src={images.fit} />
      </ImageWrapper>

      <ImageWrapper isShown={!isFit}>
        <FootImage src={images.base} />
        <FootPoints isLeftFoot={whetherLR === 'L'}>
          {[1, 2, 3, 4, 5, 6, 7].map(num => {
            const pointKey = `${whetherLR}${num}`
            return (
              <FeedbackFootPoint
                key={pointKey}
                pointKey={pointKey}
                onClickPoint={onClickPoint}
                onSelectTightOrLoose={onSelectTightOrLoose}
                isSelected={currentSelectedPoint === pointKey}
                tightOrLoose={points[pointKey].tightOrLoose}
              />
            )
          })}
        </FootPoints>
      </ImageWrapper>
    </React.Fragment>
  )
}
export default pure(FeedbackFoot)

//
const ImageWrapper = styled.div`
  display: ${({ isShown }) => (isShown ? 'block' : 'none')};
  width: 80%;
  height: auto;
  margin: 40px auto auto auto;
  position: relative;
`

//
const FootImage = styled.img`
  width: 100%;
  height: auto;
  background-image: url("${props => props.imgUrl}");
  background-size: contain;
  margin: auto;
  position: relative;
`

// フィードバックの足の画像サイズは下記で固定し、これを100%サイズとして足ポイントを配置する
// （レイアウトのための表示サイズ調整にはzoomプロパティを使う）
const WIDTH = 248
const HEIGHT = 520

// 左右１〜７まである各足ポイントの位置と大きさはpx単位でPOSITIONSオブジェクトに定義しておき、
// 下記関数で%単位に変換
const calcXPosition = (x: number) => {
  let percentage = (x / WIDTH) * 100
  percentage = window.parseInt(percentage * 1000, 10) / 1000
  return `${percentage}%`
}
const calcYPosition = (y: number) => {
  let percentage = (y / HEIGHT) * 100
  percentage = window.parseInt(percentage * 1000, 10) / 1000
  return `${percentage}%`
}
const calcWidth = (w: number) => {
  let percentage = (w / WIDTH) * 100
  percentage = window.parseInt(percentage * 1000, 10) / 1000
  return `${percentage}%`
}
const calcHeight = (h: number) => {
  let percentage = (h / HEIGHT) * 100
  percentage = window.parseInt(percentage * 1000, 10) / 1000
  return `${percentage}%`
}
// ピクセル単位をパーセント単位に変換
const POSITIONS = {
  X1: calcXPosition(23),
  X2: calcXPosition(158),
  X3: calcXPosition(6),
  X4: calcXPosition(102),
  X5: calcXPosition(153),
  X6: calcXPosition(40),
  X7: calcXPosition(118),

  Y1: calcYPosition(19),
  Y2: calcYPosition(122),
  Y3: calcYPosition(166),
  Y4: calcYPosition(224),
  Y5: calcYPosition(323),
  Y6: calcYPosition(355),
  Y7: calcYPosition(423),

  W1: calcWidth(186),
  W2: calcWidth(88),
  W3: calcWidth(87),
  W4: calcWidth(95),
  W5: calcWidth(96),
  W6: calcWidth(90),
  W7: calcWidth(94),

  H1: calcHeight(106),
  H2: calcHeight(89),
  H3: calcHeight(89),
  H4: calcHeight(92),
  H5: calcHeight(92),
  H6: calcHeight(90),
  H7: calcHeight(93)
}

const FootPoints = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;

  > div {
    position: absolute;
  }
  ${props => {
    const isL = props.isLeftFoot
    const isR = !props.isLeftFoot
    return css`
      > div:nth-child(1) {
        left: ${isL ? POSITIONS.X1 : 'auto'};
        right: ${isR ? POSITIONS.X1 : 'auto'};
        top: ${POSITIONS.Y1};
        width: ${POSITIONS.W1};
        height: ${POSITIONS.H1};
      }
      > div:nth-child(2) {
        left: ${isL ? POSITIONS.X2 : 'auto'};
        right: ${isR ? POSITIONS.X2 : 'auto'};
        top: ${POSITIONS.Y2};
        width: ${POSITIONS.W2};
        height: ${POSITIONS.H2};
      }
      > div:nth-child(3) {
        left: ${isL ? POSITIONS.X3 : 'auto'};
        right: ${isR ? POSITIONS.X3 : 'auto'};
        top: ${POSITIONS.Y3};
        width: ${POSITIONS.W3};
        height: ${POSITIONS.H3};
      }
      > div:nth-child(4) {
        left: ${isL ? POSITIONS.X4 : 'auto'};
        right: ${isR ? POSITIONS.X4 : 'auto'};
        top: ${POSITIONS.Y4};
        width: ${POSITIONS.W4};
        height: ${POSITIONS.H4};
      }
      > div:nth-child(5) {
        left: ${isL ? POSITIONS.X5 : 'auto'};
        right: ${isR ? POSITIONS.X5 : 'auto'};
        top: ${POSITIONS.Y5};
        width: ${POSITIONS.W5};
        height: ${POSITIONS.H5};
      }
      > div:nth-child(6) {
        left: ${isL ? POSITIONS.X6 : 'auto'};
        right: ${isR ? POSITIONS.X6 : 'auto'};
        top: ${POSITIONS.Y6};
        width: ${POSITIONS.W6};
        height: ${POSITIONS.H6};
      }
      > div:nth-child(7) {
        left: ${isL ? POSITIONS.X7 : 'auto'};
        right: ${isR ? POSITIONS.X7 : 'auto'};
        top: ${POSITIONS.Y7};
        width: ${POSITIONS.W7};
        height: ${POSITIONS.H7};
      }
    `
  }};
`
