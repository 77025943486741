// @flow
import produce from 'immer'
import api from '@src/api'
import { createActionThunk } from 'redux-thunk-actions'

import FootModel from '@src/models/FootModel'
import FavoriteItemModel from '@src/models/FavoriteItemModel'
import MeasureHistoryItemModel from '@src/models/MeasureHistoryItemModel'

/*==================================
* Actions
==================================*/
const THUNK = {}
const REDUCER = {}

// Public
THUNK.CLEAR = 'feet/THUNK.CLEAR'
THUNK.FETCH = 'feet/THUNK.FETCH'
THUNK.FETCH_FROM_QR_PARAMETER = 'feet/THUNK.FETCH_FROM_QR_PARAMETER'
THUNK.FETCH_MEASURE_HISTORY = 'feet/THUNK.FETCH_MEASURE_HISTORY'

// Private
REDUCER.SET = 'feet/REDUCER.SET'
REDUCER.SET_MEASURE_HISTORY = 'feet/REDUCER.SET_MEASURE_HISTORY'

/*==================================
* Action Creators
==================================*/
export const feetActionCreators = {
  clear: createActionThunk(
    THUNK.CLEAR,
    async ({ dispatch }): Promise<void> => {
      dispatch({
        type: REDUCER.SET,
        payload: {
          foot: null,
          fitItems: []
        }
      })
      dispatch({
        type: REDUCER.SET_MEASURE_HISTORY,
        payload: {
          measureHistoryItems: []
        }
      })
    }
  ),
  fetch: createActionThunk(
    THUNK.FETCH,
    async (footUuid, { dispatch }): Promise<any> => {
      const result = await api.getFeetByid(footUuid)

      const foot = new FootModel(result.data.foot)

      dispatch({
        type: REDUCER.SET,
        payload: {
          foot,
          fitItems: result.data.fititems
        }
      })
    }
  ),
  fetchMeasureHistory: createActionThunk(
    THUNK.FETCH_MEASURE_HISTORY,
    async ({ dispatch }): Promise<void> => {
      const result = await api.getFeet()

      const items: Array<MeasureHistoryItemModel> = result.data.map(
        (raw: Object) => new MeasureHistoryItemModel(raw)
      )

      dispatch({
        type: REDUCER.SET_MEASURE_HISTORY,
        payload: {
          measureHistoryItems: items
        }
      })
    }
  ),

  fetchFromQRParameter: createActionThunk(
    THUNK.FETCH_FROM_QR_PARAMETER,
    async (encodedId, { dispatch }): Promise<any> => {
      let footUuid: string = ''
      try {
        footUuid = window.atob(encodedId)
      } catch (err) {
        // 想定内のエラー（Base64ではない文字列が渡された場合など）
        throw Error('footUuidが正しくありません')
      }

      const result = await api.getFeetByIdWithoutAuth(footUuid)

      const foot = new FootModel(result.data.foot)

      const items: Array<FavoriteItemModel> = result.data.fititems.map(
        (itemRaw: Object) => new FavoriteItemModel(itemRaw, true)
      )

      dispatch({
        type: REDUCER.SET,
        payload: {
          foot,
          fitItems: items
        }
      })
    }
  )
}

/*==================================
Initial State
==================================*/
type State = {
  measureHistoryItems: Array<MeasureHistoryItemModel>,
  foot: ?FootModel,
  fitItems: Array<FavoriteItemModel>
}

const initialState = {
  measureHistoryItems: [],
  foot: null,
  fitItems: []
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.SET: {
        draft.foot = action.payload.foot
        draft.fitItems = action.payload.fitItems
        return
      }
      case REDUCER.SET_MEASURE_HISTORY: {
        draft.measureHistoryItems = action.payload.measureHistoryItems
        return
      }
    }
  })
}

/*==================================
* Selector
==================================*/
