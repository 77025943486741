// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: any,
  marginTop?: number,
  margin?: number,
  color?: string,
  size?: number,
  isInline?: boolean,
  textAlign?: string,
  maxWidth?: string;
}

export default function({ children, ...rest }: Props) {
  return <Text {...rest}>{children}</Text>
}

const Text = styled.p`
  color: ${({ color, theme }) => (color ? color : theme.color.black)};
  font-size: ${({ size }) => (size ? `${size}px` : 'inherit')};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
  margin-bottom: ${({ margin }) => (margin ? `${margin}px` : '0px')};
  display: ${({ isInline }) => (isInline ? 'inline-block' : 'block')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'inherit')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'inherit')};
`
