// @flow
import React from 'react'

import DefaultPageLayout from '@src/components/templates/DefaultPageLayout'
import Section from '@src/components/templates/Section'
import Inner from '@src/components/templates/Inner'
import PageTitle from '@src/components/molecules/PageTitle'
import Text from '@src/components/atoms/Text'
import Button from '@src/components/atoms/Button'

import icoCompletedImg from '@src/assets/img/ico_completed.png'

type ExternalProps = {}
type Props = ExternalProps & {}

export default function Presentation({  }: Props) {
  return (
    <DefaultPageLayout>
      <Section>
        <Inner>
          <PageTitle title="パスワードをリセットしました" />
          <div className={'align-center'}>
            <img src={icoCompletedImg} width="110" />
          </div>
          <Text textAlign={'center'} size={14} marginTop={20} margin={20}>
            パスワードをリセットしました
            <br />
            新しいパスワードでログインして
            <br />
            サービスをご利用ください。
          </Text>
          <div className={'align-center'}>
            <Button href={'/login'} type={'success'}>
              flicfit トップへ
            </Button>
          </div>
        </Inner>
      </Section>
    </DefaultPageLayout>
  )
}
