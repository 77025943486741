import React, { Component } from 'react'
import styled from 'styled-components'
import Canvas from './Canvas'
import webgl from '@src/webgl'

type Props = {
  pcdFileUrl: string,
  marginLeft: ?string
}

class Foot3d extends Component<Props> {
  componentDidMount() {
    // webglの表示
    webgl.showShoes({
      el: document.getElementById('canvas-karte'),
      pcdFileUrl: this.props.pcdFileUrl
    })
  }

  componentWillUnmount() {
    // webglを消去
    webgl.hideShoes()
  }

  render() {
    return (
      <Root marginLeft={this.props.marginLeft}>
        <Canvas />
      </Root>
    )
  }
}

export default Foot3d

const Root = styled.div`
  background-color: transparent;
  position: relative;
  min-height: 200px;
  text-align: center;
  overflow: hidden;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 'auto')};
`
