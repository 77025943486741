// @flow
import React from 'react'
import styled from 'styled-components'

import Shoes from '@src/components/molecules/Shoes'
import ShoesMeta from '@src/components/molecules/ShoesMeta'
import Price from '@src/components/atoms/Price'

// ChoicesAndKartePageの試着リストで使用

type Props = {
  item: Object
}

export default function({ item }: Props) {
  return (
    <FitShoes>
      <Shoes
        fit={item.fit}
        imgPath={item.pictureUrl}
        isFavorite={item.isFavorite}
        shouldShowFavIco={false}
      />
      <Bottom>
        <ShoesMeta
          brandName={item.brandName}
          productName={item.productName}
          productNumber={item.productNumber}
          makerColor={item.makerColor}
          makerMaterial={item.makerMaterial}
          makerSize={item.makerSize}
          unit={item.unit}
          makerWidth={item.makerWidth}
        />

        {item.price > 0 && 
        <div className="align-center">
          <Price price={item.price} taxDispSetting={item.taxDispSetting} />
        </div>
        }
      </Bottom>
    </FitShoes>
  )
}

const FitShoes = styled.div``
const Bottom = styled.div`
  margin-top: 8px;
`
