// @flow
import React from 'react'
import { compose, withStateHandlers } from 'recompose'

import PrivatePageLayout from '@src/components/templates/PrivatePageLayout'
import Inner from '@src/components/templates/Inner'
import Row from '@src/components/templates/Row'
import PageTitle from '@src/components/molecules/PageTitle'
import FittingList from '@src/components/organisms/FittingList'
import Count from '@src/components/atoms/Count'
import ModalShoesDetail from '@src/components/organisms/ModalShoesDetail'
import ModalFeedback from '@src/components/organisms/ModalFeedback'
import FullModalFilteringShop from '@src/components/organisms/FullModalFilteringShop'
import FullModalFilteringCategory from '@src/components/organisms/FullModalFilteringCategory'

import type { DisplayFittingItemInterface } from '@src/types'

type ExternalProps = {
  displayItems: Array<DisplayFittingItemInterface>,
  history: Object
  //handleClickFavIco: (item: DisplayItemInterface) => void,
}

type Props = ExternalProps & {
}

export const enhancePresentation = compose(
  withStateHandlers(
    {},
    {}
  )
)

export default function Presentation({
  displayItems,
  history
}: Props) {
  return (
    <PrivatePageLayout>
      <Inner>
        <PageTitle title="試着履歴" />

        <Row>
          <Count number={10} />
        </Row>
        <FittingList
          reviews={displayItems}
          onClickShoes={(reviewId:number) => {
            history.push(`/fitting/${reviewId}`)
          }}
        />
      </Inner>
    </PrivatePageLayout>
  )
}
