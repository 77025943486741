// @flow
import { createPictureUrl } from '@src/helpers/pictureUrl'
import type { DisplayItemInterface } from '@src/types'

/* モデル：お気に入り（または試着）アイテム */
export default class FavoriteItemModel implements DisplayItemInterface {
  $key: string
  $value: any
  // パラメータについて
  //
  // itemId: ただの商品ID
  //   同じ商品でも取扱店が異なる場合、itemIdは同じになるがitemUuidは違ってくる
  //   同じ商品でも別のお店のものは別項目として扱うお気に入り項目の識別子にはならない
  // itemUuid: お気に入り項目のID
  //   お気に入りリストなどの項目の識別子としてはこっちしか使えない
  //
  brandName: string
  categoryName: string
  favoriteTypeId: 1 | 2
  favoriteTypeName: 'fit' | 'favorite'
  features: []
  ffColor: string
  ffMaterial: string
  color: ?string
  material: ?string
  fit: 'fit' | 'loose' | 'tight' | 'outside'
  heelHeight: number
  heelTypeName: string
  itemId: number
  itemUuid: number
  makerColor: string
  makerMaterial: string
  makerSize: string
  makerWidth: string
  patternName: string
  pictures: { '0': ?string }
  price: number
  productName: string
  productNumber: string
  rcmdFlag: boolean
  shoeTypeName: string
  shoelace: boolean
  storeName: string
  strap: boolean
  targetName: string
  taxDispSetting: 'none' | ?string
  toeName: string
  unit: string
  ecUrl: ?string
  // 検討: createdTimeによる一覧ソート
  // createdTime: any

  // 追加・加工パラメータ
  pictureUrl: string
  pictureUrls: Array<string>
  isFavorite: boolean
  isFitted: boolean

  constructor(raw: any, isFavorite: boolean) {
    Object.assign(this, {
      ...raw
    })

    // {APIのURL}/{imageのパス(APIから取得できるパス)}?width=268&trim=contain
    this.pictureUrl = raw.pictures['0']
      ? createPictureUrl(raw.pictures['0'], true)
      : ''

    // 画像リスト = おすすめアイテムと同じプロパティ名に揃え、使いやすいように配列にする
    this.pictureUrls = new Array(100)
      .fill(1)
      .map((_, i) => raw.pictures[i.toString()] || null)
      .filter((url: any) => url !== null)
      .map((url: ?string) => (url ? createPictureUrl(url, true) : ''))

    // お気に入りフラグ
    this.isFavorite = isFavorite

    // 試着アイテムかどうか
    // yes -> favorite_type_id: 1 & favorite_type_name: "fit"
    // no  -> favorite_type_id: 2 & favorite_type_name: "favorite"
    this.isFitted = raw.favoriteTypeId === 1 ? true : false
  }
}
