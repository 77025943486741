// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: any
}

export default function({ children }: Props) {
  return <Row>{children}</Row>
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`
