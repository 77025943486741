// @flow
import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'

//import theme from '@src/misc/theme'

import Inner from '@src/components/templates/Inner'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'
import Modal from '@src/components/organisms/Modal'
import FeedbackFoot from './FeedbackFoot'

import FavoriteItemModel from '@src/models/FavoriteItemModel'

type FeedbackInfo = {
  isLeftFit: boolean,
  isRightFit: boolean,
  points: {
    // L1: { tightOrLoose: null }
    [pointKey: string]: {
      tightOrLoose: null | 'tight' | 'loose'
    }
  }
}

type ExternalProps = {
  item: null | FavoriteItemModel,
  feedbackInfo: FeedbackInfo,
  isShown: boolean,
  isCompleteFeedback: boolean,
  closeModal: () => void,
  currentSelectedPoint: null | string,
  handleClickFit: (whetherLR: 'L' | 'R', isFit: boolean) => void,
  handleClickPoint: (pointKey: string) => void,
  handleSelectTightOrLoose: (
    pointKey: string,
    tightOrLoose: 'tight' | 'loose'
  ) => void,
  handleSave: () => Promise<void>,
  handleCancel: () => void
}

type Props = ExternalProps & {}

export const enhancePresentation = compose()

export default function Presentation({
  isShown,
  closeModal,
  item,
  currentSelectedPoint,
  feedbackInfo,
  isCompleteFeedback,
  handleClickFit,
  handleClickPoint,
  handleSelectTightOrLoose,
  handleSave
}: Props) {
  if (!item) return ''

  const canSelectLeftPoint = feedbackInfo.isLeftFit === false
  const canSelectRightPoint = feedbackInfo.isRightFit === false

  return (
    <Modal isShown={isShown} onClose={closeModal}>
      <Inner>
        <Header>
          <img src={item.pictureUrl} />
          <span>フィードバック</span>
        </Header>

        <TwoColumn>
          <Column>
            <Text size={13} margin={3}>
              左
            </Text>
            <FitButton
              isActive={feedbackInfo.isLeftFit === true}
              onClick={() => handleClickFit('L', true)}
              type={'secondary'}
              size={'small'}
            >
              フィットした
            </FitButton>
            <FitButton
              isActive={feedbackInfo.isLeftFit === false}
              onClick={() => handleClickFit('L', false)}
              type={'secondary'}
              size={'small'}
            >
              フィットしない
            </FitButton>

            <FeedbackFoot
              whetherLR={'L'}
              isFit={feedbackInfo.isLeftFit}
              points={feedbackInfo.points}
              onClickPoint={handleClickPoint}
              onSelectTightOrLoose={handleSelectTightOrLoose}
              currentSelectedPoint={currentSelectedPoint}
            />
          </Column>
          <Column>
            <Text size={13} margin={3}>
              右
            </Text>
            <FitButton
              isActive={feedbackInfo.isRightFit === true}
              onClick={() => handleClickFit('R', true)}
              type={'secondary'}
              size={'small'}
            >
              フィットした
            </FitButton>
            <FitButton
              isActive={feedbackInfo.isRightFit === false}
              onClick={() => handleClickFit('R', false)}
              type={'secondary'}
              size={'small'}
            >
              フィットしない
            </FitButton>

            <FeedbackFoot
              whetherLR={'R'}
              isFit={feedbackInfo.isRightFit}
              points={feedbackInfo.points}
              onClickPoint={handleClickPoint}
              onSelectTightOrLoose={handleSelectTightOrLoose}
              currentSelectedPoint={currentSelectedPoint}
            />
          </Column>
        </TwoColumn>
        <div>
          {canSelectLeftPoint || canSelectRightPoint ? (
            <Text
              size={9}
              margin={10}
              style={{
                margin: '10px -20px',
                textAlign: 'center'
              }}
            >
              サイズが合わないと感じた部位を押してください
            </Text>
          ) : (
            <span />
          )}
        </div>
        <br />
        <div>
          <Button type={'secondary'} onClick={handleSave}>
            完了する
          </Button>
        </div>
      </Inner>

      {isCompleteFeedback && (
        <Completed>
          <div>
            <div>フィードバックしました！</div>
          </div>
        </Completed>
      )}
    </Modal>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px auto 16px auto;
  > img {
    width: 52px;
    height: 52px;
    border: 2px solid ${({ theme }) => theme.color.borderGray};
    border-radius: 26px;
    margin-right: 12px;
  }
  > span {
    font-size: 17px;
    font-weight: bold;
  }
`

const Column = styled.div`
  width: 50%;
  text-align: center;
`

const TwoColumn = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 -28px;
  > ${Column}:nth-child(2) {
    border-left: 1px solid ${({ theme }) => theme.color.inputBorderGray};
  }
`

const FitButton = styled(Button)`
  background-color: ${({ isActive, theme }) =>
    isActive === true ? theme.color.borderGray : 'white'};
  margin: 0 auto 8px auto;
  width: calc(100% - 36px);
`

const Completed = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.2);

  > div {
    width: 80%;
    height: 140px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 3px solid yellow;
    background-color: rgba(255, 255, 255, 0.8);
  }

  > div > div {
  }
`
