// @flow
import React from 'react'
// import { compose } from 'recompose'
import styled from 'styled-components'
import constants from '@src/constants'

import { Link } from 'react-router-dom'
import logoPng from '@src/assets/img/logo.png'

// 検討: 著作権表示などの共通コンポーネント化

type ExternalProps = {
  children: any
}

type Props = ExternalProps

export default function(props: Props) {
  return (
    <Root>
      <Header>
        <h1>
          <Link to="/">
            <img src={logoPng} width="67" height="22" alt="FlickFit" />
          </Link>
        </h1>
      </Header>
      <main>{props.children}</main>
      <Footer>
        <p>
          <small>©️Copyright Flicfit All rights reserved.</small>
        </p>
      </Footer>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  width: 100%;
  max-width: ${constants.LAYOUT_MAX_WIDTH}px;
  min-height: 100vh;
  background: #fff;
  margin: 0 auto;

  /* フッターの高さ */
  padding-bottom: 30px;
`

const Header = styled.header`
  background: #fff000;
  text-align: center;
  padding: 20px 0;
  h1 {
    margin: 0;
  }
`

const Footer = styled.footer`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
  text-align: center;
  small {
    font-size: 10px;
    color: ${({ theme }) => theme.color.gray};
  }
`
