// @flow
import * as React from 'react'
import styled from 'styled-components'

import icoChevronDownImg from '@src/assets/img/ico_chevron_down.png'

type Props = {
  options: Array<{ label: any, value: any }>,
  defaultOption?: string
}

export default function({ options, defaultOption, ...rest }: Props) {
  const list = []
  if (defaultOption) {
    list.push(
      <option value="" key="">
        {defaultOption}
      </option>
    )
  }
  options.map(option => {
    list.push(
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    )
  })
  return <Select {...rest}>{list}</Select>
}

const Select = styled.select`
  border: 1px solid #e9e9e9;
  display: inline-block;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px 30px 10px 15px;
  background-image: url(${icoChevronDownImg});
  background-repeat: no-repeat;
  background-position: top 50% right 10px;
  background-size: 12px 7px;
  background-color: #fff;
  margin-bottom: 10px;
`
