// @flow
import React from 'react'
import { Switch } from 'react-router-dom'
import BaseRoute from './BaseRoute'
import PrivateRoute from './PrivateRoute'

import MyPage from '@src/components/pages/MyPage'
import LoginPage from '@src/components/pages/LoginPage'
import LoginCallbackPage from '@src/components/pages/LoginCallbackPage'
import SignupPage from '@src/components/pages/SignupPage'
import SignupFormPage from '@src/components/pages/SignupFormPage'
import ChoicesAndKartePage from '@src/components/pages/ChoicesAndKartePage'
import FootDetailPage from '@src/components/pages/FootDetailPage'
import ItemDetailPage from '@src/components/pages/ItemDetailPage'
import FittingPage from '@src/components/pages/FittingPage'
import FittingDetailPage from '@src/components/pages/FittingDetailPage'
import RecommendationPage from '@src/components/pages/RecommendationPage'
import FavoritesPage from '@src/components/pages/FavoritesPage'
import AccountPage from '@src/components/pages/AccountPage'
import AccountEditPage from '@src/components/pages/AccountEditPage'
import AccountEditStoresPage from '@src/components/pages/AccountEditStoresPage'
import AccountEditWithdrawPage from '@src/components/pages/AccountEditWithdrawPage'
import ResetPasswordPage from '@src/components/pages/ResetPasswordPage'
import ResetPasswordSubmitPage from '@src/components/pages/ResetPasswordSubmitPage'
import ResetPasswordCompletePage from '@src/components/pages/ResetPasswordCompletePage'

import NotFoundPage from '@src/components/pages/NotFoundPage'

export default function Main() {
  // 検討: 共通処理が増えたらBaseRouteの方にまとめるなど共通化する
  // https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
  window.scrollTo(0, 0)

  return (
    <Switch>
      <BaseRoute path="/login" component={LoginPage} />
      <BaseRoute path="/logincb" component={LoginCallbackPage} />
      <PrivateRoute exact path="/" component={MyPage} />
      <BaseRoute exact path="/signup" component={SignupPage} />
      <BaseRoute path="/signup/form" component={SignupFormPage} />
      {
        // <Route path="/terms" component={NotFoundPage} />
        // <Route path="/privacy_policy" component={NotFoundPage} />
      }
      <BaseRoute exact path="/reset_password" component={ResetPasswordPage} />
      <BaseRoute exact path="/reset_password/:orguser" component={ResetPasswordPage} />
      <BaseRoute
        exact path="/reset_password/submit/:code"
        component={ResetPasswordSubmitPage}
      />
      <BaseRoute
        exact path="/reset_password/complete/done"
        component={ResetPasswordCompletePage}
      />

      <BaseRoute
        path="/choices_and_karte/:encoded_id"
        component={ChoicesAndKartePage}
      />

      <PrivateRoute
        exact
        path="/feet"
        render={props => <FootDetailPage useLatestFoot {...props} />}
      />
      <PrivateRoute path="/feet/:footUuid" component={FootDetailPage} />
      <PrivateRoute path="/item/:uuid" component={ItemDetailPage} />
      <PrivateRoute path="/favorites" component={FavoritesPage} />
      <PrivateRoute exact path="/fitting" component={FittingPage} />
      <PrivateRoute path="/fitting/:reviewId" component={FittingDetailPage} />
      <PrivateRoute path="/recommendation" component={RecommendationPage} />
      <PrivateRoute exact path="/account" component={AccountPage} />
      <PrivateRoute
        exact
        path="/account/edit/withdraw"
        component={AccountEditWithdrawPage}
      />
      <PrivateRoute
        exact
        path="/account/edit/stores"
        component={AccountEditStoresPage}
      />
      <PrivateRoute
        path="/account/edit/:propertyName"
        component={AccountEditPage}
      />

      {/* Not Found */}
      <BaseRoute component={NotFoundPage} />
    </Switch>
  )
}
