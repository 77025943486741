// @flow
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import icoChevronRightImg from '@src/assets/img/ico_chevron_right.png'

type Item = {
  path?: string,
  text: string,
  color?: string,
  handleClick?: () => void
}
type Props = {
  items: Array<Item>,
  handleClickFinally?: () => void
}

export default function({ items, handleClickFinally }: Props) {
  return (
    <ListMenu>
      {items.map((item, index) => (
        <ListMenuItem key={index} color={item.color}>
          {item.handleClick ? (
            <a
              onClick={_ => {
                item.handleClick && item.handleClick()
                handleClickFinally && handleClickFinally()
              }}
            >
              {item.text}
            </a>
          ) : (
            <Link
              to={item.path || ''}
              onClick={_ => {
                handleClickFinally && handleClickFinally()
              }}
            >
              {item.text}
            </Link>
          )}
        </ListMenuItem>
      ))}
    </ListMenu>
  )
}

const ListMenu = styled.ul`
  border-top: 1px solid ${({ theme }) => theme.color.lightGray};
  margin: 0;
`

const ListMenuItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGray};
  font-size: 14px;
  a {
    background: url(${icoChevronRightImg}) no-repeat right 20px center;
    background-size: 7px 12px;
    padding: 20px 20px;
    display: block;
    color: ${({ color, theme }) => (color ? color : theme.color.black)};
  }
`
