// @flow
import React from 'react'
import styled from 'styled-components'
import { compose, withStateHandlers } from 'recompose'

import PrivatePageLayout from '@src/components/templates/PrivatePageLayout'
import Inner from '@src/components/templates/Inner'
import Block from '@src/components/templates/Block'
import PageTitle from '@src/components/molecules/PageTitle'
import Section from '@src/components/templates/Section'
import BlockTitle from '@src/components/atoms/BlockTitle'
import Measured from '@src/components/molecules/Measured'
import FeetRecommendSize from '@src/components/organisms/FeetRecommendSize'
import FeetPartsSize from '@src/components/organisms/FeetPartsSize'
import FeetType from '@src/components/organisms/FeetType'
import Aside from '@src/components/templates/Aside'
import RecommendationList from '@src/components/organisms/RecommendationList'
import ListMenu from '@src/components/molecules/ListMenu'
import Foot3d from '@src/components/organisms/Foot3d'
import NotFoundPage from '@src/components/pages/NotFoundPage'

import ModalFeetTypes from '@src/components/organisms/ModalFeetTypes'
import ModalShoesDetail from '@src/components/organisms/ModalShoesDetail'
import ModalFeedback from '@src/components/organisms/ModalFeedback'

import FootModel from '@src/models/FootModel'
import MeasureHistoryItemModel from '@src/models/MeasureHistoryItemModel'
import type { DisplayItemInterface } from '@src/types'

import SpinnerThumbnailLoading from '@src/components/atoms/SpinnerThumbnailLoading'
import ImageGallery from 'react-image-gallery'
import CheckButton from '@src/components/atoms/CheckButton'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'
import Price from '@src/components/atoms/Price2'
import theme from '@src/misc/theme'
import Review from '@src/components/atoms/Review'
import ReviewPart from '@src/components/atoms/ReviewPart'
import ReviewComment from '@src/components/atoms/ReviewComment'



type ExternalProps = {
  is404PageShown: boolean,
  item:Object, 
  images: Array<Object>,
  review: Object,
  footSurvey: Object,
  loaded: boolean,
  setLoaded: boolean => void
}

type Props = ExternalProps & {
  feedbackModalRef: { current: null | any },
  shoesDetailModalRef: { current: null | any },
  footTypeModalRef: { current: null | any }
}

export const enhancePresentation = compose(
  withStateHandlers(
    {
    },
    {}
  )
)

export default function Presentation({
  is404PageShown,
  item,
  images,
  review,
  footSurvey,
  loaded,
  setLoaded
}: Props) {


console.log("  ;;  ", item, images,  review, footSurvey)
  // 該当するfootUuidがない
  if (is404PageShown) {
    return <NotFoundPage />
  }

  if (!item) {
    // 仮：データ取得中の場合の表示（取得中はfootの値はnullなので表示できない）
    return (
      <PrivatePageLayout>
        <Section>&nbsp;</Section>
      </PrivatePageLayout>
    )
  }


  return (
    <PrivatePageLayout>
    <PageTitle title={item.product.productName} />
      <Inner>
        <Gallery loaded={loaded}>
          <Loading>
            <SpinnerThumbnailLoading loaded={loaded} size={16} />
          </Loading>
          <ImageGallery
            items={images}
            startIndex={0}
            onImageLoad={_ => setLoaded(true)}
          />
        </Gallery>
        <ShoesText>
          <div>
            <Text color={theme.color.gray} size={12} margin={8} textAlign='left' maxWidth='50%'>
              {item.product.brandName}
              <br />
              {`${item.product.productNumber} / ${item.measurement.makerSize}${item.product.unit}  / ${item.mesh.makerColor}`}
            </Text>

            {item.price > 0 && 
            <Price
              price={parseInt(item.price)}
              taxDispSetting={item.taxDispSetting || ''}
            />
            }
            {/*
            <Text size={12}>{item.storeName}</Text>
            */}


          </div>
        </ShoesText>
        { (() => {
          const tight =review['fitting_log_shoe_4']
          const blister = review['fitting_log_shoe_5']

          const size =  item.makerSize
          const memo = review['fitting_log_shoe_6']


          const fittingLogShoe1 = review['fitting_log_shoe_1']
          const fittingLogShoe2 = review['fitting_log_shoe_2']
          const fittingLogShoe3 = review['fitting_log_shoe_3']

          let survey = Object.keys(footSurvey).map(e => { 
            if ( e != 'fitting_log_foot_3' ) {
              if ( footSurvey[e].answer ) return `${footSurvey[e].title}:${ footSurvey[e].answer}`
            }
          })
          console.log(
            "FOOT FURBEY " , footSurvey
          )
          const foot = footSurvey['fitting_log_foot_3']


        return (

        <Description>
        <ReviewHead>
          <p>{`${item.storeName}(${item.reviewcreatedTime}}`}</p>
          {`${footSurvey.fitting_log_foot_4.answer}で試着`} 
          <ReviewSize>{`${item.measurement.makerSize}${item.product.unit}`}</ReviewSize>
        </ReviewHead>

        <Review
          title={fittingLogShoe1.title}
          options={fittingLogShoe1.options}
          answer={fittingLogShoe1.answer}
          type={fittingLogShoe1.type}
        />
        <Review
          title={fittingLogShoe2.title}
          options={fittingLogShoe2.options}
          answer={fittingLogShoe2.answer}
          type={fittingLogShoe2.type}
        />
        <Review
          title={fittingLogShoe3.title}
          options={fittingLogShoe3.options}
          answer={fittingLogShoe3.answer}
          type={fittingLogShoe3.type}
        />
        <ReviewPart
          tight={tight}
          blister={blister}
        />

        <ReviewHeadFoot><p>足の特徴</p>{foot.answer.join('/')}</ReviewHeadFoot>

        </Description>
        )
          })()
        }
      </Inner>

    </PrivatePageLayout>
  )
}


const Loading = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vw - 80px);
  max-height: 100vh;
`

const Gallery = styled.div`
  position: relative;
  > ${Loading} {
    display: ${props => (props.loaded ? 'none' : 'block')};
  }
  > .image-gallery {
    display: ${props => (props.loaded ? 'block' : 'none')};
  }
`

const ShoesText = styled.div`
  text-align: center;
  position: relative;
  > div {
    display: flex;
  }
`

const StyledCheckButton = styled(CheckButton)`
  position: absolute;
  bottom: 100px;
  right: 20px;
`

const EcButton  = styled(Button)`
  margin-top: 10px;
`

const Description = styled.div`
  border: solid 2px #D9D9D9;
  border-radius: 20px;
  overflow: hidden;
  margin: 10px 0;
`
const ReviewHead = styled.div`
  position: relative; 
  background-color: #d9d9d9;
  padding: 5px 10px;
  size: 14px;
  > p {
    font-weight: bold;
  }
`
const ReviewSize = styled.div`
  position: absolute;
  bottom: 0px;
  right: 20px;
  background-color: #d9d9d9;
  font-size: 30px;  
`


const ReviewHeadFoot = styled.div`
  background-color: #d9d9d9;
  padding: 5px 10px;
  size: 14px;
  > p {
    font-weight: bold;
  }
`

const Aaa = styled.div`
  display: flex;
`
const Title = styled.div`
	margin-right: 0.5em;
  width:25%;
`
const Answer = styled.div`
  display: -webkit-flex;
  display: flex;
  width: 100%;
`

const Step = styled.div`
  padding: 0; 
  position: relative;
  min-width: 33%;
`
const Progress = styled.div`
  position: relative; 
  border-radius: 0px; 
  height: 1px; 
  box-shadow: none; 
  margin: 20px 0;
  display: flex;
  overflow: hidden;
  background-color: #707070;
  width: ${({ index  }) => index? '50%' : ''}
  left: ${({ index  }) => index && index == 'first'? '50%' : ''}
  opacity: ${({ opacity  }) => opacity ? opacity : ''}
`

const Dot = styled.span`
  position: absolute; 
  width: 10px; 
  height: 10px; 
  display: block; 
  background: #fff; 
  border: 1px solid #707070;
  top:  20px; 
  left: 50%; 
  margin-top: -5px; 
  margin-left: -5px; 
  border-radius: 50%;
  :after {
    display: ${({ selected }) => selected? 'block': 'none'};
    content: ' '; 
    width: 15px; 
    height: 15px; 
    background: #ff0; 
    border-radius: 510px; 
    position: absolute; 
    top: -3px; 
    left: -3px;
    opacity: 70%; 
  }
`
const Info = styled.div`
  color: #707070; 
  font-size: 14px;
  text-align: center;
`
