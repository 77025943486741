// @flow
import React from 'react'
import styled from 'styled-components'

import Shoes from '@src/components/molecules/ShoesFitting'
import Price from '@src/components/atoms/Price'
import StoreName from '@src/components/molecules/StoreName'
import Button from '@src/components/atoms/Button'
import ShoesMeta from '@src/components/molecules/ShoesMeta'

//import FavoriteItemModel from '@src/models/FavoriteItemModel'
import type { DisplayFittingItemInterface } from '@src/types'

type Props = {
  reviews: Array<DisplayFittingItemInterface>,
  onClickShoes?: (reviewId: number) => void,
}

export default function({
  reviews,
  onClickFavIco,
  onClickShoes,
}: Props) {

  console.log("FittingList " , reviews)
  return (
    <FittingList>
      {reviews.map((item, index) => (
        <li key={index}>
          <div>
            <Shoes
              review={item.review}
              imgPath={item.pictureUrl}
              onClickShoes={() => {
                if (onClickShoes) onClickShoes(item.reviewId)
              }}
            />
          </div>
          <div>
            <ShoesMeta
              textStyleAttributes={{
                size: 10,
                margin: 8
              }}
              brandName={item.brandName}
              productName={item.productName}
              productNumber={item.productNumber}
              makerColor={item.makerColor}
              makerMaterial={item.makerMaterial}
              makerSize={item.makerSize}
              unit={item.unit}
              makerWidth={item.makerWidth}
            />

            {item.price > 0 && 
            <Price
              price={item.price}
              taxDispSetting={item.taxDispSetting || ''}
            />
            }
            <StoreName name={item.storeName}  isFitted={true} />
          </div>
        </li>
      ))}
    </FittingList>
  )
}

const FittingList = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.lightGray};
    > div {
      width: 46%;
    }
    > div:last-child {
      button {
        margin-top: 15px;
      }
    }
  }
`
