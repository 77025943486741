// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: any
}

const ButtonC = ({ children, ...props }: Props) => (
  <ButtonStyle {...props}>{children}</ButtonStyle>
)

export default ButtonC

const ButtonStyle = styled.button`
  background-color: #f3f3f3;
  font-size: 13px;
  height: 30px;
  padding: 0 16px;
  border-radius: 15px;
  white-space: nowrap;
`
