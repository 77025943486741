// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  type: string,
  placeholder?: string
}

export default function({ ...rest }: Props) {
  return <Input {...rest} />
}

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.color.inputBorderGray};
  border-radius: 6px;
  box-shadow: none;
  width: 100%;
  display: block;
  margin: 0 0 10px;
  font-size: 14px;
  padding: 10px 15px;
  &::placeholder {
    color: #cccccc;
  }
`
