// @flow

type ToeShapeType = {
  name: string,
  text: string,
  illustPath: string
}

export const toeShapeTypes: { [key: string]: ToeShapeType } = {
  'ToeShapeType.EGYPTIAN_TOE': {
    name: 'フレンドリー',
    text:
      '社交的で温厚なあなたは、いつも友達に囲まれています。\n他人の意見を止める力があり、いつの間にかリーダー役を請け負うことになることもしばしば…\n時には人の意見を聞かずに靴を選んではいかがでしょうか？',
    illustPath: '/img/footType/EGYPTIAN_TOE.png'
  },
  'ToeShapeType.GREEK_TOE': {
    name: 'クリエイティブ',
    text:
      'クリエイティブ型のあなたは、好奇心旺盛でいつもエネルギーに溢れています。\nただ、没頭するあまり、周りが見えなくなってしまうことも多いかも…\n時には、誰かの靴と同じものを選ぶのはどうでしょう？',
    illustPath: '/img/footType/GREEK_TOE.png'
  },
  'ToeShapeType.ROMAN_TOE': {
    name: 'ロジカル',
    text:
      'ロジカル型のあなたは、じっくり考えてから動く慎重派。\n論理的に解決へ導く力があるので、難しい問題があなたに舞い込むことが多いかも…\n時には、その日の気分に任せて、靴を選んではどうでしょう？',
    illustPath: '/img/footType/ROMAN_TOE.png'
  },
  'ToeShapeType.GERMANIC_TOE': {
    name: 'ファイター',
    text:
      'ファイター型のあなたは、意志が強く、貫く力を持っています。\nただ、負けず嫌いな性格のあなたは、他人の意見と衝突しやすいかもしれません。\n時には自分で靴を選ばずに、誰かに選んでもらうのはどうでしょう？',
    illustPath: '/img/footType/GERMANIC_TOE.png'
  },
  'ToeShapeType.CELTIC_TOE': {
    name: 'フリーダム',
    text:
      'フリーダム型のあなたは、アンテナ感度が高く敏感なタイプ。\n常に新しいものを探し続けるので、一つの場所に止まらず、いろんなところに出没するあなた。\n時には、定番の靴を選んでみてはどうでしょう？',
    illustPath: '/img/footType/CELTIC_TOE.png'
  }
}
