// @flow
import React from 'react'
import { compose, withStateHandlers } from 'recompose'

import PrivatePageLayout from '@src/components/templates/PrivatePageLayout'
import Inner from '@src/components/templates/Inner'
import Row from '@src/components/templates/Row'
import PageTitle from '@src/components/molecules/PageTitle'
import FavoritesList from '@src/components/organisms/FavoritesList'
import Count from '@src/components/atoms/Count'
import FavoriteFilterSetting from '@src/components/organisms/FavoriteFilterSetting'
import ModalShoesDetail from '@src/components/organisms/ModalShoesDetail'
import ModalFeedback from '@src/components/organisms/ModalFeedback'
import FullModalFilteringShop from '@src/components/organisms/FullModalFilteringShop'
import FullModalFilteringCategory from '@src/components/organisms/FullModalFilteringCategory'

import type { DisplayItemInterface } from '@src/types'

type ExternalProps = {
  displayItems: Array<DisplayItemInterface>,
  filteringConditionTexts: { shop: string, category: string },
  handleClickFavIco: (item: DisplayItemInterface) => void,
  handleClickClearFilteringSetting: () => void
}

type Props = ExternalProps & {
  filteringShopModalRef: { current: null | any },
  filteringCategoryModalRef: { current: null | any },
  feedbackModalRef: { current: null | any },
  shoesDetailModalRef: { current: null | any }
}

export const enhancePresentation = compose(
  withStateHandlers(
    {
      filteringShopModalRef: React.createRef(),
      filteringCategoryModalRef: React.createRef(),
      feedbackModalRef: React.createRef(),
      shoesDetailModalRef: React.createRef()
    },
    {}
  )
)

export default function Presentation({
  displayItems,
  filteringConditionTexts,
  handleClickFavIco,
  handleClickClearFilteringSetting,

  filteringShopModalRef,
  filteringCategoryModalRef,
  feedbackModalRef,
  shoesDetailModalRef
}: Props) {
  const favorites = displayItems
  return (
    <PrivatePageLayout>
      <Inner>
        <PageTitle title="お気に入り" />

        <FavoriteFilterSetting
          shopText={filteringConditionTexts.shop}
          categoryText={filteringConditionTexts.category}
          onClickShopRow={() => {
            if (!filteringShopModalRef.current) return
            filteringShopModalRef.current.showModal()
          }}
          onClickCategoryRow={() => {
            if (!filteringCategoryModalRef.current) return
            filteringCategoryModalRef.current.showModal()
          }}
          onClickClearFilterSetting={handleClickClearFilteringSetting}
        />

        <Row>
          <Count number={favorites.length} />
        </Row>
        <FavoritesList
          favorites={favorites}
          onClickShoes={(item: DisplayItemInterface) => {
            if (!shoesDetailModalRef.current) return
            shoesDetailModalRef.current.showModal(item.itemUuid)
          }}
          onClickFavIco={handleClickFavIco}
          onClickFeedback={(item: DisplayItemInterface) => {
            if (!feedbackModalRef.current) return
            feedbackModalRef.current.showModal(item)
          }}
        />
      </Inner>

      <ModalShoesDetail
        mode={'favorite-detail'}
        modalRef={shoesDetailModalRef}
        items={favorites}
        onClickFavIco={handleClickFavIco}
        onClickFeedback={(item: DisplayItemInterface) => {
          if (!feedbackModalRef.current) return
          feedbackModalRef.current.showModal(item)
        }}
      />
      <ModalFeedback modalRef={feedbackModalRef} />
      <FullModalFilteringShop modalRef={filteringShopModalRef} />
      <FullModalFilteringCategory modalRef={filteringCategoryModalRef} />
    </PrivatePageLayout>
  )
}
