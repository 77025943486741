// @flow
import React from 'react'
import styled from 'styled-components'
import constants from '@src/constants'

import Text from '@src/components/atoms/Text'

import icoChevronLeftImg from '@src/assets/img/ico_chevron_left.png'

type Props = {
  children: any,
  title: string,
  isShown: boolean,
  onClose: () => void
}

export default function Presentation({
  children,
  title,
  isShown,
  onClose
}: Props) {
  if (!isShown) return ''
  return (
    <FullModal>
      {title && (
        <Header>
          <img src={icoChevronLeftImg} alt="" width={7} onClick={onClose} />
          <Text textAlign={'center'} size={15}>
            <strong>{title}</strong>
          </Text>
        </Header>
      )}
      {children}
    </FullModal>
  )
}

const FullModal = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  max-width: ${constants.LAYOUT_MAX_WIDTH}px;
  height: 100vh;
  margin: 0 auto;
  background: #fff;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
`
const Header = styled.div`
  padding: 25px 15px;
  position: relative;
  img {
    position: absolute;
    left: 15px;
    top: calc(50% - 6px);
  }
`
