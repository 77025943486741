// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import configureStore from './redux/configureStore'
import { registerStore as registerStoreToApi } from './api'
import commonHandlers from '@src/commonHandlers'
import analytics from '@src/analytics'
import Root from './Root'

const { store, runSaga } = configureStore({})
registerStoreToApi(store)
commonHandlers.registerStore(store)
analytics.registerStore(store)
const $root = document.getElementById('app-root')

if ($root) {
  runSaga()
  ReactDOM.render(
    <Provider store={store}>
      <Root />
    </Provider>,
    $root
  )
}
