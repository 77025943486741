import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import analytics from '@src/analytics'

/*
 * このコンポーネントでURL（パス）の変更を監視し、GAのdataLayer.pushを呼んでいます
 */

type Props = {
  location: any
}

type State = {}

class BaseRoute extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    if (!this.props.location) return
    const curr = this.props.location.pathname
    analytics.sendAnalyticsData(curr)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.location || !this.props.location) return
    const prev = prevProps.location.pathname
    const curr = this.props.location.pathname
    if (prev !== curr) {
      analytics.sendAnalyticsData(curr)
    }
  }

  render() {
    return <Route {...this.props} />
  }
}

export default BaseRoute
