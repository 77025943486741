// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  name: string,
  value: string | number,
  label: string | number,
  defaultChecked: boolean
}

export default function({
  name,
  value,
  label,
  defaultChecked,
  ...rest
}: Props) {
  return (
    <CheckBox type={'radio'} {...rest}>
      <input
        type="checkbox"
        name={name}
        value={value}
        defaultChecked={defaultChecked}
      />
      <span>{label}</span>
    </CheckBox>
  )
}

const CheckBox = styled.label`
  display: inline-block;
  font-size: 14px;
  margin-right: 35px;
  margin-bottom: 10px;
  input {
    display: none;
  }
  span {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1.5;
    &:before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 10px;
      border: 1px solid ${({ theme }) => theme.color.lightGray};
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  input:checked + span::before {
    background: ${({ theme }) => theme.color.yellow};
    border-color: ${({ theme }) => theme.color.yellow};
  }
  input:checked + span::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 5px;
    display: block;
    width: 5px;
    height: 8px;
    margin-top: -5px;
    transform: rotate(40deg);
    border-bottom: 2px solid ${({ theme }) => theme.color.black};
    border-right: 2px solid ${({ theme }) => theme.color.black};
  }
`
