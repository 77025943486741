// @flow
import { toeShapeTypes } from '@src/misc/resources'
import { formatSize, formatAncleAngle } from '@src/helpers/number'

/* モデル：足カルテのFoot */
export default class FootModel {
  __raw__: any

  footUuid: string
  storeName: string
  tmpThreeUrl: string
  jisSize: number
  jisWidth: string
  jisWidthJa: string

  // 追加・加工パラメータ
  displayDate: string
  formattedMeasurement: {
    right: {
      [key: string]: number
    },
    left: {
      [key: string]: number
    }
  }
  toeShapeTypeCode: string
  toeShapeType: {
    name: string,
    text: string,
    illustPath: string
  }

  constructor(raw: any) {
    this.__raw__ = raw

    Object.assign(this, {
      ...raw
    })


    this.displayDate = raw.scannedTime.split('T')[0].replace(/\-/g, '.')
    if( raw.measValue.footMeasurement ) {
      this.formattedMeasurement = {
        right: this.calcFormattedMeasurement(raw.measValue.footMeasurement.right),
        left: this.calcFormattedMeasurement(raw.measValue.footMeasurement.left)
      }
    } else {
      this.formattedMeasurement = {
        right: this.calcFormattedMeasurement(raw.measValue.right.footMeasurement),
        left: this.calcFormattedMeasurement(raw.measValue.left.footMeasurement)
      }
    }

    this.toeShapeTypeCode = this.formattedMeasurement.left.toeShapeType
    this.toeShapeType = toeShapeTypes[this.toeShapeTypeCode]
  }

  calcFormattedMeasurement(data: Object): Object {
    // measValueのデータを元にmm単位をcm単位に変換して表示用データを出力するメソッド
    return {
      // 1.足長
      footLength: formatSize(data.footLength),
      // 2.足幅
      footWidth: formatSize(data.footBoundingWidth? data.footBoundingWidth:data.footWidth),
      // 3.足囲
      footCircumference: formatSize(data.footWidth70PercentCircumference? data.footWidth70PercentCircumference:data.footCircumference),
      // 4.アーチの長さ
      //archLength: formatSize(data.archLength),
      // 5.指の長さ
      //fingerLength: formatSize(data.fingerLength),
      // 6.踵から親指の付け根
      //lengthHeelToThumbBigMound: formatSize(data.lengthHeelToThumbBigMound),
      // 7.踵から小指の付け根
      //lengthHeelToPinkySmallMound: formatSize(data.lengthHeelToPinkySmallMound),
      // 8.踵幅
      ankleWidth: formatSize(data.ankleWidth),
      // 9.甲高
      instepHeight: formatSize(data.instepHeight),
      // 10.踵の傾き
      //ankleAngle: formatAncleAngle(data.ankleAngle),
      toeShapeType: data.toeShapeType.replace('FootToeShapeType','ToeShapeType')
    }
  }
}
