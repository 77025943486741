// @flow
import React from 'react'
import styled from 'styled-components'
import { pure } from 'recompose'
import theme from '@src/misc/theme'
import Text from '@src/components/atoms/Text'

import tabIconRecommendImg from '@src/assets/img/tab_icon_recommend.png'
import tabIconFavoriteImg from '@src/assets/img/tab_icon_favorite.png'

type Props = {
  type: 'favorite' | 'recommend',
  children: any
}

function NoItemsInTab({ type, children }: Props) {
  const img = type === 'favorite' ? tabIconFavoriteImg : tabIconRecommendImg
  return (
    <Wrapper>
      <Icon src={img} />
      <TextBox>
        <Text color={theme.color.gray} size={13} margin={0}>
          {children}
        </Text>
      </TextBox>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  padding: 12px 0;
  justify-content: center;
  align-items: center;
`
const TextBox = styled.div`
  text-align: left;
  justify-content: center;
`
const Icon = styled.img`
  margin-right: 10px;
  display: block;
  width: 28px;
  height: 28px;
`

export default pure(NoItemsInTab)
