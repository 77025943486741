// @flow
import * as React from 'react'
import styled from 'styled-components'
import { addComma } from '@src/helpers/number'

type Props = {
  price: number,
  taxDispSetting: ?string
}

export default function({ price }: Props) {
  return (
    <Price>
      ¥{addComma(price)}
      <span>（税抜）</span>
    </Price>
  )
}

const Price = styled.p`
  font-size: 14px;
  font-weight: bold;
  span {
    font-size: 11px;
    font-weight: normal;
  }
`
