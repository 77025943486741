// @flow
//import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withState, withHandlers, withProps } from 'recompose'
import {
  recommendationConditionActionCreators,
  withTagKeys
} from '@src/redux/modules/specifics/recommendationCondition'

type Props = {
  recommendationCondition: any,
  recommendationConditionActions: any,
  tagKeys: Array<string>
}

export const enhanceConnection = compose(
  connect(
    state => ({
      recommendationCondition: state.specifics.recommendationCondition
    }),
    dispatch => ({
      recommendationConditionActions: bindActionCreators(
        { ...recommendationConditionActionCreators },
        dispatch
      )
    })
  ),

  withTagKeys,

  withState('favoriteSize', 'setFavoriteSize', 24),

  withProps(({ recommendationCondition, tagKeys }: Props) => {
    const { selectedTags, tagsCollection } = recommendationCondition

    const nonSelectTags = {}
    tagKeys.forEach(tagKey => {
      nonSelectTags[tagKey] = !selectedTags[tagKey]
        ? tagsCollection[tagKey]
        : []
    })
    return {
      selectedTags: selectedTags,
      nonSelectTags: nonSelectTags
    }
  }),

  withHandlers({
    handleSelectTag: ({ recommendationConditionActions }: Props) => (
      selectedTagKey: string,
      selectedTagIndex: number
    ) => {
      recommendationConditionActions.selectTag(selectedTagKey, selectedTagIndex)
    },
    handleRemoveTag: ({ recommendationConditionActions }: Props) => (
      selectedTagKey: string
    ) => {
      recommendationConditionActions.unselectTag(selectedTagKey)
    }
  })
)
