// @flow
export const URLJoin = (...args: Array<string>) =>
  args
    .join('/')
    .replace(/[\/]+/g, '/')
    .replace(/^(.+):\//, '$1://')
    .replace(/^file:/, 'file:/')
    .replace(/\/(\?|&|#[^!])/g, '$1')
    .replace(/\?/g, '&')
    .replace('&', '?')

export const formatBirthdate = function(
  year: string,
  month: string,
  day: string
) {
  const MM = month.length === 1 ? `0${month}` : month
  const DD = day.length === 1 ? `0${day}` : day
  let birthdate = [year, MM, DD].join('-')
  if (birthdate == '--') birthdate = ''
  return birthdate
}

export const formatJisWidth = function(jisWidth: string) {
  if (jisWidth === 'EE') return '2E'
  if (jisWidth === 'EEE') return '3E'
  if (jisWidth === 'EEEE') return '4E'
  return jisWidth
}

export const lineState = function(l: number) {
    // 生成する文字列の長さ
    if( !l ) l = 8;
    // 生成する文字列に含める文字セット
    const c = "abcdefghijklmnopqrstuvwxyz0123456789";
    const cl = c.length;
    let  r = "";
    for(var i=0; i<l; i++){
      r += c[Math.floor(Math.random()*cl)];
    }
    return r
}

export const getUrlQueries = function(): any {
  const queryStr = window.location.search.slice(1);  // 文頭?を除外
  let queries = {};

  // クエリがない場合は空のオブジェクトを返す
  if (!queryStr) {
    return queries;
  }

  // クエリ文字列を & で分割して処理
  queryStr.split('&').forEach(function(queryStr) {
    // = で分割してkey,valueをオブジェクトに格納
    var queryArr = queryStr.split('=');
    queries[queryArr[0]] = queryArr[1];
  });

  return queries;
}
