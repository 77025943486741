// @flow
import { createActionThunk } from 'redux-thunk-actions'
import { masterActionCreators } from '@src/redux/modules/master'
import { userActionCreators } from '@src/redux/modules/user'
import { storeActionCreators } from '@src/redux/modules/store'

type LoginProcessResult = {
  isSucceeded: boolean
}

/*
 * ログインプロセス
 */

/*==================================
* Actions
==================================*/
const THUNK = {}

// Public
THUNK.LOGIN_BY_EMAIL = 'loginProcess/THUNK.LOGIN_BY_EMAIL'
THUNK.AUTO_LOGIN = 'loginProcess/THUNK.AUTO_LOGIN'
THUNK.CALLBACK_LOGIN = 'loginProcess/THUNK.CALLBACK_LOGIN'

/*==================================
* Action Creators
==================================*/
export const loginProcessActionCreators = {
  // Emailログイン
  loginByEmail: createActionThunk(
    THUNK.LOGIN_ASYNC,
    async (email, password, { dispatch }): Promise<LoginProcessResult> => {
      try {
        // 新規token取得
        await dispatch(userActionCreators.fetchAuthToken(email, password))
      } catch (err) {
        // ログイン失敗
        if (err.responseData && err.responseData.statusCode === 401) {
          if ( err.responseData.message ==  "NEED_TO_REGISTER_PASSWORD" ) {
            // パスワード再設定のお願い
            return { isSucceeded: false, isResetPassword: true }
          }
          return { isSucceeded: false }
        } else throw err
      }

      //
      await dispatch(masterActionCreators.fetch())

      // // 店舗履歴を取得
      await dispatch(storeActionCreators.fetch())

      // ユーザ情報を取得 -> これで isLoginCompleted === true になる
      await dispatch(userActionCreators.fetchUser())

      // ログインユーザの初期データ確保成功
      return { isSucceeded: true }
    }
  ),

  // 自動ログイン
  autoLogin: createActionThunk(
    THUNK.AUTO_LOGIN,
    async ({ dispatch }): Promise<LoginProcessResult> => {
      // この処理は厳密にはログインを実行する処理ではないのですが、
      // App起動時にトークンがまだ生きていればログインした直後と同じように
      // ユーザデータを保持した状態（=isLoginCompletedの状態）にまで
      // 自動的にしますよ、という意味で自動ログインと名付けています。

      try {
        // localStorageからtoken復元
        await dispatch(userActionCreators.restoreAuthToken())

        // トークンはあっても有効期限は切れている可能性あり
        // 以下のログイン時初期データの取得がトークン有効期限確認を兼ねる

        // footuuidの紐付け
        await dispatch(userActionCreators.setFootUuid())

        //
        await dispatch(masterActionCreators.fetch())

        // // 店舗履歴を取得
        await dispatch(storeActionCreators.fetch())

        // ユーザ情報を取得 -> これで isLoginCompleted === true になる
        await dispatch(userActionCreators.fetchUser())

        // ログインユーザの初期データ確保成功
        return { isSucceeded: true }
      } catch (err) {
        // ログイン失敗（エラーでも正常系としてまとめる）
        return { isSucceeded: false }
      }
    }
  ),

  // LINEログインCallback
  loginByLineCallback: createActionThunk(
    THUNK.CALLBACK_LOGIN,
    async ({ dispatch }): Promise<LoginProcessResult> => {
      try {
        // 新規token取得
        await dispatch(userActionCreators.fetchLineAuthTokenCallback())
      } catch (err) {
        // ログイン失敗
        console.error(err)
        if (err.responseData && err.responseData.statusCode === 401) {
          return { isSucceeded: false }
        } else throw err
      }
      // footuuidの紐付け
      await dispatch(userActionCreators.setFootUuid())

      await dispatch(masterActionCreators.fetch())

      // 店舗履歴を取得
      await dispatch(storeActionCreators.fetch())

      // ユーザ情報を取得 -> これで isLoginCompleted === true になる
      await dispatch(userActionCreators.fetchUser())

      // ログインユーザの初期データ確保成功
      return { isSucceeded: true }

    }
  ),

}

/*==================================
Initial State
==================================*/

/*==================================
* Reducer
==================================*/

/*==================================
* Selector
==================================*/
