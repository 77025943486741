// @flow
import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

type Props = {
  children: any,
  href?: string,
  type: 'primary' | 'secondary' | 'success',
  size?: string,
  weight?: string,
  height? : string,
  external?: string
}

export default function({ children, href, external, ...rest }: Props) {
  if (href) {

    if ( external) {
    return (
      <Button as={Link} to={{pathname: href}} {...rest} target={external}  rel={ "noopener"} className={`pumaLink`}>
        {children}
      </Button>
    )

    }
    return (
      <Button as={Link} to={href} {...rest}>
        {children}
      </Button>
    )
  }
  return <Button {...rest}>{children}</Button>
}

const Button = styled.button`
  font-size: ${({ size }) => {
    if (size === 'small') return '10px'
    else if (size === 'large') return '15px'
    else return '13px'
  }};
  padding: ${({ size }) => {
    if (size === 'small') return '8px 5px 7px'
    else return '14px 20px 13px'
  }};
  background: ${({ type, theme }) => {
    if (type === 'primary') return theme.color.black
    else if (type === 'secondary') return theme.color.white
    else if (type === 'success') return theme.color.yellow
  }};
  color: ${({ type, theme }) => {
    if (type === 'primary') return theme.color.white
    else if (type === 'secondary') return theme.color.black
    else if (type === 'success') return theme.color.black
  }};
  border-color: ${({ type, theme }) => {
    if (type === 'primary') return theme.color.black
    else if (type === 'secondary') return theme.color.borderGray
    else if (type === 'success') return theme.color.yellow
  }};
  border-width: 2px;
  border-style: solid;
  font-weight: ${({ weight }) => (weight ? weight : 'normal')};
  border-radius: 28px;
  line-height: 1;
  width: 100%;
  height: ${({ height }) => (height ? height : '')};
  display: inline-block;
  text-align: center;
  &:disabled {
    color: ${({ theme }) => theme.color.gray};
  }
  img {
    display: inline-block;
    margin-right: 12px;
    position: relative;
    top: -1px;
  }
`
