// @flow
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withState, withHandlers } from 'recompose'
import withRefererableMethods from '@src/helpers/withRefererableMethods'
import { filteringConditionActionCreators } from '@src/redux/modules/specifics/filteringCondition'
import { withCategoryNames } from '@src/redux/modules/master'
import analytics from '@src/analytics'
import constants from '@src/constants'

type Refererable = {
  showModal: () => void
}

type Props = {
  modalRef: { current: null | Refererable },

  master: any,
  categoryNames: Array<string>,
  filteringCondition: any,
  filteringConditionActions: any,

  isShown: boolean,
  setIsShown: boolean => void,
  checkBoxes: Array<{ value: string, label: string, defaultChecked: boolean }>,
  formRef: { current: null | HTMLFormElement }
}

export const enhanceConnection = compose(
  connect(
    state => ({
      master: state.master,
      filteringCondition: state.specifics.filteringCondition
    }),
    dispatch => ({
      filteringConditionActions: bindActionCreators(
        { ...filteringConditionActionCreators },
        dispatch
      )
    })
  ),

  withCategoryNames,

  withState('isShown', 'setIsShown', false),
  withState('formRef', 'setFormRef', React.createRef()),
  withState('checkBoxes', 'setCheckBoxes', []),

  withHandlers({
    handleCancel: ({ setIsShown }: Props) => () => {
      setIsShown(false)
    },

    handleSave: ({
      categoryNames,
      filteringConditionActions,
      formRef,
      setIsShown
    }: Props) => async () => {
      const selectedCategoryNames = []
      Array.prototype.forEach.call(
        formRef.current,
        (input: HTMLInputElement, i: number) => {
          if (input.checked) selectedCategoryNames.push(categoryNames[i])
        }
      )

      await filteringConditionActions.updateSelectedCategory(
        selectedCategoryNames
      )
      setIsShown(false)
    }
  }),

  withRefererableMethods('modalRef', {
    showModal() {
      // カテゴリー選択モーダルを開く
      const {
        categoryNames,
        setCheckBoxes,
        filteringCondition,
        setIsShown
      } = this.props

      // アクセス解析 送信
      const pageId = constants.PSEUDO_PAGE_PATH_MODAL_FILTERING_CATEGORY
      analytics.sendAnalyticsData(pageId)

      // カテゴリー名をチェックリストとして表示する
      const checkBoxes = categoryNames.map(categoryName => {
        return {
          value: categoryName,
          label: categoryName,
          defaultChecked: filteringCondition.category.selectedNames.includes(
            categoryName
          )
        }
      })
      //console.dir(filteringCondition)
      setCheckBoxes(checkBoxes)

      setIsShown(true)
    }
  })
)
