// @flow
import React from 'react'
import styled from 'styled-components'

type Props = {
  children: any,
  label?: string,
  errorMessage?: string,
  note?: string,
  required?: boolean
}

export default function({ children, label, errorMessage, note, required }: Props) {
  return (
    <FormRow errorMessage={errorMessage}>
      <span>
        <LabelText>{label}{required && "※"}</LabelText>
        {children}
        {errorMessage && <Error>{errorMessage}</Error>}
        {note && <Note>{note}</Note>}
      </span>
    </FormRow>
  )
}

const FormRow = styled.div`
  margin-bottom: 20px;
  input,
  select {
    ${({ errorMessage, theme }) => inputColor(errorMessage, theme)};
  }
`

const LabelText = styled.span`
  font-size: 11px;
  display: block;
  margin-bottom: 2px;
`

const Error = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.color.red};
  display: block;
  margin-top: -5px;
  margin-bottom: 5px;
`

const Note = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.color.gray};
  display: block;
  margin-top: -5px;
`

const inputColor = (errorMessage, theme) => {
  if (errorMessage) {
    return `
      background-color: #ffdddd;
      border-color: ${theme.color.red};
    `
  }
}
