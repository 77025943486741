// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: any
}

export default function({ children, ...rest }: Props) {
  return <Table {...rest}>{children}</Table>
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    font-size: 10px;
    padding: 6px 5px;
    vertical-align: middle;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.color.lightGray};
  }
  th {
    font-weight: normal;
  }
  thead {
    td {
      font-weight: bold;
    }
  }
`
