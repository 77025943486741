// @flow
import React from 'react'
import styled from 'styled-components'
import { compose, withStateHandlers } from 'recompose'

import PrivatePageLayout from '@src/components/templates/PrivatePageLayout'
import Inner from '@src/components/templates/Inner'
//import Block from '@src/components/templates/Block'
import PageTitle from '@src/components/molecules/PageTitle'
import Section from '@src/components/templates/Section'
import NotFoundPage from '@src/components/pages/NotFoundPage'

import type { DisplayItemInterface } from '@src/types'

import SpinnerThumbnailLoading from '@src/components/atoms/SpinnerThumbnailLoading'
import ImageGallery from 'react-image-gallery'
import CheckButton from '@src/components/atoms/CheckButton'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'
import Price from '@src/components/atoms/Price2'
import theme from '@src/misc/theme'
import Review from '@src/components/atoms/Review'
import ReviewPart from '@src/components/atoms/ReviewPart'
import ReviewComment from '@src/components/atoms/ReviewComment'

type ExternalProps = {
  is404PageShown: boolean,
  item:Object, 
  images: Array<string>,
  reviews: Array<Object>,
  shoeFeatures: Array<Object>,
  reviewDetail:Array<number>,
  loaded: boolean,
  setLoaded: boolean => void,
  handleClickFavIco: (item: DisplayItemInterface) => void,
  handleClickMore: (reviewId: number) => void
}

type Props = ExternalProps & {
  feedbackModalRef: { current: null | any },
  shoesDetailModalRef: { current: null | any },
  footTypeModalRef: { current: null | any }
}

export const enhancePresentation = compose(
  withStateHandlers(
    {
      feedbackModalRef: React.createRef(),
      shoesDetailModalRef: React.createRef(),
      footTypeModalRef: React.createRef()
    },
    {}
  )
)

export default function Presentation({
  is404PageShown,
  item,
  images,
  reviews,
  shoeFeatures,
  loaded,
  reviewDetail,
  setLoaded,
  handleClickFavIco,
  handleClickMore,
  feedbackModalRef,
  shoesDetailModalRef,
  footTypeModalRef
}: Props) {

  // 該当するfootUuidがない
  if (is404PageShown) {
    return <NotFoundPage />
  }

  if (!item) {
    // 仮：データ取得中の場合の表示（取得中はfootの値はnullなので表示できない）
    return (
      <PrivatePageLayout>
        <Section>&nbsp;</Section>
      </PrivatePageLayout>
    )
  }


  return (
    <PrivatePageLayout>
    <PageTitle title={item.product.productName} />
      <Inner>
        <Gallery loaded={loaded}>
          <Loading>
            <SpinnerThumbnailLoading loaded={loaded} size={16} />
          </Loading>
          <ImageGallery
            items={images}
            startIndex={0}
            onImageLoad={_ => setLoaded(true)}
          />
          <StyledCheckButton
            isFavorite={item.isFavorite}
            onClick={() => handleClickFavIco(item)}
          />
        </Gallery>
        <ShoesText>
          <div>
            <Text color={theme.color.gray} size={12} margin={8} textAlign='left' maxWidth='50%'>
              {item.product.brandName}
              <br />
              {`${item.product.productNumber} / ${item.measurement.makerSize}${item.product.unit}  / ${item.mesh.makerColor}`}
            </Text>

            {item.price > 0 && 
            <Price
              price={parseInt(item.price)}
              taxDispSetting={item.taxDispSetting || ''}
            />
            }
            {/*
            <Text size={12}>{item.storeName}</Text>
            */}


          </div>
        </ShoesText>

        <ShoeFeature>
        {shoeFeatures.map ((e, idx ) => (
               <Review key={idx}
                 title={e.title}
                 options={e.options}
                 answer={e.answer}
                 type={e.type}
               />
        ))}
        </ShoeFeature>

        { reviews.map(( review, idx ) => {
          // review.type == 'fitting_log',  user_review
          if ( review.type == 'fitting_log' || review.type == "user_review") return false
          const {content : { shoeReview} } = review
          const tight =shoeReview.find (e => e.questionId == 'tester_review_shoe_8')
          const blister =shoeReview.find (e => e.questionId == 'tester_review_shoe_9')

          const brand =shoeReview.find (e => e.questionId == 'tester_review_shoe_1')
          const size =shoeReview.find (e => e.questionId == 'tester_review_shoe_4')
          const sizeOther =shoeReview.find (e => e.questionId == 'tester_review_shoe_5')
          const memo =shoeReview.find (e => e.questionId == 'tester_review_shoe_7')
          const star =shoeReview.find (e => e.questionId == 'tester_review_shoe_15')

          const testerReviewShoe6 =shoeReview.find (e => e.questionId == 'tester_review_shoe_6')
          const testerReviewShoe11 =shoeReview.find (e => e.questionId == 'tester_review_shoe_11')
          const testerReviewShoe12 =shoeReview.find (e => e.questionId == 'tester_review_shoe_12')
          const testerReviewShoe13 =shoeReview.find (e => e.questionId == 'tester_review_shoe_13')
          const testerReviewShoe14 =shoeReview.find (e => e.questionId == 'tester_review_shoe_14')
          const reviewDisplay =  reviewDetail.includes(review.id)

          let survey = review.reviewer.survey.map(e => { 
            if ( e.questionId != 'fitting_log_foot_7' ) {
              if ( e.answer ) return `${e.title}:${e.answer}`
            }
          })
          const foot =review.reviewer.survey.find (e => e.questionId == 'fitting_log_foot_7')


        return (

        <Description key={idx}>
        <ReviewHeadFoot><p>スタッフ</p> {survey.join('/').replace("//","/")} </ReviewHeadFoot>
        <ReviewComment 
          makerSize={review.item.measurement.makerSize}
          unit={item.product.unit}
          fit={testerReviewShoe6}
          size={size}
          sizeOther={sizeOther}
          memo={memo}
          star={star}
        />
        {!reviewDisplay && (
          <ReviewButtonBox>
            <ReviewButton   onClick={() => handleClickMore(review.id)}>もっとみる</ReviewButton>
          </ReviewButtonBox>
        )}
        {reviewDisplay && (
        <Review
          title={testerReviewShoe11.title}
          options={testerReviewShoe11.options}
          answer={testerReviewShoe11.answer}
          type={testerReviewShoe11.type}
        />
        )}
        {reviewDisplay && (
        <Review
          title={testerReviewShoe12.title}
          options={testerReviewShoe12.options}
          answer={testerReviewShoe12.answer}
          type={testerReviewShoe12.type}
        />
        )}
        {reviewDisplay && (
        <Review
          title={testerReviewShoe13.title}
          options={testerReviewShoe13.options}
          answer={testerReviewShoe13.answer}
          type={testerReviewShoe13.type}
        />
        )}
        {reviewDisplay && (
        <Review
          title={testerReviewShoe14.title}
          options={testerReviewShoe14.options}
          answer={testerReviewShoe14.answer}
          type={testerReviewShoe14.type}
        />
        )}
        {reviewDisplay && (
        <ReviewPart
          tight={tight}
          blister={blister}
        />
        )}

        <ReviewHeadFoot><p>足の特徴</p>{foot.answer.join('/')}</ReviewHeadFoot>

        </Description>
        )
          })
        }

        { reviews.map(( review, idx ) => {
          if ( review.type != "user_review") return false

          const { content } = review
          const name  = review.reviewer.name

          return (
          <Description key="userReview${idx}">
          <ReviewHeadFoot><p>{name}</p></ReviewHeadFoot>
          <ReviewHeadFoot2>{content}</ReviewHeadFoot2>
          </Description>
          )
        })
        }

      </Inner>

        { ( item.ecUrl != null ) && 
          <EcButton
            onClick={() => {
              window.open(item.ecUrl, '_blank');
            }}
            size="large"
            type="success"
            height='50px'
          >
            詳しく見る
          </EcButton>
        }

    </PrivatePageLayout>
  )
}


const Loading = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vw - 80px);
  max-height: 100vh;
`

const Gallery = styled.div`
  position: relative;
  > ${Loading} {
    display: ${props => (props.loaded ? 'none' : 'block')};
  }
  > .image-gallery {
    display: ${props => (props.loaded ? 'block' : 'none')};
  }
`

const ShoesText = styled.div`
  text-align: center;
  position: relative;
  > div {
    display: flex;
  }
`

const StyledCheckButton = styled(CheckButton)`
  position: absolute;
  bottom: 100px;
  right: 20px;
`

const EcButton  = styled(Button)`
position: fixed;
width: 95%;
bottom: 15px;
left: 50%;
transform: translateX(-50%);
  border-radius: 10px;
  border: none;
  color: #fff;
  /*
  background-color: rgba(255, 255, 255, 0.3);
  */
  background-color: rgba( 112, 112, 112,  0.3);
  text-align: center;
  line-height: 1; 
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);  
`

const Description = styled.div`
  border: solid 2px #D9D9D9;
  border-radius: 20px;
  overflow: hidden;
  margin: 10px 0;

`
const ReviewHeadFoot = styled.div`
  background-color: #d9d9d9;
  padding: 5px 10px;
  size: 14px;
  > p {
    font-weight: bold;
  }
`
const ReviewHeadFoot2 = styled.div`
  background-color: #fff;
  padding: 5px 10px;
  size: 14px;
  > p {
    font-weight: bold;
  }
`

const ReviewButtonBox  = styled.div`
  text-align: center;
`
const ReviewButton  = styled(Button)`
  border: 1px solid #707070;
  border-radius: 20px;
  width: 130px;
  text-align: center;
  margin: 10px auto;
`
const ShoeFeature = styled.div`
  border: solid 2px #D9D9D9;
  border-radius: 20px;
  margin: 10px 20px;
`