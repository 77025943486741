// @flow
import React from 'react'
import styled from 'styled-components'

type Props = {
  children: any,
  title?: string,
  isShown: boolean,
  onClose: () => void
}

export default function Presentation({
  children,
  title,
  isShown,
  onClose
}: Props) {
  if (!isShown) return ''
  return (
    <div>
      <Bg>
        <Wrapper>
          <ClickEventReceiver onClick={onClose} />
          <Button onClick={onClose}>
            <span />
            <span />
          </Button>
          <Box>
            {title && <h2>{title}</h2>}
            {children}
          </Box>
        </Wrapper>
      </Bg>
    </div>
  )
}

const Bg = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 480px;
  position: relative;
  @media only screen and (min-width: 768px) {
    width: 480px;
  }
`

const ClickEventReceiver = styled.div`
  /*
  「背景クリックで閉じる」機能のためだけにある要素
  <Box>と兄弟要素にしてあることで
  モーダルコンテンツに対するクリックイベントが伝わってこないようにしている
  */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const Button = styled.button`
  position: absolute;
  left: 20px;
  top: 20px;
  span {
    width: 30px;
    height: 3px;
    background: ${({ theme }) => theme.color.white};
    display: inline-block;
    border-radius: 1.5px;
    position: absolute;
    left: 8px;
    transition: 0.2s;
    &:first-child {
      top: 15px;
      transform: rotate(45deg);
    }
    &:last-child {
      top: 15px;
      transform: rotate(-45deg);
    }
  }
`
const Box = styled.div`
  z-index: 100;
  position: relative;
  width: calc(100% - 40px);
  background: #fff;
  /*margin: 55px auto;*/
  padding: 20px 15px;
  border-radius: 6px;
  max-width: 480px;
  margin: 68px auto 20px auto;
  height: calc(100% - 88px);
  max-height: calc(100vh - 88px);
  overflow-y: auto;
  h2 {
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    margin: 0 0 10px;
  }
`
