// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { appActionCreators } from '@src/redux/modules/app'
import logicHelper from './logicHelper'
import { loginProcessActionCreators } from '@src/redux/modules/specifics/loginProcess'

type ExternalProps = {
  match: any
}


type FormData = {
  email: string,
  newPassword: string,
  newPasswordRepeated: string
}

type Props = ExternalProps &  {
  history: any,
  appActions: any,
  formData: FormData,
  validationErrorInfo: { [errorInfoKey: string]: string },
  setFormData: Function,
  setValidationErrorInfo: Function,
  updateIs404PageShown: boolean => void,
  loginProcessActions: { [string]: Function }
}

const defaultFormData = function(): FormData {
  return {
    email: '',
    newPassword: '',
    newPasswordRepeated: ''
  }
}

export const enhanceConnection = compose(
  connect(
    null,
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      loginProcessActions: bindActionCreators(
        { ...loginProcessActionCreators },
        dispatch)
    })
  ),

  withState('formData', 'setFormData', defaultFormData()),
  withState('validationErrorInfo', 'setValidationErrorInfo', {}),
  withState('is404PageShown', 'updateIs404PageShown', false),

  withHandlers({
    setFormValue: ({ formData, setFormData }: Props) => (
      key: string,
      value: string
    ) => {
      setFormData({
        ...formData,
        [key]: value
      })
    },

    handleClickSubmit: ({
      history,
      appActions,
      formData,
      setValidationErrorInfo,
      match,
      loginProcessActions
    }: Props) => async () => {
      // バリデーションエラー表示の初期化
      setValidationErrorInfo({})

      const isSucceeded = await logicHelper.submitResetPassword(
        formData,
        setValidationErrorInfo,
        appActions,
        match.params.code
      )
      if (!isSucceeded) return


      const loginResult = await loginProcessActions.autoLogin()

      if (loginResult.isSucceeded) {
        history.push('/')
        return
      }
      history.push('/reset_password/complete/done')
    }
  }),

  lifecycle({
    async componentDidMount() {
      console.log("Submit___ ResetPassword")
      const {
        match,
        updateIs404PageShown
      } = this.props
      console.log("match", match)
      if (!match.params || !match.params.code) {
        updateIs404PageShown(true)
        return
      }
    }
  })
)
