// @flow
import { combineReducers } from 'redux'

import filteringCondition from './filteringCondition'
import recommendationCondition from './recommendationCondition'

export default combineReducers({
  filteringCondition,
  recommendationCondition
})
