// @flow
import React from 'react'
import styled from 'styled-components'
import { pure } from 'recompose'
import noAvatarImg from '@src/assets/img/no_avatar.png'

type Props = {
  src: '' | string,
  onFileChange?: (e: any) => void
}

function ProfileImageUploader({ src, onFileChange }: Props) {
  return (
    <Root>
      <figure>
        <img src={src || noAvatarImg} alt="" width={95} />
      </figure>
      <UploaderLabel>
        <HiddenInput
          type="file"
          onChange={onFileChange ? onFileChange : () => {}}
          multiple={false}
          accept="image/png,image/jpeg"
        />
        <Icon>{'📷'}</Icon>
      </UploaderLabel>
    </Root>
  )
}

const Root = styled.div`
  width: 95px;
  height: 95px;
  margin: auto;
  position: relative;

  > figure {
    width: 95px;
    margin: 0 auto 15px;
    img {
      border-radius: 50%;
    }
  }
`
const UploaderLabel = styled.label`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  :active {
    background-color: rgba(255, 255, 255, 0.5);
  }
`
const HiddenInput = styled.input`
  display: none;
`
const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin: -20px auto auto -20px;

  text-align: center;
  line-height: 40px;
`

export default pure(ProfileImageUploader)
