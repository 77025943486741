// @flow
import React from 'react'
import { compose, withState, withHandlers, withStateHandlers } from 'recompose'
import styled from 'styled-components'
import { formatSize } from '@src/helpers/number'
import { formatJisWidth } from '@src/helpers/string'

import UserInfoModel from '@src/models/UserInfoModel'
import UserFootModel from '@src/models/UserFootModel'
//import RecommendationItemModel from '@src/models/RecommendationItemModel'
//import FavoriteItemModel from '@src/models/FavoriteItemModel'

import PrivatePageLayout from '@src/components/templates/PrivatePageLayout'
import Inner from '@src/components/templates/Inner'
import ArrowLink from '@src/components/atoms/ArrowLink'
import Measured from '@src/components/molecules/Measured'
import NoItemsInTab from '@src/components/molecules/NoItemsInTab'
import Button from '@src/components/atoms/Button'
import RecommendationList from '@src/components/organisms/RecommendationList'
import FavoritesList from '@src/components/organisms/FavoritesList'
import ModalShoesDetail from '@src/components/organisms/ModalShoesDetail'
import ModalFeedback from '@src/components/organisms/ModalFeedback'
import ModalLineFriend from '@src/components/organisms/ModalLineFriend'

import type { DisplayItemInterface } from '@src/types'

import bgMyPageImg from '@src/assets/img/bg_mypage.png'
import tabRecommendImg from '@src/assets/img/tab_recommend.png'
import tabFavoriteImg from '@src/assets/img/tab_favorite.png'
import tabRecommendActiveImg from '@src/assets/img/tab_recommend_active.png'
import tabFavoriteActiveImg from '@src/assets/img/tab_favorite_active.png'
import noAvatarImg from '@src/assets/img/no_avatar.png'

type ExternalProps = {
  isPageLoadedFirstTime: boolean,
  userInfo: UserInfoModel,
  userFoot: UserFootModel | null,
  recommendationDisplayItems: Array<DisplayItemInterface>,
  favoriteDisplayItems: Array<DisplayItemInterface>,
  setBackUrl: any,
  handleClickFavIco: (item: DisplayItemInterface) => void,
  reloadFavoriteItems: () => void
}

type Props = ExternalProps & {
  tabIndex: 1 | 2,
  updateTabIndex: number => void,
  selectTab: number => void,
  history : Object,

  feedbackModalRef: { current: null | any },
  recommendationDetailModalRef: { current: null | any },
  favoriteDetailModalRef: { current: null | any },
  friendModalRef: { current: null | any },
}

/*==================================
* enhance
==================================*/

export const enhancePresentation = compose(
  // タブ切り替え操作
  withState('tabIndex', 'updateTabIndex', 1),
  withHandlers({
    selectTab: ({ tabIndex, updateTabIndex, reloadFavoriteItems }: Props) => (
      num: number
    ) => {
      if (num === tabIndex) return
      if (num === 2) {
        // お気に入り一覧タブを開く際に一覧データを際取得しています（ちょっと無駄あり）
        reloadFavoriteItems()
      }
      return updateTabIndex(num)
    }
  }),

  // モーダル（アイテム詳細）
  withStateHandlers(
    {
      feedbackModalRef: React.createRef(),
      recommendationDetailModalRef: React.createRef(),
      favoriteDetailModalRef: React.createRef(),
      friendModalRef: React.createRef()
    },
    {}
  )
)

/*==================================
* View
==================================*/

export default function Presentation({
  isPageLoadedFirstTime,
  userInfo,
  userFoot,
  recommendationDisplayItems,
  favoriteDisplayItems,
  handleClickFavIco,
  tabIndex,
  selectTab,
  setBackUrl,
  feedbackModalRef,
  recommendationDetailModalRef,
  favoriteDetailModalRef,
  friendModalRef,
  history
}: Props) {
  return (
    <PrivatePageLayout>
      <MyPage>
        <Inner>
          <h1>{userInfo.nickname}</h1>
          <figure>
            <img
              src={userInfo.profileImgUrl || noAvatarImg}
              alt=""
              width={96}
            />
          </figure>

          {userFoot && (
            <React.Fragment>
              <p>おすすめサイズ</p>
              <p>
                <strong>
                  {formatSize(userFoot.jisSize)}
                  cm {userFoot.jisWidthJa}{/*({formatJisWidth(userFoot.jisWidth)})*/}
                </strong>
              </p>

              <hr />
              <Measured
                date={userFoot.displayDate}
                storeName={userFoot.store}
                isNew={false}
              />
              <ArrowLink path={'/feet'} text={'足カルテの詳細を見る'} />
            </React.Fragment>
          )}
        </Inner>

        <br />
        <br />

        <div>
          <TabHead>
            <div
              className={tabIndex === 1 ? 'selected' : ''}
              onClick={() => selectTab(1)}
            >
              <img
                src={tabIndex === 1 ? tabRecommendActiveImg : tabRecommendImg}
                alt="おすすめ"
                width={76}
              />
            </div>
            <div
              className={tabIndex === 2 ? 'selected' : ''}
              onClick={() => selectTab(2)}
            >
              <img
                src={tabIndex === 2 ? tabFavoriteActiveImg : tabFavoriteImg}
                alt="お気に入り"
                width={86}
              />
            </div>
          </TabHead>
          <TabContents>
            <Inner>
              {isPageLoadedFirstTime && tabIndex === 1 && (
                <React.Fragment>
                  {recommendationDisplayItems.length === 0 ? (
                    <NoItemsInTab type="recommend">
                      申し訳ありません。
                      <br />
                      おすすめの靴を見つけることが
                      <br />
                      できませんでした。
                    </NoItemsInTab>
                  ) : (
                    <div>
                      <RecommendationList
                        recommendations={recommendationDisplayItems}
                        shouldShowFavoriteIcon={true}
                        onClickShoes={(itemId: number) => {
                          setBackUrl()
                          history.push(`/item/${itemId}`)
                          /*
                          if (!recommendationDetailModalRef.current) return
                          recommendationDetailModalRef.current.showModal(
                            item.itemUuid
                          )
                          */
                        }}
                        onClickFavIco={handleClickFavIco}
                      />
                      <Button type={'success'} href={'/recommendation'}>
                        おすすめをもっと見る
                      </Button>
                    </div>
                  )}
                </React.Fragment>
              )}
              {isPageLoadedFirstTime && tabIndex === 2 && (
                <React.Fragment>
                  {favoriteDisplayItems.length === 0 ? (
                    <NoItemsInTab type="favorite">
                      気になる靴、試着したい靴を
                      <br />
                      お気に入りに登録しよう！
                    </NoItemsInTab>
                  ) : (
                    <FavoritesList
                      favorites={favoriteDisplayItems}
                      onClickShoes={(item: DisplayItemInterface) => {
                        if (!favoriteDetailModalRef.current) return
                        favoriteDetailModalRef.current.showModal(item.itemUuid)
                      }}
                      onClickFavIco={handleClickFavIco}
                      onClickFeedback={(item: DisplayItemInterface) => {
                        if (!feedbackModalRef.current) return
                        feedbackModalRef.current.showModal(item)
                      }}
                    />
                  )}
                </React.Fragment>
              )}
            </Inner>
          </TabContents>
        </div>

        <div>{/* 【保留】フィードバック関連 */}</div>
      </MyPage>
      <ModalShoesDetail
        mode={'recommendation-detail'}
        modalRef={recommendationDetailModalRef}
        items={recommendationDisplayItems}
        onClickFavIco={handleClickFavIco}
      />

      <ModalShoesDetail
        mode={'favorite-detail'}
        modalRef={favoriteDetailModalRef}
        items={favoriteDisplayItems}
        onClickFavIco={handleClickFavIco}
        onClickFeedback={(item: DisplayItemInterface) => {
          if (!feedbackModalRef.current) return
          feedbackModalRef.current.showModal(item)
        }}
      />

      <ModalFeedback modalRef={feedbackModalRef} />
      {/* 
      TODO: 友達チェック -> モーダルは一旦無効にしておく
      <ModalLineFriend modalRef={friendModalRef} friendFlag={userInfo.friendstatus} />
      */}
    </PrivatePageLayout>
  )
}

const MyPage = styled.div`
  text-align: center;
  background: url(${bgMyPageImg}) no-repeat center -100px;
  background-size: 100%;
  padding-top: 30px;
  h1 {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 15px;
  }
  figure {
    margin: 0 0 15px;
    img {
      border-radius: 50%;
    }
  }
  strong {
    font-size: 19px;
  }
  hr {
    width: 160px;
    margin: 10px auto;
  }
`

const TabHead = styled.div`
  > div {
    width: 46%;
    text-align: center;
    display: inline-block;
    background-color: #c9c9c9;
    border: 2px solid #c9c9c9;
    padding: 8px 0;
    border-radius: 10px 10px 0 0;
    margin: 0 3px;
  }
  > div.selected {
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.color.borderGray};
    border-bottom-color: ${({ theme }) => theme.color.white};
  }
`

const TabContents = styled.div`
  border-top: 2px solid ${({ theme }) => theme.color.borderGray};
  padding-top: 25px;
  margin-top: -2px;
`
