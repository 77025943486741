// @flow
import React from 'react'
import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
import { bindActionCreators } from 'redux'
import { appActionCreators } from '@src/redux/modules/app'
import { storeActionCreators } from '@src/redux/modules/store'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import commonHandlers from '@src/commonHandlers'

import StoreModel from '@src/models/StoreModel'

type Props = {
  history: any,
  stores: Array<StoreModel>,

  appActions: any,
  storeActions: any,

  checkBoxes: Array<{ value: string, label: string, defaultChecked: boolean }>,
  initCheckBoxes: () => void,
  formRef: { current: null | HTMLFormElement }
}

export const enhanceConnection = compose(
  connect(
    state => ({
      stores: state.store.stores
    }),
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      storeActions: bindActionCreators({ ...storeActionCreators }, dispatch)
    })
  ),

  withState('formRef', 'setFormRef', React.createRef()),
  withState('checkBoxes', 'setCheckBoxes', []),

  withHandlers({
    handleCancel: ({ history }: Props) => () => {
      history.goBack()
    },

    handleSave: ({
      history,
      stores,
      appActions,
      storeActions,
      formRef
    }: Props) => async () => {
      const favoriteStoreIds = []
      const notFavoriteStoreIds = []
      Array.prototype.forEach.call(
        formRef.current,
        (input: HTMLInputElement, i: number) => {
          if (input.checked) favoriteStoreIds.push(stores[i].storeId)
          else notFavoriteStoreIds.push(stores[i].storeId)
        }
      )

      appActions.showLoading()
      try {
        await storeActions.updateFavoriteStores(favoriteStoreIds, true)
        await storeActions.updateFavoriteStores(notFavoriteStoreIds, false)
        await storeActions.fetch()
      } catch (err) {
        commonHandlers.handleAsyncLogicError(err)
        return
      } finally {
        appActions.hideLoading()
      }

      history.goBack()
    }
  }),

  lifecycle({
    componentDidMount() {
      const { stores, setCheckBoxes } = this.props
      const checkBoxes = stores.map(store => {
        return {
          value: store.storeId.toString(),
          label: store.storeName,
          defaultChecked: store.favoriteFlag
        }
      })
      setCheckBoxes(checkBoxes)
    }
  })
)
