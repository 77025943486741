// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  number: number
}

export default function({ number }: Props) {
  return (
    <Count>
      {number}
      <span>件</span>
    </Count>
  )
}

const Count = styled.p`
  font-size: 14px;
  font-weight: bold;
  span {
    font-size: 11px;
    font-weight: normal;
  }
`
