// @flow
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { compose, withHandlers, withState } from 'recompose'

import DefaultPageLayout from '@src/components/templates/DefaultPageLayout'
import PageTitle from '@src/components/molecules/PageTitle'
import Inner from '@src/components/templates/Inner'

import Input from '@src/components/atoms/forms/Input'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'
import theme from '@src/misc/theme'

import icoFacebookImg from '@src/assets/img/ico_facebook.png'
import icoTwitterImg from '@src/assets/img/ico_twitter.png'
import icoLineImg from '@src/assets/img/ico_line.png'
import icoYahooImg from '@src/assets/img/ico_yahoo.png'
import icoMailImg from '@src/assets/img/ico_mail.png'
import icoLockImg from '@src/assets/img/ico_lock.png'
import icoMailWhiteImg from '@src/assets/img/ico_mail_white.png'

import amplifyHttpClient from '@src/api/httpClients/amplifyHttpClient'

// 外部供給のProps
type ExternalProps = {
  handleLoginAsync: (mailAddr: string, password: string) => Promise<any>,
  handleLineLoginAsync: () => Promise<any>,
  handleLineLoginErrorAsync:(err: Object) => Promise<any>,
  loginErrorInfo: {
    message: string
  }
}

// このファイルで定義しているProps
type Props = ExternalProps & {
  mailAddr: string,
  password: string,
  setMailAddr: string => void,
  setPassword: string => void,
  submit: () => void,
  handleSignIn: (identityProvider: string) => void,
  handleSignOut: () => void,
  
}

/*==================================
* enhance
==================================*/
export const enhancePresentation = compose(
  withState('mailAddr', 'setMailAddr', ''),
  withState('password', 'setPassword', ''),
  withHandlers({
    submit: ({ mailAddr, password, handleLoginAsync }: Props) => () => {
      handleLoginAsync(mailAddr, password)
    },
    handleSignIn: ({ handleLineLoginAsync }: Props) => () => async() => {
      handleLineLoginAsync()
    }
  })
)

/*==================================
* View
==================================*/
export default function Presentation({
  loginErrorInfo,
  mailAddr,
  password,
  setMailAddr,
  setPassword,
  submit,
  handleSignIn,
  handleSignOut
}: Props) {
  return (
    <DefaultPageLayout>
      <Inner>
        <PageTitle title="Flicfitへようこそ！" />
        <PageTitle title="ログイン" />
        <LoginWithOtherServices>
          <p>LINE</p>
          <Button onClick={handleSignIn("LINE")} type={'primary'}>
            <img src={icoLineImg} width={25} />
            LINEでログイン
          </Button>
        <br />
        </LoginWithOtherServices>
        <LoginFormStyle>
          <p>メールアドレスでログイン</p>
          <ErrorMsg><p>{loginErrorInfo.message}</p></ErrorMsg>
          <Input
            type="email"
            placeholder="メールアドレス"
            value={mailAddr}
            onChange={e => {
              setMailAddr(e.target.value)
            }}
          />
          <Input
            type="password"
            placeholder="パスワード"
            value={password}
            onChange={e => {
              setPassword(e.target.value)
            }}
          />
          <Button onClick={submit} type={'primary'}>
            ログイン
          </Button>
          <ForgotPassword>
            <small>
              パスワードを忘れた方は
              <Link to="/reset_password">こちら</Link>
            </small>
          </ForgotPassword>
        <br />
        <hr />
        </LoginFormStyle>
        <PageTitle title="初めて利用のお客様" />
        <Text
          color={theme.color.gray}
          textAlign={'center'}
          size={11}
          marginTop={15}
        >
          当社
          <a
            href={'https://flicfit.zendesk.com/hc/ja/articles/360020710432'}
            target="_blank"
            rel="noopener noreferrer"
          >
            利用規約
          </a>
          を読み、同意の上ご登録をお願いします。
        </Text>

        <LoginWithOtherServices>
            <p>LINE</p>
            <Button onClick={handleSignIn("LINE")} type={'primary'}>
            <img src={icoLineImg} width={25} />
            利用規約に同意してLINEで登録
          </Button>
        </LoginWithOtherServices>
        
        
          <Text
            color={theme.color.gray}
            textAlign={'center'}
            size={9}
            marginTop={15}
          >
            本Webサービスでは、ログイン時の認証画面にて許可を頂いた場合のみ、あなたのLINEアカウントに登録されているメールアドレスを取得します。<br />
            取得したメールアドレスは、フリックフィットの
            <a
              href={'https://flicfit.zendesk.com/hc/ja/articles/360020710432'}
              target="_blank"
              rel="noopener noreferrer"
            >
            利用規約
            </a>記載の目的以外では使用いたしません。<br />また、法令に定められた場合を除き、第三者への提供はいたしません。
          </Text>

        <SiginUp>
          <p>メールアドレスで登録</p>
          <Button href={'/signup'} type={'primary'}>
            <img src={icoMailWhiteImg} width={19} />
            新規会員登録の方はこちら
          </Button>
        </SiginUp>



      </Inner>
    </DefaultPageLayout>
  )
}

const LoginFormStyle = styled.div`
  margin-top: 20px;
  button {
    margin-top: 18px;
  }
  input[type='email'] {
    padding-left: 40px;
    background: url(${icoMailImg}) no-repeat 15px center;
    background-size: 16px 14px;
  }
  input[type='password'] {
    padding-left: 40px;
    background: url(${icoLockImg}) no-repeat 15px center;
    background-size: 16px 21px;
  }
  p {
    font-weight: bold;
    margin: 0 0 18px;
  }
`

const ErrorMsg = styled.div`
  p {
    color: red;
    font-weight: bold;
    margin: 0 0 18px;
  }
`


const ForgotPassword = styled.p`
  margin-top: 18px;
  text-align: center;
  font-size: 11px;
  small {
    color: #777;
  }
`

const LoginWithOtherServices = styled.div`
  button {
    background-color: #00B900;
    color: {theme.color.while};
    border-color:  #00B900;
  }
  p {
    font-weight: bold;
    margin: 0 0 18px;
  }
  ul {
    text-align: center;
  }
  li {
    display: inline-block;
    margin: 0 12px;
  }
`

const SiginUp = styled.div`
  margin: 25px 0;
  button {
    background-color: #00B900;
    color: {theme.color.while};
    border-color:  #00B900;
  }
  hr {
    margin: 25px 0;
  }
  p {
    font-weight: bold;
    margin: 0 0 18px;
  }
`
