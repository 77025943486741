import glResize from './glResize';
import glUpdate from './glUpdate';
import calcDrawingSize from './calcDrawingSize'

export default class object3d extends THREE.Object3D {

  constructor(opt) {

    super();

    this.opt = opt;

    const { dw, dh } = calcDrawingSize();
    this.sw = dw;//window.innerWidth;
    this.sh = dh;//window.innerHeight;

    this._cnt = 0

    this._updateKey = undefined
    this._resizeKey = undefined

  }


  init() {

    this._resizeKey = glResize.add(() => {
      this.resize();
    });

    this._updateKey = glUpdate.add(() => {
      this.update();
    });

  }


  dispose() {

    glUpdate.remove(this._updateKey)
    glResize.remove(this._resizeKey)

    this.opt = null

  }


  update() {

    this._cnt++;

  }


  resize() {

    const { dw, dh } = calcDrawingSize();
    this.sw = dw;//window.innerWidth;
    this.sh = dh;//window.innerHeight;

  }


  _sin2(radian) {

    return (
      Math.sin(radian) +
      Math.sin(2.2 * radian + 5.52) +
      Math.sin(2.9 * radian + 0.93) +
      Math.sin(4.6 * radian + 8.94)
    ) / 4;

  }


}
