// @flow
import React from 'react'
import styled from 'styled-components'

import Text from '@src/components/atoms/Text'

type Props = {
  img?: string,
  imgWidth?: number,
  name: string,
  text: string
}

export default function({ img, imgWidth, name, text }: Props) {
  return (
    <FeetType>
      <Center>
        {img && imgWidth && <img src={img} width={imgWidth} />}
        <Text size={14} margin={8}>
          {name}型
        </Text>
      </Center>
      <Text size={10} margin={15}>
        {text}
      </Text>
    </FeetType>
  )
}

const FeetType = styled.div`
  p {
    white-space: pre-wrap;
  }
  img {
    margin-bottom: 15px;
  }
`
const Center = styled.div`
  text-align: center;
`
