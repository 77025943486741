// @flow
import produce from 'immer'
// import api from '@src/api'
import { createActionThunk } from 'redux-thunk-actions'

export type ToastCategory = 'info' | 'warning' | 'error'
export type ToastData = {
  id: number,
  category: ToastCategory,
  message: string,
  createdAt: number
}

/*==================================
* Actions
==================================*/
const THUNK = {}
const REDUCER = {}

// Public
THUNK.INIT = 'toast/THUNK.INIT'
THUNK.ADD = 'toast/THUNK.ADD'

// Private
REDUCER.UPDATE = 'toast/REDUCER.UPDATE'
REDUCER.ADD = 'toast/REDUCER.ADD'

/*==================================
* Action Creators
==================================*/
const DEFAULT_DISPLAY_TIME = 3000
let timerId = null
let toastId = 0

export const toastActionCreators = {
  init: createActionThunk(THUNK.INIT, ({ dispatch, getState }) => {
    // Clear
    window.clearInterval(timerId)
    dispatch({
      type: REDUCER.UPDATE,
      payload: {
        toasts: []
      }
    })

    // Watch: ３秒で消す
    timerId = window.setInterval(() => {
      const now = Date.now()
      const toasts = getState().toast.toasts
      // 期限が切れているものは消す
      const filtered = toasts.filter(toast => {
        const displayTime = DEFAULT_DISPLAY_TIME
        const isExpired = now >= toast.createdAt + displayTime
        return !isExpired
      })
      if (filtered.length !== toasts.length) {
        dispatch({
          type: REDUCER.UPDATE,
          payload: {
            toasts: filtered
          }
        })
      }
    }, 200)
  }),

  add: createActionThunk(
    THUNK.ADD,
    async (
      category: ToastCategory,
      message: string,
      { dispatch }
    ): Promise<void> => {
      toastId += 1
      const toast: ToastData = {
        id: toastId,
        category,
        message,
        createdAt: Date.now()
      }
      dispatch({
        type: REDUCER.ADD,
        payload: {
          toast
        }
      })
    }
  )
}

/*==================================
Initial State
==================================*/

type State = {
  toasts: Array<ToastData>
}

const initialState = {
  toasts: []
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.UPDATE: {
        draft.toasts = action.payload.toasts
        return
      }
      case REDUCER.ADD: {
        draft.toasts = [...state.toasts, action.payload.toast]
        return
      }
    }
  })
}

/*==================================
* Selector
==================================*/
