// @flow
import React from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'

import PrivatePageLayout from '@src/components/templates/PrivatePageLayout'
import Inner from '@src/components/templates/Inner'
import PageTitle from '@src/components/molecules/PageTitle'
import FormRow from '@src/components/molecules/FormRow'
import Input from '@src/components/atoms/forms/Input'
import Select from '@src/components/atoms/forms/Select'
import Radio from '@src/components/atoms/forms/Radio'
import HalfButtons from '@src/components/molecules/HalfButtons'
import Button from '@src/components/atoms/Button'
import ProfileImageUploader from '@src/components/molecules/ProfileImageUploader'

import { FormConstants } from '@src/constants'
const options = FormConstants.selectOptions
const inputBlockTitles = FormConstants.inputBlockTitles

// 外部供給のProps
type ExternalProps = {
  currentPropertyName: string,
  userProfileImgUrl: '' | string,
  formData: { [parameterKey: string]: string },
  validationErrorInfo: { [errorInfoKey: string]: string },
  setFormValue: (parameterKey: string, value: string) => void,
  handleProfileImageFileChange: (e: any) => void,
  handleClickSave: () => void,
  handleClickCancel: () => void
}

// このファイルで定義しているProps
type Props = ExternalProps & {}

/*==================================
* enhance
==================================*/

export const enhancePresentation = compose()

/*==================================
* View
==================================*/

export default function Presentation({
  currentPropertyName,
  userProfileImgUrl,
  formData,
  validationErrorInfo,
  setFormValue,
  handleProfileImageFileChange,
  handleClickSave,
  handleClickCancel
}: Props) {
  // 検討: パラメータ :property_name はルーティングでバリデーションしての404NotFound表示を検討
  const propertyName = currentPropertyName

  if (!propertyName) return ''

  return (
    <PrivatePageLayout>
      <Inner>
        <PageTitle title="アカウント設定" />
        {renderFormContents({
          propertyName,
          userProfileImgUrl,
          formData,
          setFormValue,
          handleProfileImageFileChange,
          validationErrorInfo
        })}
        <HalfButtons>
          <Button type={'secondary'} onClick={handleClickCancel}>
            キャンセル
          </Button>
          <Button type={'primary'} onClick={handleClickSave}>
            保存する
          </Button>
        </HalfButtons>
      </Inner>
    </PrivatePageLayout>
  )
}

// プロパティに応じて異なる入力フィールドをレンダリングする
const renderFormContents = function({
  propertyName,
  userProfileImgUrl,
  formData,
  setFormValue,
  handleProfileImageFileChange,
  validationErrorInfo
}) {
  if (propertyName === 'nickname')
    return (
      <React.Fragment>
        <ProfileRow>
          <ProfileImageUploader
            src={userProfileImgUrl}
            onFileChange={handleProfileImageFileChange}
          />
        </ProfileRow>
        <FormRow
          label={inputBlockTitles.nickname}
          errorMessage={validationErrorInfo.nickname}
        >
          <Input
            type="text"
            placeholder="20字以内で入力"
            value={formData.nickname}
            onChange={e => {
              setFormValue('nickname', e.target.value)
            }}
          />
        </FormRow>
      </React.Fragment>
    )
  else if (propertyName === 'password')
    return (
      <React.Fragment>
        <FormRow
          label={'現在のパスワード'}
          errorMessage={validationErrorInfo.oldPassword}
        >
          <Input
            type="password"
            placeholder="半角英数字8文字以上で入力"
            value={formData.oldPassword}
            onChange={e => {
              setFormValue('oldPassword', e.target.value)
            }}
          />
        </FormRow>

        <FormRow
          label={'新しいパスワード'}
          errorMessage={validationErrorInfo.newPassword}
        >
          <Input
            type="password"
            placeholder="半角英数字8文字以上で入力"
            value={formData.newPassword}
            onChange={e => {
              setFormValue('newPassword', e.target.value)
            }}
          />
          <Input
            type="password"
            placeholder="パスワード確認"
            value={formData.newPasswordRepeated}
            onChange={e => {
              setFormValue('newPasswordRepeated', e.target.value)
            }}
          />
        </FormRow>
      </React.Fragment>
    )
  else if (propertyName === 'birthDate')
    return (
      <FormRow
        label={inputBlockTitles.birthDate}
        errorMessage={validationErrorInfo.birthDate}
      >
        <Row>
          <Select
            options={options.birthdayYear}
            defaultOption={'年'}
            value={formData.birthdayYear}
            onChange={e => {
              setFormValue('birthdayYear', e.target.value)
            }}
          />
          <Select
            options={options.birthdayMonth}
            defaultOption={'月'}
            value={formData.birthdayMonth}
            onChange={e => {
              setFormValue('birthdayMonth', e.target.value)
            }}
          />
          <Select
            options={options.birthdayDay}
            defaultOption={'日'}
            value={formData.birthdayDay}
            onChange={e => {
              setFormValue('birthdayDay', e.target.value)
            }}
          />
        </Row>
      </FormRow>
    )
  else if (propertyName === 'gender')
    return (
      <FormRow
        label={inputBlockTitles.gender}
        errorMessage={validationErrorInfo.gender}
      >
        <Radio
          name={'gender'}
          value={'男性'}
          checked={formData.gender === '男性'}
          label={'男性'}
          onChange={e => {
            setFormValue('gender', e.target.value)
          }}
        />
        <Radio
          name={'gender'}
          value={'女性'}
          checked={formData.gender === '女性'}
          label={'女性'}
          onChange={e => {
            setFormValue('gender', e.target.value)
          }}
        />
      </FormRow>
    )
  else if (propertyName === 'address')
    return (
      <FormRow
        label={inputBlockTitles.address}
        errorMessage={validationErrorInfo.address}
      >
        <Row>
          <Select
            options={options.address}
            defaultOption={'都道府県を選択'}
            value={formData.address}
            onChange={e => {
              setFormValue('address', e.target.value)
            }}
          />
        </Row>
      </FormRow>
    )
  else if (propertyName === 'occupation')
    return (
      <FormRow
        label={inputBlockTitles.occupation}
        errorMessage={validationErrorInfo.occupation}
      >
        <Row>
          <Select
            options={options.occupation}
            defaultOption={'職業を選択'}
            value={formData.occupation}
            onChange={e => {
              setFormValue('occupation', e.target.value)
            }}
          />
        </Row>
      </FormRow>
    )
  else return 'program error'
}

const Row = styled.div`
  display: flex;
  select {
    margin-right: 5px;
  }
  select:nth-child(1) {
    flex-basis: auto;
    flex-grow: 1;
  }
  select:nth-child(2) {
    flex-basis: 6em;
  }
  select:nth-child(3) {
    flex-basis: 6em;
  }
  select:last-child {
    margin-right: 0;
  }
`

const ProfileRow = styled.div`
  padding: 15px 0 25px;
`
