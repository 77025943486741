// @flow
import api from '@src/api'
import { formatBirthdate } from '@src/helpers/string'
import FormValidation from '@src/helpers/FormValidation'
import commonHandlers from '@src/commonHandlers'

type Hash = { [key: string]: string }

// アカウント編集のロジック補助
const logicHelper = {
  //

  // 一部（パスワード、プロフィール画像、お気に入り店舗）を除くアカウント情報の保存
  saveAccountInfo: async function(
    data: Hash,
    setValidationErrorInfo: Hash => void,
    appActions: any,
    userActions: any
  ): Promise<boolean> {
    // クライアントサイドバリデーション
    const validation = new FormValidation(data)
    validation
      .setRequirements({
        nickname: true
      })
      .setDateRequirements({
        year: String(data.birthdayYear),
        month: String(data.birthdayMonth),
        day: String(data.birthdayDay)
      }
      )
      .setMaxLengthLimits({
        nickname: { value: 20 }
      })
      .checkRequirements()
      .checkDateRequirements()
      .checkMaxLengthLimits()


      console.log ("Errro ==> ", validation.hasValidationError)

    if (validation.hasValidationError) {
      setValidationErrorInfo(validation.validationErrorInfo)
      return false
    }

    // リクエスト
    const params = {
      nickname: data.nickname,
      birthDate: formatBirthdate(
        String(data.birthdayYear),
        String(data.birthdayMonth),
        String(data.birthdayDay)
      ),
      gender: data.gender,
      address: data.address,
      occupation: data.occupation
    }

    appActions.showLoading()
    try {
      await userActions.updateUser(params)
    } catch (err) {
      if (
        err.responseData &&
        err.responseData.statusCode === 400 &&
        err.responseData.message &&
        typeof err.responseData.message === 'object'
      ) {
        const obj = err.responseData.message
        // レスポンスデータにバリデーションエラーメッセージが含まれるケース
        // responseData.messageが配列を値に持つオブジェクトになっているのでパースして解析する
        // キーがプロパティ名になっている様子
        const errorInfo = {}
        Object.keys(obj).forEach(propertyName => {
          const msg: Array<string> | string = obj[propertyName]
          errorInfo[propertyName] = Array.isArray(msg) ? msg[0] : msg
        })
        setValidationErrorInfo(errorInfo)
      } else {
        commonHandlers.handleAsyncLogicError(err)
      }
      return false
    } finally {
      appActions.hideLoading()
    }

    //
    return true
  },

  // パスワードの保存
  savePassword: async function(
    email: string,
    data: Hash,
    setValidationErrorInfo: Hash => void,
    appActions: any
  ): Promise<boolean> {
    // (1) クライアントサイドバリデーション
    const validation = new FormValidation(data)
    validation
      .setRequirements({
        oldPassword: true,
        newPassword: { errorInfoKey: 'newPassword' },
        newPasswordRepeated: { errorInfoKey: 'newPassword' }
      })
      .checkRequirements()
      .checkEquality(
        'newPassword',
        'newPasswordRepeated',
        'パスワードが一致しません'
      )

    if (validation.hasValidationError) {
      setValidationErrorInfo(validation.validationErrorInfo)
      return false
    }

    // (2) リクエスト
    appActions.showLoading()
    const result = await api.changePassword(
      email,
      data.oldPassword,
      data.newPassword
    )
    appActions.hideLoading()

    if (result.isSucceeded) return true

    // (3) Amplifyエラーハンドリング
    commonHandlers.handleAmplifyErrorWithAnalyzeErrorCode(
      result.error,
      (code: string, PRESET_MESSAGES: Object) => {
        if (
          code === 'InvalidParameterException' ||
          code === 'InvalidPasswordException'
        ) {
          // 新しいパスワードのバリデーションエラー
          setValidationErrorInfo({
            newPassword: PRESET_MESSAGES.INVALID_PASSWORD
          })
        } else if (code === 'NotAuthorizedException') {
          // 旧パスワード（またはusername）の間違いによる認証エラー
          setValidationErrorInfo({
            oldPassword: PRESET_MESSAGES.INPUT_ERROR
          })
        } else {
          return true
        }
      }
    )
    //
    return false
  }
}

export default logicHelper
