import vert from './shader/shoes.vert';
import vert2 from './shader/shoes2.vert';
import frag from './shader/shoes.frag';

import object3d from './object3d';
//import image from './image';
//import glTextures from './glTextures';
import glUtils from './glUtils';
import glConf from './glConf';
import glParam from './glParam';
import glType from './glType';


export default class shoes extends object3d {

  constructor(opt) {

    super(opt)

    this._isLoaded = false
    this._mesh = undefined

  }


  init() {

    super.init()

    this.visible = false
    this.resize()

  }


  dispose() {

    super.dispose()

    if(this._mesh != null) {
      TweenMax.killTweensOf(this._mesh.scale)
      TweenMax.killTweensOf(this._mesh.material.uniforms.alphaOffset)
      TweenMax.killTweensOf(this._mesh.rotation)

      this.remove(this._mesh)
      this._mesh.geometry.dispose()
      this._mesh.material.dispose()
      this._mesh = null
    }

    this._isLoaded = false

  }


  load(opt) {

    // 前のは破棄
    if(this._mesh != null) {
      TweenMax.killTweensOf(this._mesh.scale)
      TweenMax.killTweensOf(this._mesh.material.uniforms.alphaOffset)
      TweenMax.killTweensOf(this._mesh.rotation)

      this.remove(this._mesh)
      this._mesh.geometry.dispose()
      this._mesh.material.dispose()
      this._mesh = null
    }

    this._isLoaded = false

    let loader;
    if ( opt.data.match(/\.pcd/) != null ) {
      loader = new THREE.PCDLoader()
      loader.load(opt.data, (data) => {
        if(this.opt == null) {
          return
        }
        this._makeMesh(data, 'pcd')
        this._isLoaded = true
        this.visible = true
        this._show()
        if(opt.onComplete != null) {
          opt.onComplete()
        }

        data.geometry.dispose()
        data.material.dispose()
      })
    } else {
      loader = new THREE.PLYLoader()
      loader.load(opt.data, (data) => {
        if(this.opt == null) {
          return
        }
        this._makeMesh(data, 'ply')
        this._isLoaded = true
        this.visible = true
        this._show()
        if(opt.onComplete != null) {
          opt.onComplete()
        }

        data.dispose()
        //data.material.dispose()
      })
    }

    this._isLoaded = false

  }


  _show() {

    const duration = 3.5;
    const ease = Power2.easeOut;
    const delay = 0;

    const s = 1.2;
    this._mesh.scale.set(s, s, s);
    TweenMax.to(this._mesh.scale, duration, {
      x:1,
      y:1,
      z:1,
      ease:ease,
      delay:delay
    });

    this._mesh.material.uniforms.alphaOffset.value = 0;
    TweenMax.to(this._mesh.material.uniforms.alphaOffset, duration, {
      value:1,
      ease:ease,
      delay:delay
    });

    this._mesh.rotation.x = glUtils.radian(20);
    this._mesh.rotation.y = glUtils.radian(-135 + 180);
    TweenMax.to(this._mesh.rotation, duration, {
      // x:glUtils.radian(20),
      y:glUtils.radian(-135),
      ease:ease,
      delay:delay
    });

  }


  _makeMesh(data, type ) {

    const geometry = new THREE.InstancedBufferGeometry();
    geometry.copy(new THREE.CircleBufferGeometry(1, 16));

    let arr, num;
    if(type == 'pcd') {
      arr = data.geometry.attributes.position.array
      num = data.geometry.attributes.position.count
    } else {
      arr = data.attributes.position.array
      num = data.attributes.position.count
    }

    const translate = new Float32Array(num * 3);
    const normal = new Float32Array(num * 3);
    const delay = new Float32Array(num * 3);

    const t = new THREE.Vector3(0, 0, 0);

    for(let i = 0; i < num; i++) {

      const x = Number(arr[i*3+0]);
      const y = Number(arr[i*3+1]);
      const z = Number(arr[i*3+2]);

      const n = this._getNormal(new THREE.Vector3(x, y, z), t);

      translate[i*3+0] = x;
      translate[i*3+1] = y;
      translate[i*3+2] = z;

      normal[i*3+0] = n.x;
      normal[i*3+1] = n.y;
      normal[i*3+2] = n.z;

      // delay[i*3+0] = glUtils.random(-2, -1);
      delay[i*3+0] = Math.abs(x) * 0.5;
      delay[i*3+1] = Math.abs(y) * 0.5;
      delay[i*3+2] = Math.abs(z) * 0.5;

    }

    geometry.addAttribute('translate', new THREE.InstancedBufferAttribute(translate, 3, false, 1));
    geometry.addAttribute('normal', new THREE.InstancedBufferAttribute(normal, 3, false, 1));
    geometry.addAttribute('delay', new THREE.InstancedBufferAttribute(delay, 3, false, 1));
    geometry.computeBoundingSphere();

    const center = geometry.boundingSphere.center;

    const material = new THREE.RawShaderMaterial({
      uniforms:{
        time:{value:0},
        alpha:{value:2},
        alphaOffset:{value:1},
        size:{value:1},
        color:{value:new THREE.Color(0x21bafc)},
        lightDirection:{value:new THREE.Vector3(0,0,0)},
        resolution:{value:new THREE.Vector2(this.sw, this.sh)},
        meshSize:{value:new THREE.Vector3(Math.abs(center.x * 2), Math.abs(center.y * 2), Math.abs(center.z * 2))},
      },
      vertexShader:vert,
      fragmentShader:frag,
      side:THREE.DoubleSide,
      transparent:true,
      depthTest:true
    })

    const mesh = new THREE.Mesh(geometry, material);

    this.add(mesh);




    // mesh.position.y -= center.y;

    mesh.rotation.x = glUtils.radian(20);
    mesh.rotation.y = glUtils.radian(-135);


    this._mesh = mesh;


  }


  _getNormal(currentPoint, nextPoint) {

    const vAB = nextPoint.clone().sub(currentPoint).normalize();
    const vAZ = new THREE.Vector3(0, 0, 1);

    return vAB.cross(vAZ);

  }


  update() {

    super.update();

    if(!this._isLoaded || !glParam.isRender[glType.SCENE.SHOES] || !this.visible) {
      return;
    }

    if(this._mesh != null) {
      const s = this.sh * 0.003;
      this.scale.set(s, s, s);

      this.position.x = this.sw * 0;//-0.05;
      this.position.y = this.sw * 0;

      const uni = this._mesh.material.uniforms;
      uni.lightDirection.value.x = glParam.shoes.lightX.value * 0.01;
      uni.lightDirection.value.y = glParam.shoes.lightY.value * 0.01;
      uni.lightDirection.value.z = glParam.shoes.lightZ.value * 0.01;

      uni.time.value += 0.02;

      uni.alpha.value = glParam.shoes.alpha.value * 0.01;
      uni.size.value = glParam.shoes.size.value * 0.01;
      uni.resolution.value.set(this.sw, this.sh);
    }

  }


  resize() {

    super.resize();

  }


}
