// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
  withProps
} from 'recompose'
import { appActionCreators } from '@src/redux/modules/app'
import { feetActionCreators } from '@src/redux/modules/feet'
import { favoriteActionCreators } from '@src/redux/modules/favorite'
import commonHandlers from '@src/commonHandlers'
import UserFootModel from '@src/models/UserFootModel'
import MeasureHistoryItemModel from '@src/redux/modules/feet'
import FootModel from '@src/models/FootModel'
import FavoriteItemModel from '@src/models/FavoriteItemModel'

//
// 試着リストの最新仕様:
//   試着リストのアイテムは初期状態がお気に入りフラグオンでお気に入り一覧にも入っていて、
//   フラグオフにするとお気に入りリストからも試着リストからも消える。
//   （オフ -> オン への切り替えパターンは無い）
//

type ExternalProps = {
  match: any,
  useLatestFoot?: boolean
}

type Props = ExternalProps & {
  currentPath: ?string,
  setCurrentPath: (?string) => void,
  is404PageShown: boolean,
  updateIs404PageShown: boolean => void,
  loadData: string => Promise<void>,
  getFootUuid: () => Promise<string>,

  userFoot: UserFootModel | null,
  measureHistoryItems: Array<MeasureHistoryItemModel>,
  foot: ?FootModel,
  feet: any,
  favorite: any,
  appActions: { [string]: Function },
  feetActions: { [string]: Function },
  favoriteActions: { [string]: Function }
}

export const enhanceConnection = compose(
  connect(
    state => ({
      userFoot: state.user.data.foot,
      measureHistoryItems: state.feet.measureHistoryItems,
      foot: state.feet.foot,
      feet: state.feet,
      favorite: state.favorite
    }),
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      feetActions: bindActionCreators({ ...feetActionCreators }, dispatch),
      favoriteActions: bindActionCreators(
        { ...favoriteActionCreators },
        dispatch
      )
    })
  ),

  withState('currentPath', 'setCurrentPath', null),
  withState('is404PageShown', 'updateIs404PageShown', false),

  withProps(({ feet, favorite, appActions }: Props) => {
    // 試着アイテムリストを表示用リストに
    const items: Array<FavoriteItemModel> = feet.fitItems.map(item => {
      // お気に入りフラグを追加
      const isFavorite = favorite.ids.includes(item.itemUuid)
      return new FavoriteItemModel(item, isFavorite)
    })

    // 仕様: お気に入りフラグONのアイテムのみ表示（お気に入りオフにすると試着リストからも削除される仕様のため）
    const displayItems = items.filter(item => item.isFavorite)

    return {
      fitItems: displayItems, setBackUrl : appActions.setBackUrl
    }
    // 注: お気に入りボタンのオン・オフでグレーと黄色が切り替わるという動作仕様を聞いたため
    //     favoriteストアのidsと連携するかたちになっているが、そのあと
    //     お気に入りボタンのオフで試着リストからも削除されるという動作仕様となったので
    //     本来ならもうfavoriteストアとは関係を絶って、
    //     お気に入りボタンクリック時の処理でもfititemsから直接削除してしまった方がよさそう
  }),

  withHandlers({
    loadData: ({
      appActions,
      feetActions,
      updateIs404PageShown
    }: Props) => async footUuid => {
      if (!footUuid) {
        return updateIs404PageShown(true)
      }
      appActions.showLoading()
      try {
        await feetActions.clear()
        await feetActions.fetchMeasureHistory()
        await feetActions.fetch(footUuid)
      } catch (err) {
        if (err.responseData && err.responseData.statusCode === 404) {
          return updateIs404PageShown(true)
        } else {
          commonHandlers.handleAsyncLogicError(err)
          return updateIs404PageShown(true)
        }
      } finally {
        appActions.hideLoading()
      }
    },

    getFootUuid: ({ useLatestFoot, match, userFoot }: Props) => async () => {
      let footUuid: string | null = null

      if (useLatestFoot) {
        // 最新の足カルテページ（ /feet ）の場合
        // -> userFootから取得。但し足未計測の場合はuserFootはnullなのでfootUuidは無い。
        footUuid = userFoot ? userFoot.footUuid : null
      } else {
        // 過去の足カルテページ（ /feet/:footUuid ）の場合
        // -> pathパラメータからfoot_uuidを取得
        if (typeof match.params.footUuid === 'string') {
          footUuid = match.params.footUuid
        } else {
          throw new Error('uuid指定がない')
        }
      }

      return footUuid
    },

    handleClickFavIco: ({ favoriteActions }: Props) => async (
      item: FavoriteItemModel
    ) => {
      const { itemUuid, isFavorite } = item
      if (isFavorite) {
        // お気に入り削除
        try {
          await favoriteActions.remove(itemUuid)
        } catch (err) {
          commonHandlers.handleAsyncLogicError(err)
        }
      }
    }
  }),

  lifecycle({
    async componentDidMount() {
      const {
        match,
        setCurrentPath,
        loadData,
        getFootUuid,
        favoriteActions
      } = this.props
      setCurrentPath(match.url)

      const footUuid: string | null = await getFootUuid()
      await loadData(footUuid)

      try {
        await favoriteActions.fetch()
      } catch (err) {
        commonHandlers.handleAsyncLogicError(err)
      }
    },

    async componentDidUpdate() {
      const {
        match,
        setCurrentPath,
        currentPath,
        loadData,
        getFootUuid
      } = this.props

      if (typeof match.url === 'string' && match.url !== currentPath) {
        // 現在のURLが変わった場合は表示データを再取得する
        setCurrentPath(match.url)

        const footUuid: string | null = await getFootUuid()
        await loadData(footUuid)
      }
    }
  })
)
