import axios from 'axios'
import convertKeys from 'convert-keys'
import constants from '@src/constants'

const { flicfitApiBase } = process.env.CONFIG

/*==================================
Flicfit
==================================*/
const flicfitHttpClient = axios.create({
  baseURL: flicfitApiBase,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json'
  }
})

// リクエストにおける処理を共通化
flicfitHttpClient.interceptors.request.use(config => {
  if (!config.shouldNotConvertKey) {
    config.data = convertKeys.toSnake(config.data)
  }
  return config
})

// レスポンスにおける処理を共通化
flicfitHttpClient.interceptors.response.use(
  //
  // responseあり
  //
  response => {
    // レスポンスJSONのデータをキャメルケースに変換
    let camelized = null
    try {
      camelized = convertKeys.toCamel(response.data)
    } catch (error) {
      {
        const error = new Error('通信エラー（予期せぬデータ）')
        throw error
      }
    }
    if (!camelized) {
      const error = new Error(
        '通信エラー（レスポンスjsonにdataプロパティなし）'
      )
      throw error
    }

    //
    // 正常なレスポンスデータの取得には成功しているが目的の処理は失敗しているケース
    //
    if (camelized.statusCode !== 200) {
      // 通信は成功し、レスポンスデータを受診しているが、処理はなんらかの理由で失敗している
      // responseData.statusCodeで失敗理由などを判定できる場合がある
      // responseData.messageにサーバからのエラーメッセージがstringやobjectで入っている場合もある
      const exception = new Error(
        '正常に通信完了したがリクエストした処理が失敗'
      )
      exception.name = constants.HTTP_FLICFIT_FAILURE_RESULT_GOT
      exception.responseStatus = response.status
      exception.responseData = camelized
      throw exception
    }

    //
    // リクエストした処理が成功
    //
    return {
      ...response,
      data: camelized
    }
  },

  //
  // responseなし
  //
  error => {
    //
    // ネットワークエラー
    //
    if (!error.response || typeof error.response.status !== 'number') {
      const exception = new Error('通信エラー（接続失敗）')
      exception.name = constants.HTTP_FLICFIT_CONNECTION_ERROR
      throw exception
    }

    //
    // その他接続エラー
    //
    {
      const exception = new Error('通信エラー（500等）')
      exception.name = constants.HTTP_FLICFIT_RESPONSE_ERROR
      exception.responseStatus = error.response.status
      throw exception
    }
  }
)

export default flicfitHttpClient
