import axios from 'axios'
import convertKeys from 'convert-keys'
import constants from '@src/constants'

const CONFIG: any = process.env.CONFIG
const lineApiBase = "https://api.line.me"




/*==================================
LINE
==================================*/
const lineHttpClient = axios.create({
  baseURL: lineApiBase,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json'
  }
})

// リクエストにおける処理を共通化
lineHttpClient.interceptors.request.use(config => {
  if (!config.shouldNotConvertKey) {
    config.data = convertKeys.toSnake(config.data)
  }
  return config
})

// レスポンスにおける処理を共通化
lineHttpClient.interceptors.response.use(
  //
  // responseあり
  //
  response => {
    // レスポンスJSONのデータをキャメルケースに変換
    let camelized = null
    try {
      camelized = convertKeys.toCamel(response.data)
    } catch (error) {
      {
        const error = new Error('通信エラー（予期せぬデータ camelized）')
        throw error
      }
    }
    if (!camelized) {
      const error = new Error(
        '通信エラー（レスポンスjsonにdataプロパティなし）'
      )
      throw error
    }

    //
    // リクエストした処理が成功
    //
    return {
      ...response,
      data: camelized
    }
  },

  //
  // responseなし
  //
  error => {
    //
    // ネットワークエラー
    //
    if (!error.response || typeof error.response.status !== 'number') {
      const exception = new Error('通信エラー（接続失敗）')
      exception.name = constants.HTTP_FLICFIT_CONNECTION_ERROR
      throw exception
    }

    //
    // その他接続エラー
    //
    {
      const exception = new Error('通信エラー（500等）')
      exception.name = constants.HTTP_FLICFIT_RESPONSE_ERROR
      exception.responseStatus = error.response.status
      throw exception
    }
  }
)

export default lineHttpClient