// @flow
import constants from '@src/constants'

type DATA_LAYER_INTERFACE = {
  event: 'loadready',
  pageID: string,
  uuid: void | string,
  footUuid: void | string,
  itemUuid: void | number
}

type FEEDBACK_DATA = {
  feedback_L1?: string,
  feedback_L2?: string,
  feedback_L3?: string,
  feedback_L4?: string,
  feedback_L5?: string,
  feedback_L6?: string,
  feedback_L7?: string,
  feedback_R1?: string,
  feedback_R2?: string,
  feedback_R3?: string,
  feedback_R4?: string,
  feedback_R5?: string,
  feedback_R6?: string,
  feedback_R7?: string
}

export default {
  store: null,
  userActions: null,

  // このモジュールはApp起動時にstoreと接続させる
  registerStore(store: any) {
    this.store = store
  },

  _collectData() {
    const state = this.store.getState()

    // 未ログイン時はundefined
    const uuid: string | void = state.user.auth.uuid || undefined
    // 未ログイン時はundefined、未計測ユーザもundefined
    const footUuid: string | void = state.user.data.foot
      ? state.user.data.foot.footUuid
      : undefined

    return {
      uuid,
      footUuid
    }
  },

  // 画面表示時（URL切り替わり時）などのタイミングでのアクセス解析用データ送信用メソッド
  sendAnalyticsData(pageID: string, itemUuid: void | number): void {
    const { uuid, footUuid } = this._collectData()

    const data: DATA_LAYER_INTERFACE = {
      // event: 固定値
      event: 'loadready',
      // pageID: 引数で受け取り - Routeチェンジの場合はPath、モーダル表示時は割り当てられた固定値をもとにした擬似的なPath
      pageID: pageID,
      // uuid: 自動取得 - ログイン状態に応じて
      uuid: uuid,
      // footUuid: 自動取得 - ログインユーザ情報に応じて
      footUuid: footUuid,
      // itemUuid: 商品詳細・フィードバックモーダル表示時のみContainer側から受け取る
      itemUuid: itemUuid
    }

    // $FlowFixMe
    dataLayer.push(data)

    // console.log('アクセス解析送信')
    // console.log(JSON.stringify(data, null, '  '))
  },

  // フィードバックのデータ送信用メソッド
  sendFeedbackToGA(itemUuid: number, feedbackData: FEEDBACK_DATA): void {
    const { uuid, footUuid } = this._collectData()

    const pageID = constants.PSEUDO_PAGE_PATH_EXEC_FEEDBACK.replace(
      ':itemUuid',
      String(itemUuid)
    )

    const data: DATA_LAYER_INTERFACE & FEEDBACK_DATA = {
      // フィードバックのデータ
      ...feedbackData,
      // event: 固定値
      event: 'loadready',
      // pageID: フィードバック送信専用の擬似Path
      pageID: pageID,
      // uuid: 自動取得 - ログイン状態に応じて
      uuid: uuid,
      // footUuid: 自動取得 - ログインユーザ情報に応じて
      footUuid: footUuid,
      // itemUuid: Container側から受け取る
      itemUuid: itemUuid
    }

    // $FlowFixMe
    dataLayer.push(data)

    // console.log('Feedback送信')
    // console.log(JSON.stringify(data, null, '  '))
  }
}
