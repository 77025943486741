// @flow
import React from 'react'
import styled from 'styled-components'

type Props = {
  children: any
}

export default function({ children }: Props) {
  return <HalfButtons>{children}</HalfButtons>
}

const HalfButtons = styled.div`
  display: flex;
  justify-content: space-between;
  button,
  a {
    max-width: 48%;
  }
`
