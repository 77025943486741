// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  label: string
  // canDelete: boolean
}

export default function({ label, ...rest }: Props) {
  return <Tag {...rest}>{label}</Tag>
}

const Tag = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.color.black};
  border: 1px solid ${({ theme }) => theme.color.lightGray};
  font-weight: normal;
  line-height: 1;
  padding: 4px 10px;
  display: inline-block;
  border-radius: 10px;
  margin-right: 5px;
`
