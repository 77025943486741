// @flow
import React from 'react'
import styled from 'styled-components'
import theme from '@src/misc/theme'
import { formatJisWidth } from '@src/helpers/string'
import { formatSize } from '@src/helpers/number'
import Text from '@src/components/atoms/Text'

type Props = {
  recommendedLength: number,
  recommendedWidth: string,
  jisWidthJa: string
}

export default function({
  recommendedLength,
  recommendedWidth,
  jisWidthJa
}: Props) {
  return (
    <FeetRecommendSize>
      <Text size={10}>
        {formatSize(recommendedLength)}
        cm {jisWidthJa}{/* ({formatJisWidth(recommendedWidth)}) */}
      </Text>
      <Text color={theme.color.gray} size={9}>
        （flicfit独自基準での目安サイズです）
      </Text>
    </FeetRecommendSize>
  )
}

const FeetRecommendSize = styled.div`
  text-align: center;
`
