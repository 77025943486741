// @flow
import * as React from 'react'
import styled from 'styled-components'
import theme from '@src/misc/theme'

import Text from '@src/components/atoms/Text'

type Props = {
  brandName: string,
  productName: string,
  productNumber: string,
  makerColor: string,
  makerMaterial: string,
  makerSize: string,
  unit: string,
  makerWidth: string,

  textStyleAttributes?: { [attributeKey: string]: any }
}

export default function({
  brandName,
  productName,
  productNumber,
  makerColor,
  makerMaterial,
  makerSize,
  unit,
  makerWidth,
  textStyleAttributes
}: Props) {
  return (
    <ShoesMeta>
      <Text
        color={theme.color.gray}
        size={10}
        margin={8}
        {...textStyleAttributes}
      >
        {brandName}
        <br />
        {`${productNumber} / ${makerSize}${unit} / ${makerWidth}`}
        <br />
        {`${productName}`}
        <br />
        {`${makerColor} / ${makerMaterial}`}
      </Text>
    </ShoesMeta>
  )
}

const ShoesMeta = styled.div``
