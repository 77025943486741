// @flow
import React from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'

import UserInfoModel from '@src/models/UserInfoModel'
import StoreModel from '@src/models/StoreModel'

import PrivatePageLayout from '@src/components/templates/PrivatePageLayout'
import Inner from '@src/components/templates/Inner'
import PageTitle from '@src/components/molecules/PageTitle'
import AccountProperty from '@src/components/molecules/AccountProperty'
import Text from '@src/components/atoms/Text'
import EditLink from '@src/components/atoms/EditLink'
import noAvatarImg from '@src/assets/img/no_avatar.png'

// 外部供給のProps
type ExternalProps = {
  stores: Array<StoreModel>,
  userInfo: UserInfoModel,
  authType: ?string
}

// このファイルで定義しているProps
type Props = ExternalProps & {}

/*==================================
* enhance
==================================*/

export const enhancePresentation = compose()

/*==================================
* View
==================================*/

export default function Presentation({ authType, userInfo, stores }: Props) {
  let [year, month, day] = ['', '', '']
  if (userInfo.birthDate != null  && userInfo.birthDate != 'None') {
    [year, month, day] = userInfo.birthDate.split('-')
  }

  const favoriteStores = stores
    .filter(store => store.favoriteFlag)
    .map(store => store.storeName)
    
  const accountProperties = [
    { key: 'email', value: userInfo.email, disableEdit: true },
    { key: 'password', value: '●●●●●●●●'},
    { key: 'birthDate', value: `${year}年 ${month}月 ${day}日` },
    { key: 'gender', value: (userInfo.gender == null) ? '': userInfo.gender  },
    { key: 'address', value: ( userInfo.address == null ) ? '' : userInfo.address },
    { key: 'occupation', value: ( userInfo.occupation == null)  ? '' : userInfo.occupation },
    { key: 'stores', value: favoriteStores.join('\n') }
  ]
  // openID認証の時はパスワード表記は外す
  if(authType == 'LINE') {
    accountProperties.splice( 1, 1 );
  }

  return (
    <PrivatePageLayout>
      <Inner>
        <PageTitle title="アカウント設定" />
        <FigureAndName>
          <figure>
            <img
              src={userInfo.profileImgUrl || noAvatarImg}
              alt=""
              width={95}
            />
          </figure>
          <Name>
            <Text size={14}>
              <strong>{userInfo.nickname}</strong>
            </Text>
            <NameEditLink propertyName={'nickname'} />
          </Name>
        </FigureAndName>
        {accountProperties.map(accountProperty => (
          <AccountProperty
            key={accountProperty.key}
            propertyName={accountProperty.key}
            value={accountProperty.value}
            disableEdit={accountProperty.disableEdit || false}
          />
        ))}

        <Withdraw>
          <Text size={11}>退会</Text>
          <WithdrawEditLink propertyName={'withdraw'} label={'退会'} />
        </Withdraw>
      </Inner>
    </PrivatePageLayout>
  )
}

const FigureAndName = styled.div`
  padding: 15px 0 25px;
  figure {
    width: 95px;
    margin: 0 auto 15px;
    img {
      border-radius: 50%;
    }
  }
`
const Name = styled.div`
  text-align: center;
  position: relative;
`
const NameEditLink = styled(EditLink)`
  position: absolute;
  right: 0;
  top: 0;
`

const Withdraw = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.lightGray};
  padding: 15px 0;
  position: relative;
`
const WithdrawEditLink = styled(EditLink)`
  position: absolute;
  right: 0;
  top: 15px;
`
