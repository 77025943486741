// @flow
import { bindActionCreators } from 'redux'
import { appActionCreators } from '@src/redux/modules/app'
import { toastActionCreators } from '@src/redux/modules/toast'
import constants from '@src/constants'

interface AsyncLogicErrorInterface {
  message: string;
  name: string;
  responseStatus: ?number;
  responseData: ?{
    statusCode: number,
    message: string | any
  };
}

interface AmplifyErrorInterface {
  message: string;
  code?: string;
}

export default {
  store: null,
  appActions: null,
  toastActions: null,

  // このモジュールはApp起動時にstoreと接続させる
  registerStore(store: any) {
    this.store = store
    const dispatch = this.store.dispatch
    this.appActions = bindActionCreators({ ...appActionCreators }, dispatch)
    this.toastActions = bindActionCreators({ ...toastActionCreators }, dispatch)
  },

  //
  // Flicfit API 向けエラーハンドリング
  //
  async handleAsyncLogicError(error: AsyncLogicErrorInterface): Promise<void> {
    if (error.name === constants.HTTP_FLICFIT_CONNECTION_ERROR) {
      // ネットにつながっていない場合など
      this.toastActions.add('warning', 'サーバーに接続できませんでした')
    } else if (error.name === constants.HTTP_FLICFIT_RESPONSE_ERROR) {
      // status 500 など
      this.toastActions.add('error', 'サーバーとの通信に失敗しました')
    } else if (error.name === constants.HTTP_FLICFIT_FAILURE_RESULT_GOT) {
      // この場合レスポンスデータは正常に受けとれています
      // ただし responseData.statusCode !== 200 です
      const responseData: any = error.responseData
      if (responseData.statusCode === 401) {
        // 401 認証失敗
        // ・トークン期限切れ (expired)
        // ・Authorizationがない (Authorization is not contained in Header)

        // // リロードしかできないダイアログを表示し、リロードさせることで再ログインさせる
        // this.appActions.showErrorDialog({
        //   control: 'only-reload',
        //   title: 'トークンの有効期限が切れています。再ログインが必要です',
        //   error
        // })
        // -> ダイアログ表示せずに直接リロードするように変更
        location.reload()

        // リロードから再ログインまでの処理の流れは以下になります。
        // (1) App起動時の自動ログインに失敗し、
        // (2) PrivateRouteのログインチェックにひっかかり、
        // (3) ログインページに飛ばされ（redirectUrlあり）、
        // (4) ログイン実行〜成功するとredirectUrlによりもとのページに戻ってこれます。
      } else {
        this.toastActions.add('error', 'データの処理に失敗しました')
        console.error(error)
      }
    } else {
      this.toastActions.add('error', '予期せぬエラーが発生しました')
      console.error(error)
    }
  },

  //
  // Amplify API 向けエラーハンドリング
  //
  async handleAmplifyErrorWithAnalyzeErrorCode(
    error: ?AmplifyErrorInterface,
    analyzeErrorCode: (
      errorCode: string,
      messages: Object,
      errorMessage: string
    ) => ?boolean
  ): Promise<void> {
    if (!error || !error.message) {
      alert('予期せぬエラーが発生しました')
      return
    }

    const presetMessages = {
      INPUT_ERROR: '入力エラー',
      INVALID_PASSWORD:
        '入力エラー（数字、大文字小文字アルファベットそれぞれを含めた8文字以上の半角英数字）',
      EXIST_EMAIL: 'すでに、メールアドレス登録されています',
      INVALID_CONFIRMATION_CODE: '指定アカウントと確認コードが一致しません',
      NO_MESSAGE: ' '
    }
    //
    if (error && error.code) {
      // AmplifyのAPIはバリデーションエラー内容に応じたエラーコードを返してくるので、
      // error.codeがあればそれを解析する
      const isUnexpectedCode = analyzeErrorCode(
        error.code,
        presetMessages,
        error.message
      )
      // 解析できない内容だった場合はtrueを返してもらい、一律でエラーを表示する
      if (isUnexpectedCode === true) {
        if (error.code === 'LimitExceededException') {
          alert(
            '失敗回数の上限に達しました。時間を置いてリトライしてください。'
          )
        } else if (error.code === 'UserLambdaValidationException') {
          // すでにUUIDが誰かと紐づいている場合は、ローカルストレージからFootUuidを削除
          localStorage.removeItem('flicfitData')
          alert(`${error.code || '?'}\n\n${error.message}`)
        } else {
          // Amplifyによる不明なエラーコード
          alert(`${error.code || '?'}\n\n${error.message}`)
          console.error(error)
        }
      }
    } else {
      // AmplifyのAPIリクエストの結果が接続エラーなどの場合はerror.codeは無いので
      // 一律でエラー処理する
      // ネットワークエラー、プログラムエラー
      alert(error.message)
      console.error(error)
    }
  }
}
