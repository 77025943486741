// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withState, withHandlers } from 'recompose'
import { appActionCreators } from '@src/redux/modules/app'
import logicHelper from './logicHelper'

type FormData = {
  email: string
}

const defaultFormData = function(): FormData {
  return {
    email: ''
  }
}

type Props = {
  history: any,
  appActions: any,
  formData: FormData,
  validationErrorInfo: { [errorInfoKey: string]: string },
  sendStatus: boolean,
  setFormData: Function,
  setValidationErrorInfo: Function,
  setSendStatus: Function
}

export const enhanceConnection = compose(
  connect(
    null,
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch)
    })
  ),

  withState('formData', 'setFormData', defaultFormData()),
  withState('validationErrorInfo', 'setValidationErrorInfo', {}),
  withState('sendStatus', 'setSendStatus', false),

  withHandlers({
    setFormValue: ({ formData, setFormData }: Props) => (
      key: string,
      value: string
    ) => {
      setFormData({
        ...formData,
        [key]: value
      })
    },

    handleClickSend: ({
      history,
      appActions,
      formData,
      setValidationErrorInfo,
      setSendStatus
    }: Props) => async () => {
      // バリデーションエラー表示の初期化
      setValidationErrorInfo({})

      appActions.showLoading()
      const isSucceeded = await logicHelper.sendMail(
        formData,
        setValidationErrorInfo,
        appActions
      )
      appActions.hideLoading()
      if (!isSucceeded) return

      setSendStatus(true)
      //history.push('/reset_password/submit')
    }
  })
)
