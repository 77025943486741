// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withState, withHandlers } from 'recompose'
import { appActionCreators } from '@src/redux/modules/app'
import api from '@src/api'
import commonHandlers from '@src/commonHandlers'
import { formatBirthdate } from '@src/helpers/string'
import FormValidation from '@src/helpers/FormValidation'
import { loginProcessActionCreators } from '@src/redux/modules/specifics/loginProcess'

type FormData = {
  nickname: string,
  email: string,
  password: string,
  passwordRepeated: string,
  birthdayYear: string,
  birthdayMonth: string,
  birthdayDay: string,
  gender: string,
  address: string,
  occupation: string
}

type ErrorInfoKey =
  | 'nickname'
  | 'email'
  | 'password'
  | 'birthDate'
  | 'gender'
  | 'address'
  | 'occupation'
  | 'checkTermsRead'
  | string

type Props = {
  history: any,
  appActions: any,

  //shopName: string,
  isTermsRead: boolean,
  formData: FormData,
  validationErrorInfo: {
    [errorInfoKey: ErrorInfoKey]: string
  },

  //setShopName: Function,
  setFormData: Function,
  validationErrorInfo: Function,
  setValidationErrorInfo: Function,

  setIsTermsRead: boolean => void,
  handleNext: string => void,
  loginProcessActions: { [string]: Function }
}

const defaultFormData = function(): FormData {
  return {
    nickname: '',
    email: '',
    password: '',
    passwordRepeated: '',
    //birthdayYear: (new Date().getFullYear() - 30).toString(),
    birthdayYear: '',
    birthdayMonth: '',
    birthdayDay: '',
    gender: '',
    address: '',
    occupation: ''
  }
}

export const enhanceConnection = compose(
  connect(
    null,
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      loginProcessActions: bindActionCreators(
        { ...loginProcessActionCreators },
        dispatch)
    })
  ),

  //withState('shopName', 'setShopName', ''),
  withState('formData', 'setFormData', defaultFormData()),
  withState('validationErrorInfo', 'setValidationErrorInfo', {}),
  withState('isTermsRead', 'setIsTermsRead', false),
  withHandlers({
    setFormValue: ({ formData, setFormData }: Props) => (
      key: string,
      value: string
    ) => {
      setFormData({
        ...formData,
        [key]: value
      })
    },
    setTermsRead: ({ setIsTermsRead }: Props) => (isChecked: boolean) => {
      setIsTermsRead(isChecked)
    },
    handleNext: ({
      history,
      appActions,
      formData,
      setValidationErrorInfo,
      isTermsRead,
      loginProcessActions
    }: Props) => async () => {
      // バリデーションエラー表示の初期化
      setValidationErrorInfo({})

      // (1) クライアントサイドバリデーション
      const validation = new FormValidation(formData)
      validation
        // 必須チェック
        .setRequirements({
          nickname: true,
          email: true,
          password: { errorInfoKey: 'password' },
          passwordRepeated: { errorInfoKey: 'password' }
        })
        .checkRequirements()
        // ユーザ名文字数制限
        .setMaxLengthLimits({
          nickname: { value: 20 }
        })
        // パスワード一致チェック
        .checkMaxLengthLimits()
        .checkEquality(
          'password',
          'passwordRepeated',
          'パスワードが一致しません'
        )
      if (validation.hasValidationError) {
        setValidationErrorInfo(validation.validationErrorInfo)
        return
      }

      // 利用規約同意確認
      if (!isTermsRead) {
        setValidationErrorInfo({
          checkTermsRead: '利用規約への同意が必要です'
        })
        return
      }

      // (２) 登録リクエスト
      const params = {
        email: formData.email,
        password: formData.password,
        password_confirm: formData.passwordRepeated, 
        nickname: formData.nickname,
        gender: formData.gender,
        occupation: formData.occupation,
        address: formData.address,
        birthdate: formatBirthdate(
          String(formData.birthdayYear),
          String(formData.birthdayMonth),
          String(formData.birthdayDay)
        )
      }
      appActions.showLoading()
      const result = await api.signupAccount(params)
      appActions.hideLoading()

      if (result.isSucceeded) {
        // 自動ログイン用にlocalStorageに保存
        localStorage.setItem('flicfitAuthToken', result.data.jwt)
        localStorage.setItem('flicfitType', 'email')

        const loginResult = await loginProcessActions.autoLogin()

        if (loginResult.isSucceeded) {
          history.push('/')
          return
        }

        history.push('/login')
        return
      }

      // (3) エラーハンドリング #TODO メッセージによるエラー
      /*
      const code = result.data.message
      if (code === 'InvalidPasswordException') {
        // パスワードのバリデーションエラーなので、パスワードの項目にメッセージ表示
        setValidationErrorInfo({
          password: PRESET_MESSAGES.INVALID_PASSWORD
        })
      } else if (code === 'UsernameExistsException') {
            setValidationErrorInfo({
              email: PRESET_MESSAGES.EXIST_EMAIL
            })
      } else if (code === 'InvalidParameterException') {
            // 項目別のバリデーションエラーなので、messageの内容から項目名をマッチさせる

            if (/password/.test(errorMessage)) {
              setValidationErrorInfo({
                password: PRESET_MESSAGES.INVALID_PASSWORD
              })
            } else if (/email/.test(errorMessage)) {
              setValidationErrorInfo({
                email: PRESET_MESSAGES.INPUT_ERROR
              })
            } else {
              const errorInfo = {}
              if (/username/.test(errorMessage))
                errorInfo.email = PRESET_MESSAGES.INPUT_ERROR
              else if (/nickname/.test(errorMessage))
                errorInfo.nickname = PRESET_MESSAGES.INPUT_ERROR
              else if (/gender/.test(errorMessage))
                errorInfo.gender = PRESET_MESSAGES.INPUT_ERROR
              else if (/birthdate/.test(errorMessage))
                errorInfo.birthDate = PRESET_MESSAGES.INPUT_ERROR
              else if (/address/.test(errorMessage))
                errorInfo.address = PRESET_MESSAGES.INPUT_ERROR
              else if (/occupation/.test(errorMessage))
                errorInfo.occupation = PRESET_MESSAGES.INPUT_ERROR
              setValidationErrorInfo(errorInfo)
            }

            //
      } else if (
        code === 'UserLambdaValidationException' &&
        /birth_date/.test(errorMessage)
      ) {
        // 日付の有効エラー(例：1980年2月31日）
        setValidationErrorInfo({
          birthDate: PRESET_MESSAGES.INPUT_ERROR
        })
      } else {
        return true
      }
      */
    }
  })
)
