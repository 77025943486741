// @flow
import React from 'react'
import { compose, withStateHandlers } from 'recompose'

import PrivatePageLayout from '@src/components/templates/PrivatePageLayout'
import Inner from '@src/components/templates/Inner'
import Block from '@src/components/templates/Block'
import PageTitle from '@src/components/molecules/PageTitle'
import Section from '@src/components/templates/Section'
import BlockTitle from '@src/components/atoms/BlockTitle'
import Measured from '@src/components/molecules/Measured'
import FeetRecommendSize from '@src/components/organisms/FeetRecommendSize'
import FeetPartsSize from '@src/components/organisms/FeetPartsSize'
import FeetType from '@src/components/organisms/FeetType'
import Button from '@src/components/atoms/Button'
import Aside from '@src/components/templates/Aside'
import RecommendationList from '@src/components/organisms/RecommendationList'
import ListMenu from '@src/components/molecules/ListMenu'
import Foot3d from '@src/components/organisms/Foot3d'
import NotFoundPage from '@src/components/pages/NotFoundPage'

import ModalFeetTypes from '@src/components/organisms/ModalFeetTypes'
import ModalShoesDetail from '@src/components/organisms/ModalShoesDetail'
import ModalFeedback from '@src/components/organisms/ModalFeedback'

import FootModel from '@src/models/FootModel'
import MeasureHistoryItemModel from '@src/models/MeasureHistoryItemModel'
import type { DisplayItemInterface } from '@src/types'

type ExternalProps = {
  is404PageShown: boolean,
  foot: ?FootModel,
  measureHistoryItems: Array<MeasureHistoryItemModel>,
  fitItems: Array<DisplayItemInterface>,
  setBackUrl: any,
  handleClickFavIco: (item: DisplayItemInterface) => void
}

type Props = ExternalProps & {
  feedbackModalRef: { current: null | any },
  shoesDetailModalRef: { current: null | any },
  footTypeModalRef: { current: null | any },
  history:Object
}

export const enhancePresentation = compose(
  withStateHandlers(
    {
      feedbackModalRef: React.createRef(),
      shoesDetailModalRef: React.createRef(),
      footTypeModalRef: React.createRef()
    },
    {}
  )
)

export default function Presentation({
  is404PageShown,
  foot,
  fitItems,
  setBackUrl,
  measureHistoryItems,
  handleClickFavIco,
  feedbackModalRef,
  shoesDetailModalRef,
  footTypeModalRef,
  history
}: Props) {
  // 該当するfootUuidがない
  if (is404PageShown) {
    return <NotFoundPage />
  }

  if (!foot) {
    // 仮：データ取得中の場合の表示（取得中はfootの値はnullなので表示できない）
    return (
      <PrivatePageLayout>
        <Section>&nbsp;</Section>
      </PrivatePageLayout>
    )
  }

  // 仮: とりあえず表示されるように仮データに置き換えた
  const items = measureHistoryItems.map(item => {
    return {
      path: `/feet/${item.footUuid}`,
      text: item.storeName
    }
  })

  const fitShoesItems = fitItems

  return (
    <PrivatePageLayout>
      <Inner>
        <PageTitle title="足カルテ" />
        <Measured
          date={foot.displayDate}
          storeName={foot.storeName}
          isNew={false}
        />
        <Block>
          <BlockTitle>おすすめサイズ</BlockTitle>
          <FeetRecommendSize
            recommendedLength={foot.jisSize}
            recommendedWidth={foot.jisWidth}
            jisWidthJa={foot.jisWidthJa}
          />
        </Block>
        <Foot3d pcdFileUrl={foot.tmpThreeUrl} marginLeft={'-20px'} />
        <Block>
          <BlockTitle>各部位のサイズ</BlockTitle>
          <FeetPartsSize measuredValues={foot.formattedMeasurement} />
        </Block>
        <Block>
          <BlockTitle>足のタイプと特徴</BlockTitle>
          <FeetType
            img={foot.toeShapeType.illustPath}
            imgWidth={112}
            name={foot.toeShapeType.name}
            text={foot.toeShapeType.text}
          />
          <Button
            type={'secondary'}
            onClick={() => {
              if (!footTypeModalRef.current) return
              footTypeModalRef.current.showModal(foot.toeShapeTypeCode)
            }}
          >
            その他の足のタイプ
          </Button>
        </Block>
      </Inner>
      <Aside title={'計測時の試着リスト'}>
        <RecommendationList
          recommendations={fitShoesItems}
          shouldShowFavoriteIcon={true}
          onClickShoes={(itemUuid: string) => {
            setBackUrl()
            history.push(`/item/${itemUuid}`) 
            /*
            if (!shoesDetailModalRef.current) return
            shoesDetailModalRef.current.showModal(item.itemUuid)
            */
          }}
          onClickFavIco={handleClickFavIco}
        />
      </Aside>
      <Aside title={'過去の足カルテ'}>
        <ListMenu items={items} />
      </Aside>

      <ModalFeetTypes modalRef={footTypeModalRef} />
      <ModalShoesDetail
        mode={'favorite-detail'}
        modalRef={shoesDetailModalRef}
        items={fitShoesItems}
        onClickFavIco={handleClickFavIco}
        onClickFeedback={(item: DisplayItemInterface) => {
          if (!feedbackModalRef.current) return
          feedbackModalRef.current.showModal(item)
        }}
      />
      <ModalFeedback modalRef={feedbackModalRef} />
    </PrivatePageLayout>
  )
}
