// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withHandlers, withProps, lifecycle } from 'recompose'
import { fittingActionCreators } from '@src/redux/modules/fitting'
import { favoriteActionCreators } from '@src/redux/modules/favorite'
import commonHandlers from '@src/commonHandlers'
import FittingItemModel from '@src/models/FittingItemModel'

type Props = {
  reviews: Array<any>,
  shoeReviews: Object,
  history: Object
}

export const enhanceConnection = compose(
  connect(
    state => ({
      reviews: state.fitting.reviews,
      shoeReviews: state.fitting.shoeReviews
    }),
    dispatch => ({
      fittingActions: bindActionCreators(
        { ...fittingActionCreators },
        dispatch
      ),
      favoriteActions: bindActionCreators(
        { ...favoriteActionCreators },
        dispatch
      )
    })
  ),

  withProps(({ reviews, shoeReviews, history }: Props) => {
    const displayItems: Array<Object> = reviews.map(
      (itemRaw: any) => { 
        let tmp = {...itemRaw.product, ...itemRaw.measurement, ...itemRaw.mesh}
        tmp['reviewId'] = itemRaw.reviewId
        tmp['price'] = parseInt(itemRaw.price)
        tmp['storeName'] = itemRaw.storeName
        tmp['review'] = shoeReviews[itemRaw.reviewId].fitting_log_shoe_1.answer
        tmp['reviewcreatedTime'] = itemRaw.reviewcreatedTime 
        tmp['pictures'] = itemRaw.pictures
        return new FittingItemModel(tmp)
      })
    return {
      displayItems: displayItems,
      history: history
    }
  }),

  withHandlers({
    handleClickFavIco: ({ favoriteActions }: Props) => async (
      item: FittingItemModel
    ) => {
    }
  }),

  lifecycle({
    async componentDidMount() {
      const { favoriteActions, fittingActions } = this.props
      // 絞り込みの設定を初期化
      try {
        // 一覧取得
        //await favoriteActions.fetch()
        await fittingActions.fetch()
      } catch (err) {
        commonHandlers.handleAsyncLogicError(err)
      }
    }
  })
)
