// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
  withProps
} from 'recompose'
import { appActionCreators } from '@src/redux/modules/app'
import { favoriteActionCreators } from '@src/redux/modules/favorite'
import { fittingActionCreators } from '@src/redux/modules/fitting'
import commonHandlers from '@src/commonHandlers'
import FavoriteItemModel from '@src/models/FavoriteItemModel'
import noShoesImg from '@src/assets/img/no_shoes.png'
import { createPictureUrl } from '@src/helpers/pictureUrl'
import FittingItemModel from '@src/models/FittingItemModel'

type ExternalProps = {
  match: any
}

type Props = ExternalProps & {
  currentPath: ?string,
  setCurrentPath: (?string) => void,
  item: ?Object,
  setItem: (Object) => void,
  //images: ?Array<Object>,
  loaded: boolean,
  setLoaded: boolean => void,
  reviews: Array<Object>,
  getReviewId: () => string,
  review: Object,
  shoeReviews: Object,
  footSurvey: Object,

  is404PageShown: boolean,
  updateIs404PageShown: boolean => void,
  loadData: string => Promise<void>,

  appActions: { [string]: Function },
  favoriteActions: { [string]: Function }
}

export const enhanceConnection = compose(
  connect(
    state => ({
      shoeReviews: state.fitting.shoeReviews,
      footSurvey: state.fitting.footSurvey,
      reviews: state.fitting.reviews
    }),
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      favoriteActions: bindActionCreators(
        { ...favoriteActionCreators },
        dispatch
      )
    })
  ),

  withState('currentPath', 'setCurrentPath', null),
  withState('item', 'setItem', null),
  withState('is404PageShown', 'updateIs404PageShown', false),
  withState('loaded', 'setLoaded', false),

  withProps((a : Props) => {
    console.log("PPPROPS -> " , a)
    const {footSurvey, item, shoeReviews } = a
    if (!footSurvey || !item ) return

    const { pictures } = item
    console.log("picture,", pictures) 
    let images
    if (!pictures || pictures.length === 0) {
      // 0件の可能性あり
      images = [
        {
          original: noShoesImg,
          thumbnail: noShoesImg
        }
      ]
    } else {
      const pictureKeys = Object.keys(pictures)
      images = pictureKeys.map( keyIndex => { 
        const pictureUrl =  pictures[keyIndex] ? createPictureUrl(pictures[keyIndex], false) : ''
        const pictureUrlThumb = pictures[keyIndex] ? createPictureUrl(pictures[keyIndex], true) : ''
        return ({
        original: pictureUrl || noShoesImg, // 空文字の可能性あり
        thumbnail: pictureUrlThumb || noShoesImg // 空文字の可能性あり
        })
      })
    }
    const review = shoeReviews[`${item.reviewId}`]
    return {
      item: item, 
      images: images,
      review: shoeReviews[`${item.reviewId}`],
      footSurvey: footSurvey[`${item.reviewId}`]
    }
  }),

  withHandlers({
    loadData: ({
      shoeReviews,
      reviews,
      appActions,
      updateIs404PageShown,
      setItem,
      setReview,
      setImage
    }: Props) => async reviewId => {
      if (!reviewId || !shoeReviews[`${reviewId}`]) {
        return updateIs404PageShown(true)
      }
      appActions.showLoading()

      const itemData  = reviews.find(e => e.reviewId == reviewId)

      if(!itemData) {
        appActions.hideLoading()
        return updateIs404PageShown(true)
      }

      appActions.hideLoading()
      setItem(itemData)

      },

    getReviewId: ({ match, updateIs404PageShown }: Props) => async () => {
      let reviewId: string | null = null

      // -> pathパラメータからfoot_uuidを取得
      if (typeof match.params.reviewId === 'string') {
        reviewId = match.params.reviewId
      } else {
        return updateIs404PageShown(true)
      }

      return reviewId
    },

    handleClickFavIco: ({ favoriteActions }: Props) => async (
      item: FavoriteItemModel
    ) => {
      const { itemUuid, isFavorite } = item
      if (isFavorite) {
        // お気に入り削除
        try {
          await favoriteActions.remove(itemUuid)
        } catch (err) {
          commonHandlers.handleAsyncLogicError(err)
        }
      }
    }
  }),

  lifecycle({
    async componentDidMount() {
      const {
        match,
        setCurrentPath,
        loadData,
        getReviewId,
        favoriteActions
      } = this.props
      console.log("**** ***** ***** FittingDetail")
      setCurrentPath(match.url)

      const reviewId: string | null = await getReviewId()
      await loadData(reviewId)
    },

    async componentDidUpdate() {
      const {
        match,
        setCurrentPath,
        currentPath,
        loadData,
        getReviewId
      } = this.props

      if (typeof match.url === 'string' && match.url !== currentPath) {
        // 現在のURLが変わった場合は表示データを再取得する
        setCurrentPath(match.url)

        const reviewId: string | null = await getReviewId()
        await loadData(reviewId)
      }
    }
  })
)
