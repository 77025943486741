// @flow
import React from 'react'
import styled from 'styled-components'
import { compose, withProps, withState, withHandlers, lifecycle } from 'recompose'
import withRefererableMethods from '@src/helpers/withRefererableMethods'
import analytics from '@src/analytics'
import constants from '@src/constants'

import '@src/assets/css/image-gallery.css'
import theme from '@src/misc/theme'
import noShoesImg from '@src/assets/img/no_shoes.png'

import Inner from '@src/components/templates/Inner'
import Button from '@src/components/atoms/Button'
import CheckButton from '@src/components/atoms/CheckButton'
import Text from '@src/components/atoms/Text'
import Modal from '@src/components/organisms/Modal'

type Refererable = {
  showModal: () => void
}

type ExternalProps = {
  modalRef: { current: null | Refererable },
  friendFlag: boolean
}

type Props = ExternalProps & {
  isShown: boolean,
  setIsShown: boolean => void,
  isLoginShown: boolean,
  setIsLoginShown: boolean => void,
  handleAddFriend: () => void,
  handleSignIn:() => void,
}

export const enhancePresentation = compose(
  // モーダルの開閉
  withState('isShown', 'setIsShown', false),
  withState('isLoginShown', 'setIsLoginShown', false),

  withHandlers({
    handleAddFriend: ({ setIsLoginShown }: Props) => () => async() => {
      setIsLoginShown(true)
    },
    handleSignIn:({ setIsLoginShown }: Props) => () => async() => {
    }

  }),

  // modalRefから呼べるメソッド定義
  withRefererableMethods('modalRef', {
    showModal() {
      const { setIsShown,  friendFlag } = this.props
      if(!friendFlag) setIsShown(true)
    }
  }),

  lifecycle({
    async componentDidMount() {
      const { setIsShown, friendFlag } = this.props
      if(!friendFlag) setIsShown(true)
    },
    async componentDidUpdate() {
      const { isShown,  setIsShown } = this.props
      if (!isShown) return
    }
  })
)

export default function Presentation({
  isShown,
  setIsShown,
  isLoginShown,
  handleAddFriend
}: Props) {

  return (
    <Modal isShown={isShown} onClose={() => setIsShown(false)}>
      {!isLoginShown && 
      <Inner>
      LINE ともだち登録をお願いします。<br />
        <a href="https://lin.ee/zk1kJAn" rel="noreferrer"  target="_blank" onClick={handleAddFriend()}>
          <img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0" />
        </a>
      </Inner>
      }
      {isLoginShown && 
      <Inner>
      友達に追加完了<br />
        <a href="https://lin.ee/zk1kJAn" rel="noreferrer"  target="_blank" onClick={handleAddFriend()}>
          友だち追加完了
        </a>
      </Inner>
      }
    </Modal>
  )
}

const Loading = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vw - 80px);
  max-height: 100vh;
`

const Gallery = styled.div`
  > ${Loading} {
    display: ${props => (props.loaded ? 'none' : 'block')};
  }
  > .image-gallery {
    display: ${props => (props.loaded ? 'block' : 'none')};
  }
`

const ShoesText = styled.div`
  text-align: center;
  position: relative;
  > div {
    display: inline-block;
  }
`
const StyledCheckButton = styled(CheckButton)`
  position: absolute;
  top: 0;
  right: 0;
`

const ShoesTags = styled.div`
  text-align: center;
  margin-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.color.borderGray};
  padding-top: 15px;
  > * {
    margin: 3px 4px;
  }
`

const EcButton  = styled(Button)`
  margin-top: 10px;
`
