// @flow
import Amplify, { Auth } from 'aws-amplify'

const CONFIG: any = process.env.CONFIG
const amplifySettings: { [key: string]: string } = CONFIG.amplifySettings

const amplifyHttpClient = {}

Amplify.configure({
  Auth: {
    identityPoolId: '',
    region: amplifySettings.region,
    userPoolId: amplifySettings.userPoolId,
    userPoolWebClientId: amplifySettings.userPoolWebClientId
  }
})

amplifyHttpClient.forgotPassword = async function(
  username: string
): Promise<any> {
  // パスワードリセット対象Emailアドレス送信
  return Auth.forgotPassword(username)
}

amplifyHttpClient.forgotPasswordSubmit = async function(
  username: string,
  code: string,
  newPassword: string
): Promise<any> {
  // パスワードリセット 確認コードを使って新パスワード登録
  return Auth.forgotPasswordSubmit(username, code, newPassword)
}

type SignupParams = {
  email: string,
  password: string,
  nickname: string,
  gender: string,
  occupation: string,
  address: string,
  birthdate: string,
  footUuid: ?string
}

amplifyHttpClient.signup = async function(params: SignupParams): Promise<any> {
  // 仮登録
  const data: any = {
    username: params.email,
    password: params.password,
    attributes: {
      email: params.email,
      nickname: params.nickname,
      gender: params.gender,
      'custom:occupation': params.occupation,
      address: params.address,
      birthdate: params.birthdate // "1978-01-01"
    },
    validationData: []
  }
  if (params.footUuid) {
    // パラメータの存在するのときのみ値を代入
    data.attributes['custom:foot_uuid'] = params.footUuid
  }

  return Auth.signUp(data)
}

amplifyHttpClient.confirmSignup = async function(
  email: string,
  code: string
): Promise<Object> {
  // 本登録
  // After retrieveing the confirmation code from the user
  return Auth.confirmSignUp(email, code, {
    // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    forceAliasCreation: true
  })
}

amplifyHttpClient.changePassword = async function(
  username: string,
  oldPassword: string,
  newPassword: string
): Promise<Object> {
  // パスワード変更
  return Promise.resolve()
    .then(_ => {
      return Auth.signIn(username, oldPassword)
    })
    .then(_ => {
      return Auth.currentAuthenticatedUser()
    })
    .then(user => {
      return Auth.changePassword(user, oldPassword, newPassword)
    })
}

amplifyHttpClient.currentUserInfo = async function(): Promise<Object> {
  return Auth.currentUserInfo();
}

amplifyHttpClient.currentCredentials = async function(): Promise<Object> {
  return Auth.currentCredentials();
}


export default amplifyHttpClient
