// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: any
}

export default function({ children, ...rest }: Props) {
  return <Label {...rest}>{children}</Label>
}

const Label = styled.span`
  font-size: 10px;
  background: ${({ theme }) => theme.color.yellow};
  color: ${({ theme }) => theme.color.black};
  font-weight: normal;
  line-height: 1;
  padding: 2px 6px;
  display: inline-block;
  border-radius: 2px;
  margin-left: 0.5em;
  position: relative;
  top: -1px;
`
