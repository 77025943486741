// @flow
import React from 'react'
import SelectButton from './buttons/ButtonC'
import RemoveButton from './buttons/ButtonD'

type Props = {
  tag: string,
  label: string,
  beforeLabel?: string,
  onSelect?: () => void,
  onRemove?: () => void
}

const fitTags = {
  fit: 'Good Fit',
  loose: 'ゆったりFit',
  tight: 'しっかりFit',
  outside: 'その他サイズ'
}

const StringList = ({ tag, label, beforeLabel, onSelect, onRemove }: Props) => (
  <li>
    {!!onSelect && (
      <SelectButton onClick={onSelect}>
        {beforeLabel && beforeLabel}
        {tag === 'fit' ? fitTags[label] : label}
      </SelectButton>
    )}
    {!!onRemove && (
      <RemoveButton onRemove={onRemove}>
        {beforeLabel && beforeLabel}
        {tag === 'fit' ? fitTags[label] : label}
      </RemoveButton>
    )}
  </li>
)

export default StringList
