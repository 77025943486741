// @flow

/* モデル：ユーザFoot
   ユーザ情報にセットでついてくるもので、足カルテのFootModelよりも簡易的な情報です
*/
export default class UserFootModel {
  footUuid: string
  jisSize: number
  jisWidth: string
  jisWidthJa: string
  store: string
  newFlg: boolean
  scannedTime: string

  // 追加・加工パラメータ
  displayDate: string

  constructor(raw: any) {
    Object.assign(this, {
      ...raw
    })

    this.displayDate = raw.scannedTime.split('T')[0].replace(/\-/g, '.')
  }
}
