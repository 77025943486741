// @flow
import React from 'react'
import styled from 'styled-components'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import type { ToastData } from '@src/redux/modules/toast'

type Props = {
  toasts: Array<ToastData>
}

export default function Toast({ toasts }: Props) {
  return (
    <Root>
      <TransitionGroup>
        {toasts.map(toast => (
          <CSSTransition key={toast.id} timeout={500} classNames="fade">
            <ToastChip category={toast.category}>
              <Text>{toast.message}</Text>
            </ToastChip>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Root>
  )
}

const Root = styled.div`
  z-index: 9999;
  position: fixed;
  bottom: 0vh;
  left: 50%;
  width: 0;
  height: auto;
  text-align: center;

  .fade-enter {
    opacity: 0.01;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-out;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`

const backGroundColors = {
  info: '#8bd8f5f2',
  warning: '#efde67f2',
  error: '#e4776bf2'
}

const ToastChip = styled.li`
  background-color: ${({ category }) => backGroundColors[category] || 'gray'};
  color: #404040;
  min-width: 240px;
  width: 100vw;
  margin: 0 0 12px -50vw;
  position: relative;
  list-style: none;
  text-align: center;
  padding: 12px 10px;
`

const Text = styled.p``
