// @flow
import { connect } from 'react-redux'
import { compose } from 'recompose'

//type Props = {}

export const enhanceConnection = compose(
  connect(
    state => ({
      authType: state.user.auth.type,
      userInfo: state.user.data.info,
      stores: state.store.stores
    }),
    // eslint-disable-next-line
    dispatch => ({})
  )
)
