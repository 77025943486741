// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
  withProps
} from 'recompose'
import { appActionCreators } from '@src/redux/modules/app'
import { favoriteActionCreators } from '@src/redux/modules/favorite'
import commonHandlers from '@src/commonHandlers'
import FavoriteItemModel from '@src/models/FavoriteItemModel'
import noShoesImg from '@src/assets/img/no_shoes.png'
import { createPictureUrl } from '@src/helpers/pictureUrl'

type ExternalProps = {
  match: any
}

type Props = ExternalProps & {
  currentPath: ?string,
  setCurrentPath: (?string) => void,
  item: ?Object,
  setItem: (?Object) => void,
  images: ?Array<string>,
  reviews: ?Array<any>,
  shoeFeatures: ?Array<Object>,
  loaded: boolean,
  setLoaded: boolean => void,
  reviewDetail: Array<number>,
  setReviewDetail: (number) => void,
  is404PageShown: boolean,
  updateIs404PageShown: boolean => void,
  loadData: string => Promise<void>,
  getUuid: () => string,

  favorite: any,
  appActions: { [string]: Function },
  favoriteActions: { [string]: Function }
}

export const enhanceConnection = compose(
  connect(
    state => ({
      favorite: state.favorite
    }),
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      favoriteActions: bindActionCreators(
        { ...favoriteActionCreators },
        dispatch
      )
    })
  ),

  withState('currentPath', 'setCurrentPath', null),
  withState('item', 'setItem', null),
  withState('is404PageShown', 'updateIs404PageShown', false),
  withState('loaded', 'setLoaded', false),
  withState('reviewDetail', 'setReviewDetail', []),

  withProps(({ item }: Props) => {
    if (!item) return
    const { mesh, product, measurement } = item 

    let images
    if (!mesh) {
      images = []
    } else if (!mesh || mesh.pictures.length === 0) {
      // 0件の可能性あり
      images = [
        {
          original: noShoesImg,
          thumbnail: noShoesImg
        }
      ]
    } else {
      images = mesh.pictures.map(url => { 
        const pictureUrl =  url.key ? createPictureUrl(`shoe/${url.key}`, false) : ''
        const pictureUrlThumb = url.key ? createPictureUrl(`shoe/${url.key}`, true) : ''
        return ({
        original: pictureUrl || noShoesImg, // 空文字の可能性あり
        thumbnail: pictureUrlThumb || noShoesImg // 空文字の可能性あり
        })
      })
    }

    let reviews = []
    product.measurements.forEach(v => {
      reviews = reviews.concat(v.reviews);
    })

    let feaatures = [
    {
      type: "select",
      title: "靴の幅",
      shortTitle: "靴の幅",
      answer: "",
      options: [ "狭め", "普通", "広め" ]
    },
    {
      type: "multi_select",
      title: "クッション",
      shortTitle: "クッション",
      answer: product.cushions.map(e => e.partName),
      options: [ "先端", "中心", "踵下", "側面" ]
    },
    {
      type: "select",
      title: "伸縮性",
      shortTitle: "伸縮性",
      answer: mesh.stretch,
      options: [ "伸びる","普通" ,"伸びない"  ]
    }
    ]

    // 靴幅の広さ
    switch(measurement.widthClass) {
      case "very_narrow":
      case "narrow":
        feaatures[0]["answer"] = feaatures[0]["options"][0]
        break
      case "standard":
        feaatures[0]["answer"] = feaatures[0]["options"][1]
        break
      case "wide":
      case "very_wide":
        feaatures[0]["answer"] = feaatures[0]["options"][2]
        break
      default:
        break
    }

    if(feaatures[2]["answer"] == '' || feaatures[2]["answer"] ==  null) {
      feaatures.splice(2, 1); 
    }

    if(feaatures[0]["answer"] == '' || feaatures[0]["answer"] == null) {
      feaatures.splice(0, 1); 
    }

    return {
      item: item,
      images: images,
      reviews: reviews,
      shoeFeatures: feaatures
    }
  }),

  withHandlers({
    loadData: ({
      appActions,
      favoriteActions,
      updateIs404PageShown,
      setItem
    }: Props) => async uuid => {
      if (!uuid) {
        return updateIs404PageShown(true)
      }
      appActions.showLoading()
      try {
        const itemData  = await favoriteActions.fetchItem(uuid)

        setItem(itemData)
      } catch (err) {
        if (err.responseData && err.responseData.statusCode === 404) {
          return updateIs404PageShown(true)
        } else {
          commonHandlers.handleAsyncLogicError(err)
          return updateIs404PageShown(true)
        }
      } finally {
        appActions.hideLoading()
      }
    },

    getUuid: ({ match, updateIs404PageShown }: Props) => async () => {
      let uuid: string | null = null

      // -> pathパラメータからfoot_uuidを取得
      if (typeof match.params.uuid === 'string') {
        uuid = match.params.uuid
      } else {
        return updateIs404PageShown(true)
      }

      return uuid
    },

    handleClickFavIco: ({ favoriteActions }: Props) => async (
      item: FavoriteItemModel
    ) => {
      const { itemUuid, isFavorite } = item
      if (isFavorite) {
        // お気に入り削除
        try {
          await favoriteActions.remove(itemUuid)
        } catch (err) {
          commonHandlers.handleAsyncLogicError(err)
        }
      }
    },
    handleClickMore: ({reviewDetail, setReviewDetail }: Props) => async (
      reviewId: number
    ) => {

      console.log("Click More" , reviewId)
      let tmpReviewDetail = [...reviewDetail]
      if( reviewDetail.includes(reviewId)) {
        tmpReviewDetail = reviewDetail.filter(e => e != reviewId)
      } else {
        tmpReviewDetail.push(reviewId)
      }
      setReviewDetail(tmpReviewDetail)
    }
  }),

  lifecycle({
    async componentDidMount() {
      const {
        match,
        setCurrentPath,
        loadData,
        getUuid,
        favoriteActions
      } = this.props
      setCurrentPath(match.url)

      const uuid: string | null = await getUuid()
      console.log("componentDidMount", uuid)
      await loadData(uuid)
    },

    async componentDidUpdate() {
      const {
        match,
        setCurrentPath,
        currentPath,
        loadData,
        getUuid
      } = this.props

      if (typeof match.url === 'string' && match.url !== currentPath) {
        // 現在のURLが変わった場合は表示データを再取得する
        setCurrentPath(match.url)

        const uuid: string | null = await getUuid()
        await loadData(uuid)
      }
    }
  })
)
