// @flow
import React from 'react'
import styled from 'styled-components'
import theme from '@src/misc/theme'

import Shoes from '@src/components/molecules/Shoes'
import Price from '@src/components/atoms/Price'
import Text from '@src/components/atoms/Text'

import type { DisplayItemInterface } from '@src/types'

// 検討: このリストはrecommendationsとfititemsで兼用なので、名前とかIFとか変えたい

type Props = {
  recommendations: Array<DisplayItemInterface>,
  shouldShowFavoriteIcon?: boolean,
  onClickFavIco?: (item: DisplayItemInterface) => void,
  onClickShoes?: (itemUuid:any) => void
}

export default function Presentation({
  recommendations,
  shouldShowFavoriteIcon,
  onClickShoes,
  onClickFavIco
}: Props) {
  const items = recommendations
  console.log( "recommend list ", items )
  return (
    <RecommendationList>
      {items.map(item => (
        <li key={item.itemUuid}>
          <Shoes
            fit={item.fit}
            imgPath={item.pictureUrl}
            shouldShowFavIco={shouldShowFavoriteIcon}
            isFavorite={item.isFavorite}
            onClickShoes={() => {
              if (onClickShoes) onClickShoes(item.itemId)
            }}
            onClickFavIco={() => {
              if (onClickFavIco) onClickFavIco(item)
            }}
          />

          <Bottom>
            {item.price > 0 && 
            <Price
              price={item.price}
              taxDispSetting={item.taxDispSetting || ''}
            />
            }
            <Text color={theme.color.gray} size={11}>
              {item.storeName}
            </Text>
          </Bottom>
        </li>
      ))}
    </RecommendationList>
  )
}

const RecommendationList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  li {
    margin-bottom: 25px;
    width: 46%;
    text-align: center;
  }
`
const Bottom = styled.div`
  margin-top: 8px;
`
