// @flow
import produce from 'immer'
import api from '@src/api'
// import { createAction } from 'redux-actions'
import { createActionThunk } from 'redux-thunk-actions'

import { withProps } from 'recompose'

import UserInfoModel from '@src/models/UserInfoModel'
import UserFootModel from '@src/models/UserFootModel'

import flicfitHttpClient from '@src/api/httpClients/flicfitHttpClient'
import amplifyHttpClient from '@src/api/httpClients/amplifyHttpClient'
import lineHttpClient from '@src/api/httpClients/lineHttpClient'

import { getUrlQueries } from '@src/helpers/string'

/*==================================
* Actions
==================================*/
const THUNK = {}
const REDUCER = {}

// Public
THUNK.FETCH_AUTH_TOKEN = 'user/THUNK.FETCH_AUTH_TOKEN'
THUNK.FETCH_LINE_AUTH_TOKEN = 'user/THUNK.FETCH_LINE_AUTH_TOKEN'
THUNK.RESTORE_AUTH_TOKEN = 'user/THUNK.RESTORE_AUTH_TOKEN'
THUNK.LOGOUT_ASYNC = 'user/THUNK.LOGOUT_ASYNC'
//THUNK.FORCE_LOGOUT = 'user/THUNK.FORCE_LOGOUT'
THUNK.FETCH_USER = 'user/THUNK.FETCH_USER'
THUNK.UPDATE_USER = 'user/THUNK.UPDATE_USER'
THUNK.DELETE_USER = 'user/THUNK.DELETE_USER'
THUNK.SET_FOOTUUID = 'user/THUNK.SET_FOOTUUID'

// Private
REDUCER.SET_AUTH_TOKEN = 'user/REDUCER.SET_AUTH_TOKEN'
REDUCER.SET_LOGIN_INFO = 'user/REDUCER.SET_LOGIN_INFO'
REDUCER.CLEAR_USER_DATA = 'user/REDUCER.CLEAR_USER_DATA'
REDUCER.SET_SUPPLEMENT_FOR_RECOMMENDATION =
  'user/REDUCER.SET_SUPPLEMENT_FOR_RECOMMENDATION'

/*==================================
* Action Creators
==================================*/
export const userActionCreators = {
  fetchAuthToken: createActionThunk(
    THUNK.FETCH_AUTH_TOKEN,
    async (email, password, { dispatch }): Promise<void> => {
      const result = await api.login(email, password)
      console.log(result)

      if (!result.data.jwt) throw new Error('認証失敗')

      // 新規トークン取得に成功
      const authToken = result.data.jwt
      //const uuid = result.uuid
      const type = 'email'

      // 自動ログイン用にlocalStorageに保存
      localStorage.setItem('flicfitAuthToken', authToken)
      localStorage.setItem('flicfitType', type)

      dispatch({
        type: REDUCER.SET_AUTH_TOKEN,
        payload: {
          authToken,
          type
        }
      })
    }
  ),

  fetchLineAuthTokenCallback: createActionThunk(
    THUNK.FETCH_LINE_AUTH_TOKEN,
    async ({ dispatch }): Promise<void> => {
    var curUrl = window.location.href;
    const queries = getUrlQueries()

    if(queries.error) throw new Error(`認証失敗 (LINE ${queries.error_description})`)
    const state = queries.state


    // LINEのAPIにはstateをチェックする必要があるとあるが...QR読み取りブラウザにおいては、
    // localhostを保存できないので、いったんこのチェックは外す
    /*
    const json = localStorage.getItem('flicfitData') || '{}'
    let flicfitData = JSON.parse(json)

    if( !flicfitData.state || flicfitData.state != state ) {
      throw new Error('認証失敗 (LINE stateError)')
    }
    */
    //queries.friendship_status_changed


    const line = await api.getLineAccessToken(queries.code)
    const info = await api.lineLogin(line.idToken)

    // TODO下記アクセストークンのセットを実施

    const accessToken = info.data.jwt

    if (!accessToken ) throw new Error('認証失敗')

    // 新規トークン取得に成功
    const authToken = accessToken
    const type =  'LINE'

    // 自動ログイン用にlocalStorageに保存
    localStorage.setItem('flicfitAuthToken', authToken)
    localStorage.setItem('flicfitType', type)

    // TODO footuuidの引継ぎ(LocalHostを利用できない時..)
    if ( state.length  == 35){
      const json = localStorage.getItem('flicfitData') || '{}'
      let flicfitData = JSON.parse(json)
      flicfitData.footUuidInflowedFromQrCode = state
      localStorage.setItem('flicfitData', JSON.stringify(flicfitData))
    }

     // 足データの登録はcallbackでは実行しない
     //const result = await api.registerFootUuid()

      dispatch({
        type: REDUCER.SET_AUTH_TOKEN,
        payload: {
          authToken,
          type
        }
      })
    }
  ),

  setFootUuid: createActionThunk(
    THUNK.SET_FOOTUUID,
    async ( { dispatch }): Promise<void> => {
      const result = await api.registerFootUuid()
    }
  ),

  restoreAuthToken: createActionThunk(
    THUNK.RESTORE_AUTH_TOKEN,
    async ({ dispatch }): Promise<void> => {
      const flicfitAuthToken = localStorage.getItem('flicfitAuthToken')
      const flicfitType = localStorage.getItem('flicfitType')

      // トークンなし
      if (!flicfitAuthToken) throw new Error('No authtoken in localstorage')
      if (!flicfitType) throw new Error('No type in localstorage')

      // localStorageからトークン取得に成功
      const authToken = flicfitAuthToken
      const type = flicfitType
      dispatch({
        type: REDUCER.SET_AUTH_TOKEN,
        payload: {
          authToken,
          type 
        }
      })
    }
  ),

  logoutAsync: createActionThunk(
    THUNK.LOGOUT_ASYNC,
    async ({}): Promise<void> => {
      try {
        await api.logout()
        const flicfitType = localStorage.getItem('flicfitType')
        if( flicfitType == 'LINE'){
          // LINEのログアウトがあれば...
        }
      } catch (err) {
      } finally {
        // とにかくトークン消去
        localStorage.setItem('flicfitAuthToken', '')
        localStorage.setItem('flicfitType', '')
        // 強制的に画面遷移
        window.location.href = '/login'
      }
    }
  ),

  fetchUser: createActionThunk(
    THUNK.FETCH_USER,
    async ({ dispatch }): Promise<void> => {
      const result = await api.getUser()

      // おすすめ一覧取得のための応急対応
      //
      // foot.foot_uuidが存在する場合、「おすすめ一覧」を取得するための補足情報として
      // GET /enduser/feet/{foot_uuid} から size と width を取得しておくための処理。
      // 現状、user.data.footだけだと「おすすめ一覧」取得のためのパラメータが足りないため。
      // 詳しくは2019/2/12の質問ログを参照のこと。
      let supplementForRecommendation = null
      const userFoot = result.data.foot
      if (userFoot && userFoot.footUuid) {
        //const {footLength, footWidth} = await api.getFeetByid(userFoot.footUuid)
        const {data: { foot :{ measValue}}} = await api.getFeetByid(userFoot.footUuid)
        let filtering = null
        if ( measValue.filtering ) {
          filtering = measValue.filtering
        }
        const {footLength, footWidth} = userFoot
        supplementForRecommendation = {
          size: footLength,
          width: footWidth,
          filtering: filtering
        }
      }

      // LINE友達登録のチェック
      /* TODO 
      const flicfitType = localStorage.getItem('flicfitType')
      if( flicfitType == 'LINE'){
        const lineAccessToken = await api.getCognitoUserInfo()

        if( lineAccessToken != null ){
          const friendstatus = await api.getLineFrinedStatus(lineAccessToken)
          result.data.info['friendstatus'] = friendstatus 
        }
      }
      */

      dispatch({
        type: REDUCER.SET_SUPPLEMENT_FOR_RECOMMENDATION,
        payload: {
          supplementForRecommendation
        }
      })

      dispatch({
        type: REDUCER.SET_LOGIN_INFO,
        payload: {
          userData: result.data
        }
      })
    }
  ),

  updateUser: createActionThunk(
    THUNK.UPDATE_USER,
    async (params, { dispatch }): Promise<void> => {
      // アカウント編集
      await api.updateUser(params)

      // ユーザ情報を更新
      const result = await api.getUser()
      dispatch({
        type: REDUCER.SET_LOGIN_INFO,
        payload: {
          userData: result.data
        }
      })
    }
  ),

  deleteUser: createActionThunk(
    THUNK.DELETE_USER,
    async ({}): Promise<void> => {
      await api.deleteUser()

      // 成功
      // cognitoのsiginout
      const flicfitType = localStorage.getItem('flicfitType')  
      if( flicfitType == 'LINE'){
        // LINEのログアウト
      }

      // トークン消去
      localStorage.setItem('flicfitAuthToken', '')
      localStorage.setItem('flicfitType', '')
    }
  )
}

/*==================================
Initial State
==================================*/
type State = {
  auth: {
    token: ?string,
    type: ?string
  },
  data: {
    info: UserInfoModel | null,
    foot: UserFootModel | null
  },
  // おすすめ一覧取得のための応急対応
  supplementForRecommendation: null | {
    size: number,
    width: number
  }
}

const initialState = {
  auth: {
    token: null,
    type: null
  },
  data: {
    info: null,
    foot: null
  },
  // おすすめ一覧取得のための応急対応
  supplementForRecommendation: null
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.SET_AUTH_TOKEN: {
        draft.auth = {
          token: action.payload.authToken,
          type: action.payload.type
        }
        return
      }
      case REDUCER.SET_LOGIN_INFO: {
        // 取得したuserのfootは空オブジェクトの場合がありうるのでその場合はnullとする
        draft.data = {
          info: new UserInfoModel(action.payload.userData.info),
          foot: action.payload.userData.foot.footUuid
            ? new UserFootModel(action.payload.userData.foot)
            : null
        }
        return
      }
      case REDUCER.CLEAR_USER_DATA: {
        draft.data = {
          info: null,
          foot: null
        }
        draft.auth = initialState.auth
        return
      }
      // おすすめ一覧取得のための応急対応
      case REDUCER.SET_SUPPLEMENT_FOR_RECOMMENDATION: {
        draft.supplementForRecommendation =
          action.payload.supplementForRecommendation
        return
      }
    }
  })
}

/*==================================
* Selector
==================================*/
export const withLoggedIn = withProps(({ user }) => {

  console.log("withLoggedIn", user)
  return {
    isLoginCompleted: user.data.info != null
  }
})
