// @flow
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import GlobalNav from '@src/components/molecules/GlobalNav'

import logoPng from '@src/assets/img/logo.png'

export default function Presentation() {
  return (
    <Header>
      <h1>
        <Link to="/">
          <img src={logoPng} width="67" height="22" alt="Flicfit" />
        </Link>
      </h1>
      <GlobalNav />
    </Header>
  )
}

const Header = styled.header`
  background: ${({ theme }) => theme.color.yellow};
  text-align: center;
  padding: 14px 0 17px;
  position: relative;
  h1 {
    margin: 0;
  }
`
