// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: any
}

export default function({ children, ...rest }: Props) {
  return <BlockTitle {...rest}>{children}</BlockTitle>
}

const BlockTitle = styled.h2`
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 10px;
`
