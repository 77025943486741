// @flow
import React from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'
import constants from '@src/constants'

import Header from '@src/components/organisms/Header'
import Footer from '@src/components/organisms/Footer'

type ExternalProps = {
  children: any
}

type Props = ExternalProps

/*==================================
* enhance
==================================*/

export const enhancePresentation = compose()

/*==================================
* View
==================================*/

export default function({ children }: Props) {
  return (
    <Root>
      <Header />
      <main>{children}</main>
      <Footer />
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  max-width: ${constants.LAYOUT_MAX_WIDTH}px;
  min-height: 100vh;
  background: #fff;
  margin: 0 auto;
`
