// @flow
const HEEL_HEIGHT_DATA_SETTINGS = [
  { label: '〜3cm', lower: -999, upper: 30 },
  { label: '3.1〜5cm', lower: 31, upper: 50 },
  { label: '5.1〜7cm', lower: 51, upper: 70 },
  { label: '7.1〜10cm', lower: 71, upper: 100 },
  { label: '10.1cm〜', lower: 101, upper: 999 }
]

export function findHeelHeightData(value: any) {
  const found = HEEL_HEIGHT_DATA_SETTINGS.find((heelHeightData: any) => {
    const heelHeight = value
    const min = heelHeightData.lower
    const max = heelHeightData.upper
    return heelHeight > min && heelHeight <= max
  })
  return found
}

export function isValueConformToTag(
  value: any,
  tag: any,
  tagKey: string
): boolean {
  if (tagKey === 'heelHeight') {
    const heelHeight = value
    const min = tag.heelHeightData.lower
    const max = tag.heelHeightData.upper
    return heelHeight > min && heelHeight <= max
  } else if (tagKey === 'strap' || tagKey === 'shoelace') {
    const hasIt = value
    return hasIt === tag.bool
  } else if (tagKey === 'colorId') {
    const colorId = value
    return colorId === tag.id
  } else {
    const name = value
    return name === tag.name
  }
}
