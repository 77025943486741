// @flow
import produce from 'immer'
import api from '@src/api'
import { createActionThunk } from 'redux-thunk-actions'

/*==================================
* Actions
==================================*/
const THUNK = {}
const REDUCER = {}

// Public
THUNK.FETCH = 'favorite/THUNK.FETCH'
THUNK.ADD = 'favorite/THUNK.ADD'
THUNK.REMOVE = 'favorite/THUNK.REMOVE'
THUNK.FETCH_ITEM = 'favorite/THUNK.FETCH_ITEM'

// Private
REDUCER.SET = 'favorite/REDUCER.SET'
REDUCER.ADD = 'favorite/REDUCER.ADD'
REDUCER.REMOVE = 'favorite/REDUCER.REMOVE'

/*==================================
* Action Creators
==================================*/
export const favoriteActionCreators = {
  fetch: createActionThunk(
    THUNK.FETCH,
    async ({ dispatch }): Promise<void> => {
      const result = await api.getFavoriteItems()

      const items = result.data.favorite
      const ids: Array<number> = items.map(item => item.itemUuid)

      dispatch({
        type: REDUCER.SET,
        payload: {
          items,
          ids
        }
      })
    }
  ),

  add: createActionThunk(
    THUNK.ADD,
    async (
      itemUuid: number,
      isFitted: boolean,
      fit: 'fit' | 'loose' | 'tight' | 'outside',
      footUuid: string, // <- favoriteではuserFoot.footUuidを用いる
      { dispatch }
    ): Promise<void> => {
      dispatch({
        type: REDUCER.ADD,
        payload: {
          id: itemUuid
        }
      })
      await api.addFavoriteItem(
        itemUuid,
        isFitted ? 'fit' : 'favorite',
        fit,
        footUuid
      )
      //
      // 注：この THUNK.ADD アクションでは ids しか更新していない。
      //    items に追加データを反映するには THUNK.FETCH が必要。
      //    （おすすめ一覧ページでは ids しか見ていないから THUNK.FETCH しなくていい）
      //
      //    あるいは、この場でitemsを更新するために追加されたアイテムを１件を
      //    レスポンスデータとして返してもらうようにAPIを変更する手もある。
    }
  ),

  remove: createActionThunk(
    THUNK.REMOVE,
    async (itemUuid: number, { dispatch }): Promise<void> => {
      dispatch({
        type: REDUCER.REMOVE,
        payload: {
          id: itemUuid
        }
      })
      await api.removeFavoriteItem(itemUuid)
    }
  ),

  fetchItem: createActionThunk(
    THUNK.FETCH_ITEM,
    async ( itemUuid: number ,{ dispatch }): Promise<void> => {

      const result = await api.getItem(itemUuid)
      
      return result
    }
  ),
  fetchFittingItem: createActionThunk(
    THUNK.FETCH_ITEM,
   async ({ dispatch }): Promise<void> => {

      const result = await api.getFittingItem()
      
      return result
    }
  ),
}



/*==================================
Initial State
==================================*/
type State = {
  items: Array<Object>,
  ids: Array<number>
}

const initialState = {
  items: [],
  ids: []
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.SET: {
        draft.items = action.payload.items
        draft.ids = action.payload.ids
        return
      }
      case REDUCER.ADD: {
        const id: number = action.payload.id
        if (state.ids.indexOf(id) !== -1) return
        draft.ids = [...state.ids, id]
        return
      }
      case REDUCER.REMOVE: {
        const targetId: number = action.payload.id
        draft.ids = state.ids.filter(id => id !== targetId)
        draft.items = state.items.filter(item => item.itemUuid !== targetId)
        return
      }
    }
  })
}

/*==================================
* Selector
==================================*/
