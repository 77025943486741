// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withHandlers, withProps } from 'recompose'
import analytics from '@src/analytics'
import constants from '@src/constants'

import { appActionCreators } from '@src/redux/modules/app'
import { userActionCreators } from '@src/redux/modules/user'

type Props = {
  backUrl: ?string,
  appActions: { [string]: Function },
  userActions: { [string]: Function }
}

export const enhanceConnection = compose(
  connect(
    state => ({
      backUrl: state.app.backUrl
    }),
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      userActions: bindActionCreators({ ...userActionCreators }, dispatch)
    })
  ),
    withProps(( {backUrl} : Props) => {
      console.log('GlovalNav WidthProps ', backUrl)
      if ( !backUrl) return
      const path = location.pathname.match(/^\/fitting|item\/\d{1,10}?$/)
      if (!path) return { backUrl: null }
      return {
        backUrl: backUrl
      }
  }),

  withHandlers({
    handleLogout: ({ appActions, userActions }: Props) => async () => {
      appActions.showLoading()

      // アクセス解析 送信
      const pageId = constants.PSEUDO_PAGE_PATH_EXEC_LOGOUT
      analytics.sendAnalyticsData(pageId)

      // ログアウト処理は強制的に静的画面遷移するため、データ送信がちゃんと完了するよう少し待つ
      await new Promise(r => setTimeout(r, 500))

      userActions.logoutAsync()
    }
  })
)
