// @flow
import React from 'react'
import styled from 'styled-components'
import { Link, Redirect } from 'react-router-dom'
import { compose, withHandlers, withState } from 'recompose'

import DefaultPageLayout from '@src/components/templates/DefaultPageLayout'
import PageTitle from '@src/components/molecules/PageTitle'
import Inner from '@src/components/templates/Inner'

import Input from '@src/components/atoms/forms/Input'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'
import theme from '@src/misc/theme'

import icoFacebookImg from '@src/assets/img/ico_facebook.png'
import icoTwitterImg from '@src/assets/img/ico_twitter.png'
import icoLineImg from '@src/assets/img/ico_line.png'
import icoYahooImg from '@src/assets/img/ico_yahoo.png'
import icoMailImg from '@src/assets/img/ico_mail.png'
import icoLockImg from '@src/assets/img/ico_lock.png'
import icoMailWhiteImg from '@src/assets/img/ico_mail_white.png'

import amplifyHttpClient from '@src/api/httpClients/amplifyHttpClient'

// 外部供給のProps
type ExternalProps = {
  loginErrorInfo: {
    message: string
  }
}

/*==================================
* View
==================================*/
export default function Presentation() {
  return (
    <DefaultPageLayout>
    {/* 
      <Redirect to='/' />
    */}
    </DefaultPageLayout>
  )
}