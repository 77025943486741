// @flow
import produce from 'immer'
import { createActionThunk } from 'redux-thunk-actions'
import { withProps } from 'recompose'

import StoreModel from '@src/models/StoreModel'

/*
 * お気に入り一覧ページでの絞り込み条件を取り扱うストアです
 */

/*==================================
* Actions
==================================*/
const THUNK = {}
const REDUCER = {}

// Public
THUNK.UPDATE_SELECTED_SHOP = 'filteringCondition/THUNK.UPDATE_SELECTED_SHOP'
THUNK.UPDATE_SELECTED_CATEGORY =
  'filteringCondition/THUNK.UPDATE_SELECTED_CATEGORY'
THUNK.RESET_SELECTED_SHOP = 'filteringCondition/THUNK.RESET_SELECTED_SHOP'
THUNK.RESET_SELECTED_CATEGORY =
  'filteringCondition/THUNK.RESET_SELECTED_CATEGORY'

// Private
REDUCER.SET_SELECTED_SHOP = 'filteringCondition/REDUCER.SET_SELECTED_SHOP'
REDUCER.SET_SELECTED_CATEGORY =
  'filteringCondition/REDUCER.SET_SELECTED_CATEGORY'

/*==================================
* Action Creators
==================================*/
export const filteringConditionActionCreators = {
  updateSelectedShop: createActionThunk(
    THUNK.UPDATE_SELECTED_SHOP,
    async (selected, { dispatch, getState }): Promise<void> => {
      const stores: Array<StoreModel> = getState().store.stores

      dispatch({
        type: REDUCER.SET_SELECTED_SHOP,
        payload: {
          isAll: selected.length === stores.length,
          selectedIds: selected.map(store => store.storeId),
          selectedNames: selected.map(store => store.storeName)
        }
      })
    }
  ),

  resetSelectedShop: createActionThunk(
    THUNK.RESET_SELECTED_SHOP,
    async ({ dispatch, getState }): Promise<void> => {
      // 初期選択はお気に入り店舗全て
      const stores: Array<StoreModel> = getState().store.stores
      const filtered: Array<StoreModel> = stores.filter(
        store => store.favoriteFlag
      )

      dispatch({
        type: REDUCER.SET_SELECTED_SHOP,
        payload: {
          isAll: stores.length === filtered.length,
          selectedIds: filtered.map(store => store.storeId),
          selectedNames: filtered.map(store => store.storeName)
        }
      })
    }
  ),

  updateSelectedCategory: createActionThunk(
    THUNK.UPDATE_SELECTED_CATEGORY,
    async (selected, { dispatch, getState }): Promise<void> => {
      const categories = getState().master.tags.category
      const categoryNames = categories.map(
        (category: { name: string }) => category.name
      )

      dispatch({
        type: REDUCER.SET_SELECTED_CATEGORY,
        payload: {
          isAll: selected.length === categoryNames.length,
          selectedNames: selected
        }
      })
    }
  ),

  resetSelectedCategory: createActionThunk(
    THUNK.RESET_SELECTED_CATEGORY,
    async ({ dispatch, getState }): Promise<void> => {
      // 初期選択はマスターデータにあるカテゴリー全て
      const categories = getState().master.tags.category
      const categoryNames = categories.map(
        (category: { name: string }) => category.name
      )
      dispatch({
        type: REDUCER.SET_SELECTED_CATEGORY,
        payload: {
          isAll: true,
          selectedNames: categoryNames
        }
      })
    }
  )
}

/*==================================
Initial State
==================================*/

type State = {
  shop: {
    isAll: boolean,
    selectedIds: Array<number>,
    selectedNames: Array<string>
  },
  category: {
    isAll: boolean,
    selectedNames: Array<string>
  }
}

const initialState = {
  shop: {
    isAll: true,
    selectedIds: [],
    selectedNames: []
  },
  category: {
    isAll: true,
    selectedNames: []
  }
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.SET_SELECTED_SHOP: {
        draft.shop = action.payload
        return
      }
      case REDUCER.SET_SELECTED_CATEGORY: {
        draft.category = action.payload
        return
      }
    }
  })
}

/*==================================
* Selector
==================================*/
type FilteringConditionTexts = {
  shop: string,
  category: string
}
export const withFilteringConditionTexts = withProps(
  ({
    filteringCondition
  }): { filteringConditionTexts: FilteringConditionTexts } => {
    return {
      filteringConditionTexts: {
        shop: filteringCondition.shop.isAll
          ? 'すべて'
          : filteringCondition.shop.selectedNames.join(', '),
        category: filteringCondition.category.isAll
          ? 'すべて'
          : filteringCondition.category.selectedNames.join(', ')
      }
    }
  }
)
