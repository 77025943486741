// @flow
import React from 'react'
import styled from 'styled-components'

import ListMenu from '@src/components/molecules/ListMenu'

export default function() {
  const items = [
    { path: '/', text: 'マイページTOP' },
    { path: '/feet', text: '足カルテ' },
    { path: '/recommendation', text: 'おすすめ' },
    { path: '/favorites', text: 'お気に入り' },
    { path: '/account', text: 'アカウント情報' }
  ]
  return (
    <Footer>
      <ListMenu items={items} />
      <p>
        <small>©Copyright Flicﬁt All rights reserved.</small>
      </p>
    </Footer>
  )
}

const Footer = styled.footer`
  margin-top: 40px;
  p {
    text-align: center;
    margin: 0;
    padding: 10px 0;
    small {
      color: ${({ theme }) => theme.color.gray};
      font-size: 10px;
    }
  }
`
