// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  compose,
  withState,
  withHandlers,
  withProps,
  lifecycle
} from 'recompose'
import { appActionCreators } from '@src/redux/modules/app'
import { recommendationActionCreators } from '@src/redux/modules/recommendation'
import { favoriteActionCreators } from '@src/redux/modules/favorite'
import { withStoreCodeMap } from '@src/redux/modules/store'
import commonHandlers from '@src/commonHandlers'

import UserInfoModel from '@src/models/UserInfoModel'
import UserFootModel from '@src/models/UserFootModel'
import RecommendationItemModel from '@src/models/RecommendationItemModel'
import FavoriteItemModel from '@src/models/FavoriteItemModel'

const RECOMMENDATIONS_DISPLAY_LIMIT = 6

type Props = {
  // 注: このページがマウントされる時点でuserInfoがnullになることはありません
  userInfo: UserInfoModel,
  // 注: userFootはnullの場合があります
  userFoot: UserFootModel | null,
  supplementForRecommendation: null | { size: number, width: number, filtering: any },

  recommendation: { items: Array<any> },
  storeCodeMap: { [storeCode: string]: { storeName: string } },
  favorite: any,

  setIsPageLoadedFirstTime: boolean => void,

  appActions: { [string]: Function },
  recommendationActions: { [string]: Function },
  favoriteActions: { [string]: Function },

  recommendationAllItems: Array<RecommendationItemModel>,
  recommendationDisplayItems: Array<RecommendationItemModel>
}

export const enhanceConnection = compose(
  connect(
    state => ({
      userInfo: state.user.data.info,
      userFoot: state.user.data.foot,
      supplementForRecommendation: state.user.supplementForRecommendation,
      recommendation: state.recommendation,
      store: state.store,
      favorite: state.favorite
    }),
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      recommendationActions: bindActionCreators(
        { ...recommendationActionCreators },
        dispatch
      ),
      favoriteActions: bindActionCreators(
        { ...favoriteActionCreators },
        dispatch
      )
    })
  ),

  withStoreCodeMap,

  withProps(({ recommendation, favorite, storeCodeMap, appActions }: Props) => {
    // おすすめアイテムデータにお気に入りフラグを追加
    const allItems = recommendation.items.map(item => {
      const isFavorite = favorite.ids.includes(item.itemUuid)
      const storeName = storeCodeMap[item.clientCode].storeName
      return new RecommendationItemModel(item, isFavorite, storeName)
    })

    const displayItems = allItems.slice(0, RECOMMENDATIONS_DISPLAY_LIMIT)

    // 算出プロパティを返す
    return {
      recommendationAllItems: allItems,
      recommendationDisplayItems: displayItems,
      setBackUrl:  appActions.setBackUrl
    }
  }),

  withProps(({ favorite }: Props) => {
    // お気に入りアイテムリスト
    const favoriteItems: Array<FavoriteItemModel> = favorite.items.map(
      (itemRaw: any) => new FavoriteItemModel(itemRaw, true)
    )

    // 算出プロパティを返す
    return {
      favoriteDisplayItems: favoriteItems
    }
  }),

  withHandlers({
    handleClickFavIco: ({ favoriteActions, userFoot }: Props) => async (
      item: FavoriteItemModel | RecommendationItemModel
    ) => {
      const { itemUuid, isFitted, isFavorite, fit } = item
      if (isFavorite) {
        // お気に入り削除
        try {
          await favoriteActions.remove(itemUuid)
        } catch (err) {
          commonHandlers.handleAsyncLogicError(err)
        }
      } else {
        // お気に入り追加
        if (!userFoot) throw Error('この時点ではfootはあるはず@MyPage')
        const footUuid = userFoot.footUuid
        try {
          await favoriteActions.add(itemUuid, isFitted, fit, footUuid)
        } catch (err) {
          commonHandlers.handleAsyncLogicError(err)
        }
      }
    },

    reloadFavoriteItems: ({ favoriteActions }: Props) => async () => {
      try {
        // お気に入りアイテムをリロード
        // （おすすめ一覧からお気に入りアイテムが追加されている場合があるため）
        // （このリロードが重ければadd時のAPIレスポンスで追加したitemを受け取るしかない）
        await favoriteActions.fetch()
      } catch (err) {
        commonHandlers.handleAsyncLogicError(err)
        return
      }
    }
  }),

  withState('isPageLoadedFirstTime', 'setIsPageLoadedFirstTime', false),

  lifecycle({
    async componentDidMount() {
      const {
        setIsPageLoadedFirstTime,
        appActions,
        recommendationActions,
        favoriteActions,
        userFoot,
        supplementForRecommendation,
        friendModalRef
      } = this.props

      appActions.showLoading()
      try {
        // おすすめ一覧クリア
        await recommendationActions.clear()
        // お気に入りアイテム
        await favoriteActions.fetch()

        // 仕様: userFootがない（＝計測歴がない）場合、おすすめ一覧は表示なし（０件で表示）
        if (userFoot && supplementForRecommendation) {
          // おすすめ一覧取得
          await recommendationActions.find(
            supplementForRecommendation.size,
            supplementForRecommendation.width,
            supplementForRecommendation.filtering
          )
        }
      } catch (err) {
        console.log(err)
        commonHandlers.handleAsyncLogicError(err)
        return
      } finally {
        appActions.hideLoading()
        setIsPageLoadedFirstTime(true)
      }
    }
  })
)
