// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  compose,
  withHandlers,
  withState,
  withProps,
  lifecycle
} from 'recompose'
import { appActionCreators } from '@src/redux/modules/app'
import { recommendationActionCreators } from '@src/redux/modules/recommendation'
import { recommendationConditionActionCreators } from '@src/redux/modules/specifics/recommendationCondition'
import { favoriteActionCreators } from '@src/redux/modules/favorite'
import { withStoreCodeMap } from '@src/redux/modules/store'
import { isValueConformToTag } from '@src/helpers/recommendTagHelper'
import commonHandlers from '@src/commonHandlers'

import RecommendationItemModel from '@src/models/RecommendationItemModel'
import UserFootModel from '@src/models/UserFootModel'
import type { TagKey } from '@src/redux/modules/specifics/recommendationCondition'
import type { DisplayItemWithTagInterface } from '@src/types'

const DEFAULT_DISPLAY_LIMIT = 6

type Props = {
  recommendation: { items: Array<any> },
  recommendationCondition: any,
  userFoot: null | UserFootModel,
  supplementForRecommendation: null | { size: number, width: number, filtering: any },
  storeCodeMap: { [storeCode: string]: { storeName: string } },
  favorite: any,

  appActions: { [string]: Function },
  recommendationActions: { [string]: Function },
  recommendationConditionActions: { [string]: Function },
  favoriteActions: { [string]: Function },

  // 現在の表示件数
  displayLimit: number,
  updateDisplayLimit: number => void,
  // 全アイテムリスト
  allItems: Array<RecommendationItemModel>,
  // 限定件数の表示アイテムリスト
  displayItems: Array<RecommendationItemModel>,
  // もっと表示できるかどうか
  isAbleToShowMore: boolean,
  // おすすめ件数
  totalCount: number, 

  history: Object,
  setBackUrl: any
}

export const enhanceConnection = compose(
  connect(
    state => ({
      recommendation: state.recommendation,
      recommendationCondition: state.specifics.recommendationCondition,
      userFoot: state.user.data.foot,
      supplementForRecommendation: state.user.supplementForRecommendation,
      store: state.store,
      favorite: state.favorite
    }),
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      recommendationActions: bindActionCreators(
        { ...recommendationActionCreators },
        dispatch
      ),
      recommendationConditionActions: bindActionCreators(
        { ...recommendationConditionActionCreators },
        dispatch
      ),
      favoriteActions: bindActionCreators(
        { ...favoriteActionCreators },
        dispatch
      )
    })
  ),

  withStoreCodeMap,

  withState('displayLimit', 'updateDisplayLimit', DEFAULT_DISPLAY_LIMIT),

  withProps(
    ({
      recommendation,
      recommendationCondition,
      displayLimit,
      favorite,
      storeCodeMap,
      appActions
    }: Props) => {
      // おすすめアイテムデータにお気に入りフラグを追加
      const recommendationItems = recommendation.items.map(item => {
        const isFavorite = favorite.ids.includes(item.itemUuid)
        const storeName = storeCodeMap[item.clientCode].storeName
        return new RecommendationItemModel(item, isFavorite, storeName)
      })

      //
      // 選択したおすすめ条件タグによる絞り込み
      //
      let filteredItems = [...recommendationItems]
      Object.keys(recommendationCondition.selectedTags).forEach(
        (tagKey: TagKey) => {
          const tag = recommendationCondition.selectedTags[tagKey]

          // タグが選択されていない -> 絞り込まない
          if (!tag) return

          // タグが選択されている -> 絞り込む
          filteredItems = filteredItems.filter(
            (item: DisplayItemWithTagInterface) => {
              let itemTagKey = tagKey
              if (tagKey == 'colorId') itemTagKey = item[tagKey] ? tagKey : 'ffColorId'
              if (tagKey == 'material') itemTagKey = item[tagKey] ? tagKey : 'ffMaterial'
              const value = item[itemTagKey]
              const isConform = isValueConformToTag(value, tag, tagKey)
              return isConform
            }
          )
        }
      )

      // 表示件数
      let displayItems
      let isAbleToShowMore
      if (filteredItems.length <= displayLimit) {
        displayItems = filteredItems
        isAbleToShowMore = false
      } else {
        displayItems = filteredItems.slice(0, displayLimit)
        isAbleToShowMore = true
      }

      // 算出プロパティを返す
      return {
        displayItems,
        allItems: filteredItems,
        isAbleToShowMore,
        totalCount: filteredItems.length,
        setBackUrl: appActions.setBackUrl
      }
    }
  ),

  withHandlers({
    handleClickTag: ({
      recommendationConditionActions,
      allItems
    }: Props) => async (tagKey: string, itemUuid: number) => {
      const item = allItems.find(item => item.itemUuid === itemUuid)
      if (!item) throw Error('program error')
      let itemTagKey = tagKey
      if (tagKey  == 'colorId' ) itemTagKey = item[tagKey] ? tagKey : 'ffColorId'
      const value = item[itemTagKey]
      recommendationConditionActions.selectTagByValue(tagKey, value)
    },

    handleClickFavIco: ({ favoriteActions, userFoot }: Props) => async (
      item: RecommendationItemModel
    ) => {
      const { itemUuid, isFitted, isFavorite, fit } = item
      if (isFavorite) {
        // お気に入り削除
        try {
          await favoriteActions.remove(itemUuid)
        } catch (err) {
          commonHandlers.handleAsyncLogicError(err)
        }
      } else {
        // お気に入り追加
        if (!userFoot)
          throw Error('この時点ではfootはあるはず@RecommendationPage')
        const footUuid = userFoot.footUuid
        try {
          await favoriteActions.add(itemUuid, isFitted, fit, footUuid)
        } catch (err) {
          commonHandlers.handleAsyncLogicError(err)
        }
      }
    },

    handleClickSeeMore: ({
      recommendation,
      displayLimit,
      updateDisplayLimit
    }: Props) => async () => {
      const next =
        displayLimit + DEFAULT_DISPLAY_LIMIT >= recommendation.items.length
          ? recommendation.items.length
          : displayLimit + DEFAULT_DISPLAY_LIMIT
      updateDisplayLimit(next)
    }
  }),

  lifecycle({
    async componentDidMount() {
      const {
        appActions,
        recommendationActions,
        recommendationConditionActions,
        favoriteActions,
        userFoot,
        supplementForRecommendation
      } = this.props

      appActions.showLoading()
      try {
        // おすすめ一覧クリア
        await recommendationActions.clear()
        // お気に入りアイテム
        await favoriteActions.fetch()

        // 仕様: userFootがない（＝計測歴がない）場合、おすすめ一覧は表示なし（０件で表示）
        if (userFoot && supplementForRecommendation) {
          // おすすめ条件の初期化
          await recommendationConditionActions.resetSelectedTags()
          // おすすめアイテム
          await recommendationActions.find(
            supplementForRecommendation.size,
            supplementForRecommendation.width,
            supplementForRecommendation.filtering
          )
          // 絞り込み用のおすすめ条件タグの生成
          await recommendationConditionActions.init()
        }
      } catch (err) {
        commonHandlers.handleAsyncLogicError(err)
        return
      } finally {
        appActions.hideLoading()
      }
    }
  })
)
