// @flow
import React from 'react'
import styled from 'styled-components'
import { compose, withState } from 'recompose'

import icoChevronDownImg from '@src/assets/img/ico_chevron_down.png'
import icoChevronRightImg from '@src/assets/img/ico_chevron_right.png'

type ExternalProps = {
  shopText: string,
  categoryText: string,
  onClickShopRow: () => void,
  onClickCategoryRow: () => void,
  onClickClearFilterSetting: () => void
}

type Props = ExternalProps & {
  isShown: boolean,
  setIsShown: boolean => void
}

export const enhancePresentation = compose(
  withState('isShown', 'setIsShown', false)
)

export default function Presentation({
  shopText,
  categoryText,
  onClickShopRow,
  onClickCategoryRow,
  onClickClearFilterSetting,
  isShown,
  setIsShown
}: Props) {
  return (
    <FavoriteFilterSetting>
      <Top isShown={isShown} onClick={() => setIsShown(!isShown)}>
        絞り込み
      </Top>
      <Pulldown isShown={isShown}>
        <Ul>
          <Li onClick={onClickShopRow}>
            <Left>ショップ</Left>
            <Right>{shopText}</Right>
          </Li>

          <Li onClick={onClickCategoryRow}>
            <Left>カテゴリー</Left>
            <Right>{categoryText}</Right>
          </Li>
        </Ul>

        <Clear onClick={onClickClearFilterSetting}>クリア</Clear>
      </Pulldown>
    </FavoriteFilterSetting>
  )
}

const FavoriteFilterSetting = styled.div`
  text-align: center;
  margin: 0 auto;
  border: 2px solid #c9c9c9;
  font-size: 13px;
  line-height: 1;
  border-radius: 20px;
`

const Top = styled.div`
  padding: 13px 0;
  position: relative;
  &:after {
    content: '';
    display: inline-block;
    background: url(${icoChevronDownImg}) no-repeat 0 0;
    background-size: 100%;
    width: 12px;
    height: 7px;
    position: absolute;
    right: 15px;
    top: 16px;
    transition-duration: 0.2s;
    transform: ${props =>
      props.isShown ? 'rotateZ(180deg)' : 'rotateZ(0deg)'};
  }
`

const Pulldown = styled.div`
  display: ${props => (props.isShown ? 'block' : 'none')};
`

const Ul = styled.div``

const Li = styled.div`
  display: flex;
  padding: 12px 15px;
  font-size: 12px;
  border-top: 1px solid ${({ theme }) => theme.color.borderGray};
  &:active {
    background: #efefef;
  }
`
const Left = styled.div`
  flex: 1 0;
  min-width: 74px;
  text-align: left;
`
const Right = styled.div`
  flex: 10 1;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: url(${icoChevronRightImg}) no-repeat center right;
  background-size: 7px 12px;
  padding-right: 20px;
`
const Clear = styled.button`
  padding: 13px 0;
  color: #ccc;
  border-top: 1px solid ${({ theme }) => theme.color.borderGray};
  display: block;
  width: 100%;
`
