// @flow
import * as React from 'react'
import styled from 'styled-components'

import Text from '@src/components/atoms/Text'
import Label from '@src/components/atoms/Label'
import theme from '@src/misc/theme'

type Props = {
  date: string,
  storeName: string,
  isNew: boolean
}

export default function({ date, storeName, isNew }: Props) {
  return (
    <Measured>
      <div>
        <Text margin={2} color={theme.color.gray} isInline={true}>
          {date} 計測
        </Text>
        {isNew && <Label>NEW</Label>}
      </div>
      <Text size={13}>{storeName}</Text>
    </Measured>
  )
}

const Measured = styled.div`
  margin-bottom: 10px;
  text-align: center;
`
