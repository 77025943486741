// @flow
import api from '@src/api'
import FormValidation from '@src/helpers/FormValidation'
import commonHandlers from '@src/commonHandlers'

type Hash = { [key: string]: string }

const logicHelper = {
  //
  // パスワードリセット確認メール送信
  //
  sendMail: async function(
    data: Hash,
    setValidationErrorInfo: Hash => void,
    appActions: any
  ): Promise<boolean> {
    // (1) クライアントサイドバリデーション
    const validation = new FormValidation(data)
    validation
      .setRequirements({
        email: true
      })
      .checkRequirements()
    if (validation.hasValidationError) {
      setValidationErrorInfo(validation.validationErrorInfo)
      return false
    }

    // (2) リクエスト
    appActions.showLoading()
    const result = await api.resetPassword(data.email)

    if (result.isSucceeded) return true

    appActions.hideLoading()
    const error =  result.error.responseData 
    if(  error.message ){
      if( error.message.email){
          setValidationErrorInfo({
            email: "指定のメールアドレスのアカウントは存在しません",
          })
      }
    }

    // (3) TODO エラーハンドリング
    /*
    commonHandlers.handleAmplifyErrorWithAnalyzeErrorCode(
      result.error,
      (code: string, PRESET_MESSAGES: Object) => {
        if (code === 'UserNotFoundException') {
          setValidationErrorInfo({
            email: PRESET_MESSAGES.INPUT_ERROR
          })
        } else {
          setValidationErrorInfo({
            email: PRESET_MESSAGES.INPUT_ERROR
          })
        }
      }
    )
    */
    //
    return false
  }
}

export default logicHelper
