// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: any
}

export default function({ children }: Props) {
  return <Block>{children}</Block>
}

const Block = styled.div`
  margin-bottom: 45px;
`
