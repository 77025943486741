import React, { Component } from 'react'
import styled from 'styled-components'

export default class IntroCanvas extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return <Canvas id="canvas-karte" width={100} height={100} />
  }
}

const Canvas = styled.canvas`
  margin: 0 auto;
`
