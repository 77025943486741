// @flow
import React from 'react'
import styled from 'styled-components'
import { pure } from 'recompose'

type Props = {
  pointKey: string,
  onClickPoint: (pointKey: string) => void,
  onSelectTightOrLoose: (
    pointKey: string,
    tightOrLoose: 'tight' | 'loose'
  ) => void,
  isSelected: boolean,
  tightOrLoose: null | 'tight' | 'loose'
}

function FeedbackFootPoint({
  pointKey,
  onClickPoint,
  onSelectTightOrLoose,
  isSelected,
  tightOrLoose
}: Props) {
  let label = ''
  if (tightOrLoose === 'tight') {
    label = 'きつい'
  } else if (tightOrLoose === 'loose') {
    label = 'ゆるい'
  }
  let rotate = '0'
  if (pointKey === 'L1') {
    rotate = '-19deg'
  } else if (pointKey === 'R1') {
    rotate = '19deg'
  }

  return (
    <Wrapper>
      <Colored isRed={!!tightOrLoose} isActive={isSelected} rotate={rotate} />
      <Label onClick={_ => onClickPoint(pointKey)}>{label}</Label>

      {isSelected && (
        <Popup>
          <div onClick={_ => onSelectTightOrLoose(pointKey, 'loose')}>
            ゆるい
          </div>
          <div onClick={_ => onSelectTightOrLoose(pointKey, 'tight')}>
            きつい
          </div>
        </Popup>
      )}
    </Wrapper>
  )
}
export default pure(FeedbackFootPoint)

const RED = 'rgba(243, 0, 56, 0.8)'
const YELLOW = 'rgba(249, 237, 0, 0.6)'
const OPACUE_YELLOW = 'rgba(253, 233, 0, 0.4)'

const Wrapper = styled.div`
  position: relative;
`
const Colored = styled.div`
  position: absolute;
  left: 0;
  rignt: 0;
  width: 100%;
  height: 100%;
  border-radius: 60px;
  background-color: ${props => {
    if (props.isRed) return RED
    if (props.isActive) return YELLOW
    return OPACUE_YELLOW
  }};
  transform: rotate(${props => props.rotate});
`
const Label = styled.div`
  position: absolute;
  left: 0;
  rignt: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`
const Popup = styled.div`
  position: absolute;
  top: -38px;
  left: 50%;
  width: 100px;
  height: 44px;
  margin-left: -50px;
  border: 2px solid ${({ theme }) => theme.color.borderGray};
  display: flex;
  justify-content: space-around;
  background-color: white;
  border-radius: 30px;

  > div {
    font-size: 11px;
    height: 26px;
    padding: 4px 0;
    width: 42px;
    overflow: hidden;
    border-radius: 13px;
    margin: auto auto;
    :active {
      background-color: ${({ theme }) => theme.color.gray};
      color: white;
    }
  }
`
