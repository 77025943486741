// @flow
import * as React from 'react'
import styled from 'styled-components'

import Inner from '@src/components/templates/Inner'

type Props = {
  children: any,
  title: string
}

export default function({ children, title }: Props) {
  return (
    <Aside>
      <Inner>
        {title && <h2>{title}</h2>}
        {children}
      </Inner>
    </Aside>
  )
}

const Aside = styled.div`
  padding: 25px 0;
  border-top: 1px solid ${({ theme }) => theme.color.lightGray};
  h2 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 20px;
  }
`
