// @flow

/* モデル：計測履歴の項目 */
export default class MeasureHistoryItemModel {
  footUuid: string
  storeName: string
  scannedTime: string

  // 追加・加工パラメータ
  displayDate: string

  constructor(raw: any) {
    Object.assign(this, {
      ...raw
    })

    this.displayDate = raw.scannedTime.split('T')[0].replace(/\-/g, '.')
  }
}
