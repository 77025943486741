// @flow
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withState, withHandlers } from 'recompose'
import withRefererableMethods from '@src/helpers/withRefererableMethods'
import { filteringConditionActionCreators } from '@src/redux/modules/specifics/filteringCondition'
import analytics from '@src/analytics'
import constants from '@src/constants'

import StoreModel from '@src/models/StoreModel'

type Refererable = {
  showModal: () => void
}

type Props = {
  modalRef: { current: null | Refererable },
  stores: Array<StoreModel>,
  filteringCondition: any,
  filteringConditionActions: any,

  isShown: boolean,
  setIsShown: boolean => void,
  checkBoxes: Array<{ value: string, label: string, defaultChecked: boolean }>,
  formRef: { current: null | HTMLFormElement }
}

export const enhanceConnection = compose(
  connect(
    state => ({
      stores: state.store.stores,
      filteringCondition: state.specifics.filteringCondition
    }),
    dispatch => ({
      filteringConditionActions: bindActionCreators(
        { ...filteringConditionActionCreators },
        dispatch
      )
    })
  ),

  withState('isShown', 'setIsShown', false),
  withState('formRef', 'setFormRef', React.createRef()),
  withState('checkBoxes', 'setCheckBoxes', []),

  withHandlers({
    handleCancel: ({ setIsShown }: Props) => () => {
      setIsShown(false)
    },

    handleSave: ({
      stores,
      filteringConditionActions,
      formRef,
      setIsShown
    }: Props) => async () => {
      const selectedStores = []
      Array.prototype.forEach.call(
        formRef.current,
        (input: HTMLInputElement, i: number) => {
          if (input.checked) selectedStores.push({ ...stores[i] })
        }
      )

      await filteringConditionActions.updateSelectedShop(selectedStores)
      setIsShown(false)
    }
  }),

  withRefererableMethods('modalRef', {
    showModal() {
      // ショップ選択モーダルを開く
      const {
        stores,
        setCheckBoxes,
        filteringCondition,
        setIsShown
      } = this.props

      // アクセス解析 送信
      const pageId = constants.PSEUDO_PAGE_PATH_MODAL_FILTERING_SHOP
      analytics.sendAnalyticsData(pageId)

      // お気に入りの店舗のみをチェックリストとして表示する
      const checkBoxes = stores
        .filter(store => store.favoriteFlag)
        .map(store => {
          return {
            value: store.storeId.toString(),
            label: store.storeName,
            defaultChecked: filteringCondition.shop.selectedIds.includes(
              store.storeId
            )
          }
        })
      setCheckBoxes(checkBoxes)

      setIsShown(true)
    }
  })
)
