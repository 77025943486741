// @flow
import React from 'react'
import styled from 'styled-components'

import Text from '@src/components/atoms/Text'
import EditLink from '@src/components/atoms/EditLink'

import { FormConstants } from '@src/constants'
const inputBlockTitles = FormConstants.inputBlockTitles

type Props = {
  propertyName: string,
  value: string | number,
  disableEdit: boolean
}

export default function({ propertyName, value, disableEdit }: Props) {
  return (
    <AccountProperty>
      <Text size={11}>{inputBlockTitles[propertyName]}</Text>
      <PropertyText size={14}>
        {String(value)
          .split('\n')
          .map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
      </PropertyText>
      {!disableEdit && <StyledEditLink propertyName={propertyName} />}
    </AccountProperty>
  )
}

const AccountProperty = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.color.lightGray};
  padding: 15px 0;
`
const StyledEditLink = styled(EditLink)`
  position: absolute;
  right: 0;
  top: 15px;
`
const PropertyText = styled(Text)`
  padding-left: 15px;
`
