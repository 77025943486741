// @flow
import React, { Fragment } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { compose, lifecycle } from 'recompose'

import { appActionCreators } from '@src/redux/modules/app'
import { loginProcessActionCreators } from '@src/redux/modules/specifics/loginProcess'
import { toastActionCreators } from '@src/redux/modules/toast'
import baseStyles from '@src/misc/baseStyles'
import theme from '@src/misc/theme'
import Loading from '@src/components/atoms/Loading'
import ErrorDialog from '@src/components/molecules/ErrorDialog'
import Toast from '@src/components/organisms/Toast'

import Main from './Main'

type Props = {
  app: Object
}

function Root({ app }: Props) {
  const { initialized, loading, errorDialogData } = app
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyle />
        <BrowserRouter>{initialized && <Main />}</BrowserRouter>
        <Loading loading={loading} />
        <ErrorDialog errorDialogData={errorDialogData} />
        <Toast />
      </Fragment>
    </ThemeProvider>
  )
}

const mapStateToProps = state => ({
  app: state.app
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActionCreators }, dispatch),
  loginProcessActions: bindActionCreators(
    { ...loginProcessActionCreators },
    dispatch
  ),
  toastActions: bindActionCreators({ ...toastActionCreators }, dispatch)
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    async componentDidMount() {
      const { appActions, loginProcessActions, toastActions } = this.props

      // 種々の初期化
      toastActions.init()

      // 初期データ取得
      try {
        // 自動ログインを実行（ログイン失敗しても正常系です）
        await loginProcessActions.autoLogin()
        appActions.hideLoading() // 初期ローディング解除
        appActions.initialDataSuccess()
      } catch (error) {
        appActions.hideLoading() // 初期ローディング解除
        appActions.initialDataFailure()
        appActions.showErrorDialog({
          control: 'only-reload',
          title: 'アプリケーションを初期化できませんでした',
          error: error
        })
      }
    }
  })
)(Root)

// グローバルのCSS設定
const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  ${baseStyles}
`
