import shoesContents from './shoesContents'

class Webgl {
  canvas: Object
  shoes: any

  constructor() {
    this.canvas = {}
    // 足カルテ
    this.shoes = undefined
  }

  // 足カルテの表示
  showShoes(opt: { el: HTMLElement, pcdFileUrl: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.shoes != null) {
        return reject()
      }
      // console.log('足カルテの表示', opt.pcdFileUrl)
    // this.intro.opt.el.style.display = 'none'
      this.shoes = new shoesContents({
        el:opt.el
      })

      // pcd or plyを判定

      const data = opt.pcdFileUrl
      this.shoes.show({
        data:data,
        onComplete:() => {
          resolve()
        }
      })
    })
  }

  // 足カルテ消す
  hideShoes(opt: any): Promise<any> {
    // アニメーション必要かもだけど一旦普通に消す
    return new Promise((resolve, reject) => {
      this.shoes.hide({
        onComplete:() => {
          this.shoes.dispose()
          this.shoes = null
          resolve()
        }
      })
    })
  }

}

export default new Webgl()
