// @flow
import React from 'react'

import DefaultPageLayout from '@src/components/templates/DefaultPageLayout'
import Section from '@src/components/templates/Section'
import Inner from '@src/components/templates/Inner'
import PageTitle from '@src/components/molecules/PageTitle'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'
import FormRow from '@src/components/molecules/FormRow'
import Input from '@src/components/atoms/forms/Input'

type ExternalProps = {
  formData: {
    email: string
  },
  validationErrorInfo: { [errorInfoKey: string]: string },
  sendStatus: boolean,
  
  setFormValue: (key: string, value: string) => void,
  handleClickSend: () => void
}
type Props = ExternalProps & {
  match: any
}

export default function Presentation({
  formData,
  validationErrorInfo,
  setFormValue,
  handleClickSend,
  sendStatus,
  match
}: Props) {
  return (
    <DefaultPageLayout>
      <Section>
        { !sendStatus && 
        <Inner>
          <PageTitle title="パスワードの再設定" />
          { match.params.orguser && 
          <Text textAlign={'center'} color={'red'} size={18} margin={30}>
            弊社、認証システム変更に伴い、お手数ですが、パスワードを再設定をお願いいたします。
          </Text>
          }
          <Text textAlign={'center'} size={14} margin={30}>
            パスワードを再設定します
            <br />
            登録されたメールアドレスを入力して、
            <br />
            送信してください。
          </Text>
          <FormRow
            label={'登録したメールアドレス'}
            errorMessage={validationErrorInfo.email}
          >
            <Input
              type="email"
              placeholder="メールアドレス"
              value={formData.email}
              onChange={e => {
                setFormValue('email', e.target.value)
              }}
            />
          </FormRow>
          <Button onClick={handleClickSend} type={'primary'}>
            送信
          </Button>
        </Inner>
        }
        { sendStatus && 
        <Inner>
          <PageTitle title="パスワードの再設定" />
          <Text textAlign={'center'} size={14} margin={30}>
            登録いただいているメールアドレスに、パスワードを再設定の情報を送信しました。
            <br />
            メールに記載されているURLにアクセスしてして、再設定をお願いします。
          </Text>
        </Inner>
        }
      </Section>
    </DefaultPageLayout>
  )
}
