// @flow
import produce from 'immer'
import api from '@src/api'
import { createActionThunk } from 'redux-thunk-actions'

/*==================================
* Actions
==================================*/
const THUNK = {}
const REDUCER = {}

// Public
THUNK.FIND_ITEMS = 'recommendation/THUNK.FIND_ITEMS'
THUNK.CREAR_ITEMS = 'recommendation/THUNK.CREAR_ITEMS'

// Private
REDUCER.SET_ITEMS = 'recommendation/REDUCER.SET_ITEMS'

/*==================================
* Action Creators
==================================*/
export const recommendationActionCreators = {
  find: createActionThunk(
    THUNK.FIND_ITEMS,
    async (
      size: number,
      width: number,
      filtering: any,
      { dispatch, getState }
    ): Promise<void> => {
      // FIXME: テスト環境向け config/ にデバッグ用におすすめ取得用の固定値を記述できる
      //        固定値でなくても機能するようになったらこれは不要になる
      const { __debug__ } = (process.env.CONFIG: any)
      const s =
        __debug__ && __debug__.recommendSize ? __debug__.recommendSize : size
      const w =
        __debug__ && __debug__.recommendWidth ? __debug__.recommendWidth : width

      // FIXME: できれば、ここでstoreストアを参照したくない。modules同士での相互参照避けたい

      // ユーザのお気に入り店舗のCodeをリストアップ
      const stores = getState().store.stores
      const favoriteStoreCodeList = stores
        .filter(store => store.favoriteFlag)
        .map(store => store.storeCode)

      // お気に入り店舗ごとのおすすめアイテム取得
      const rawItems = []
      for (let i = 0; i < favoriteStoreCodeList.length; i += 1) {
        const storeCode = favoriteStoreCodeList[i]
        const recommendationResult = await api.findRecommendation(
          storeCode,
          s,
          w,
          filtering
        )
        rawItems.push(...recommendationResult.data)
      }

      // 格納
      dispatch({
        type: REDUCER.SET_ITEMS,
        payload: {
          items: rawItems
        }
      })
    }
  ),

  clear: createActionThunk(
    THUNK.CREAR_ITEMS,
    async ({ dispatch }): Promise<void> => {
      dispatch({
        type: REDUCER.SET_ITEMS,
        payload: {
          items: []
        }
      })
    }
  )
}

/*==================================
Initial State
==================================*/
type State = {
  items: Array<any>
}

const initialState = {
  items: []
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.SET_ITEMS: {
        draft.items = action.payload.items
        return
      }
    }
  })
}

/*==================================
* Selector
==================================*/
