// @flow
import React from 'react'

import DefaultPageLayout from '@src/components/templates/DefaultPageLayout'
import Section from '@src/components/templates/Section'
import Inner from '@src/components/templates/Inner'
import PageTitle from '@src/components/molecules/PageTitle'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'
import FormRow from '@src/components/molecules/FormRow'
import Input from '@src/components/atoms/forms/Input'

type ExternalProps = {
  formData: {
    email: string,
    newPassword: string,
    newPasswordRepeated: string
  },
  validationErrorInfo: {
    [errorInfoKey: string]: string
  },
  setFormValue: (key: string, value: string) => void,
  handleClickSubmit: () => void
}
type Props = ExternalProps

export default function Presentation({
  formData,
  validationErrorInfo,
  setFormValue,
  handleClickSubmit
}: Props) {
  return (
    <DefaultPageLayout>
      <Section>
        <Inner>
          <PageTitle title="パスワードの再設定" />
          <Text textAlign={'center'} size={14} margin={30}>
            メールアドレスに送信された確認コードと
            <br />
            ご希望の新しいパスワードを
            <br />
            入力してください。
          </Text>
          <FormRow
            label={'登録したメールアドレス'}
            errorMessage={validationErrorInfo.email}
          >
            <Input
              type="email"
              placeholder="メールアドレス"
              value={formData.email}
              onChange={e => {
                setFormValue('email', e.target.value)
              }}
            />
          </FormRow>

          <FormRow
            label={'新しいパスワード'}
            errorMessage={validationErrorInfo.newPassword}
          >
            <Input
              type="password"
              placeholder="半角英数字8文字以上で入力"
              value={formData.newPassword}
              onChange={e => {
                setFormValue('newPassword', e.target.value)
              }}
            />
            <Input
              type="password"
              placeholder="パスワード確認"
              value={formData.newPasswordRepeated}
              onChange={e => {
                setFormValue('newPasswordRepeated', e.target.value)
              }}
            />
          </FormRow>

          <Button onClick={handleClickSubmit} type={'primary'}>
            パスワードをリセット
          </Button>
        </Inner>
      </Section>
    </DefaultPageLayout>
  )
}
