// @flow
import React from 'react'
//import styled from 'styled-components'
import { compose, withStateHandlers } from 'recompose'

import PrivatePageLayout from '@src/components/templates/PrivatePageLayout'
import Inner from '@src/components/templates/Inner'
import Row from '@src/components/templates/Row'
import Count from '@src/components/atoms/Count'
import ArrowLink from '@src/components/atoms/ArrowLink'
import PageTitle from '@src/components/molecules/PageTitle'
import RecommendMenu from '@src/components/organisms/RecommendMenu'
import RecommendationList from '@src/components/organisms/RecommendationList'
import Button from '@src/components/atoms/Button'
import ModalShoesDetail from '@src/components/organisms/ModalShoesDetail'

import type { DisplayItemInterface } from '@src/types'

type ExternalProps = {
  allItems: Array<DisplayItemInterface>,
  displayItems: Array<DisplayItemInterface>,
  isAbleToShowMore: boolean,
  totalCount: number,
  setBackUrl: any,

  handleClickFavIco: (item: DisplayItemInterface) => void,
  handleClickTag: (tagKey: string, itemUuid: number) => void,
  handleClickSeeMore: () => void
}
type Props = ExternalProps & {
  shoesDetailModalRef: { current: null | any },
  history: Object
}

export const enhancePresentation = compose(
  withStateHandlers(
    {
      shoesDetailModalRef: React.createRef()
    },
    {}
  )
)

export default function Presentation({
  displayItems,
  isAbleToShowMore,
  totalCount,
  handleClickTag,
  handleClickFavIco,
  handleClickSeeMore,
  history,
  setBackUrl,
  shoesDetailModalRef
}: Props) {
  return (
    <PrivatePageLayout>
      <Inner>
        <PageTitle title="おすすめ" />
      </Inner>

      <RecommendMenu />
      <br />
      <br />

      <Inner>
        <Row>
          <Count number={totalCount} />
          <ArrowLink
            path={'/account/edit/stores'}
            text={'お気に入り店舗設定'}
          />
        </Row>

        <RecommendationList
          recommendations={displayItems}
          shouldShowFavoriteIcon={true}
          onClickShoes={(itemId: number) => {
            setBackUrl()
            history.push(`/item/${itemId}`)
            /*
            if (!shoesDetailModalRef.current) return
            shoesDetailModalRef.current.showModal(item.itemUuid)
            */
          }}
          onClickFavIco={handleClickFavIco}
        />
        {isAbleToShowMore && (
          <Button type={'secondary'} onClick={handleClickSeeMore}>
            もっと見る
          </Button>
        )}
      </Inner>

      <ModalShoesDetail
        mode={'recommendation-detail'}
        modalRef={shoesDetailModalRef}
        items={displayItems}
        onClickFavIco={handleClickFavIco}
        onClickTag={handleClickTag}
      />
    </PrivatePageLayout>
  )
}
