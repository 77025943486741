// @flow
import React from 'react'
import styled from 'styled-components'

import Table from '@src/components/atoms/Table'

import footIllustImg from '@src/assets/img/img_foot_illust.png'
import number1Img from '@src/assets/img/number_1.png'
import number2Img from '@src/assets/img/number_2.png'
import number3Img from '@src/assets/img/number_3.png'
import number4Img from '@src/assets/img/number_4.png'
import number5Img from '@src/assets/img/number_5.png'
import number6Img from '@src/assets/img/number_6.png'
import number7Img from '@src/assets/img/number_7.png'
import number8Img from '@src/assets/img/number_8.png'
import number9Img from '@src/assets/img/number_9.png'
//import number10Img from '@src/assets/img/number_10.png'

type Props = {
  measuredValues: Object
}

export default function({ measuredValues }: Props) {
  return (
    <FeetPartsSize>
      {/*
      <div className={'align-center'}>
        <img src={footIllustImg} alt="" width={204} />
      </div>
      */}
      <Table>
        <thead>
          <tr>
            <td />
            <td className={'align-center'} width="50">
              左足
            </td>
            <td className={'align-center'} width="50">
              右足
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className={'align-left'}>
            {/*
              <img src={number1Img} alt="1" width={14} />
            */}
              &nbsp; 足長
            </th>
            <td className={'align-right'}>
              {measuredValues.left.footLength}
              cm
            </td>
            <td className={'align-right'}>
              {measuredValues.right.footLength}
              cm
            </td>
          </tr>
          <tr>
            <th className={'align-left'}>
            {/*
              <img src={number2Img} alt="2" width={14} />
            */}
              &nbsp; 足幅
            </th>
            <td className={'align-right'}>
              {measuredValues.left.footWidth}
              cm
            </td>
            <td className={'align-right'}>
              {measuredValues.right.footWidth}
              cm
            </td>
          </tr>
          {/*
          <tr>
            <th className={'align-left'}>
              <img src={number3Img} alt="3" width={14} />
              &nbsp; 足囲
            </th>
            <td className={'align-right'}>
              {measuredValues.left.footCircumference}
              cm
            </td>
            <td className={'align-right'}>
              {measuredValues.right.footCircumference}
              cm
            </td>
          </tr>
          <tr>
            <th className={'align-left'}>
              <img src={number4Img} alt="4" width={14} />
              &nbsp; アーチの長さ
            </th>
            <td className={'align-right'}>
              {measuredValues.left.archLength}
              cm
            </td>
            <td className={'align-right'}>
              {measuredValues.right.archLength}
              cm
            </td>
          </tr>
          <tr>
            <th className={'align-left'}>
              <img src={number5Img} alt="5" width={14} />
              &nbsp; 指の長さ
            </th>
            <td className={'align-right'}>
              {measuredValues.left.fingerLength}
              cm
            </td>
            <td className={'align-right'}>
              {measuredValues.right.fingerLength}
              cm
            </td>
          </tr>
          <tr>
            <th className={'align-left'}>
              <img src={number6Img} alt="6" width={14} />
              &nbsp; 踵から親指の付け根
            </th>
            <td className={'align-right'}>
              {measuredValues.left.lengthHeelToThumbBigMound}
              cm
            </td>
            <td className={'align-right'}>
              {measuredValues.right.lengthHeelToThumbBigMound}
              cm
            </td>
          </tr>
          <tr>
            <th className={'align-left'}>
              <img src={number7Img} alt="7" width={14} />
              &nbsp; 踵から小指の付け根
            </th>
            <td className={'align-right'}>
              {measuredValues.left.lengthHeelToPinkySmallMound}
              cm
            </td>
            <td className={'align-right'}>
              {measuredValues.right.lengthHeelToPinkySmallMound}
              cm
            </td>
          </tr>
          */}
          <tr>
            <th className={'align-left'}>
            {/*
              <img src={number8Img} alt="8" width={14} />
            */}
              &nbsp; 踵幅
            </th>
            <td className={'align-right'}>
              {measuredValues.left.ankleWidth}
              cm
            </td>
            <td className={'align-right'}>
              {measuredValues.right.ankleWidth}
              cm
            </td>
          </tr>
          <tr>
            <th className={'align-left'}>
            {/*
              <img src={number9Img} alt="9" width={14} />
            */}
              &nbsp; 甲高
            </th>
            <td className={'align-right'}>
              {measuredValues.left.instepHeight}
              cm
            </td>
            <td className={'align-right'}>
              {measuredValues.right.instepHeight}
              cm
            </td>
          </tr>
          {/*
          <tr>
            <th className={'align-left'}>
              <img src={number10Img} alt="10" width={14} />
              &nbsp; 踵の傾き
            </th>
            <td className={'align-right'}>{measuredValues.left.ankleAngle}°</td>
            <td className={'align-right'}>
              {measuredValues.right.ankleAngle}°
            </td>
          </tr>
          */}
        </tbody>
      </Table>
    </FeetPartsSize>
  )
}

const FeetPartsSize = styled.div`
  text-align: center;
`
