import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import BaseRoute from './BaseRoute'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose } from 'recompose'
import { withLoggedIn } from '@src/redux/modules/user'
import { toastActionCreators } from '@src/redux/modules/toast'

type Props = {
  location: any,
  isLoginCompleted: boolean,
  toastAction: any,
  showLoginNeededMessage: () => void
}

type State = {
  shouldRedirect: boolean,
  redirectUrl: ?string
}

class PrivateRoute extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      // ログインページへリダイレクトするかどうか
      shouldRedirectToLoginPage: false,
      // ログインページへリダイレクトされた後にログイン実行した時の戻り先URL
      redirectUrl: null
    }
  }

  static getDerivedStateFromProps({
    location,
    isLoginCompleted,
    toastAction
  }: Props) {
    // ログインしているか（ユーザ情報など取得できているかどうか）をチェック
    if (!isLoginCompleted) {
      toastAction.add('info', 'ログインが必要です')
      return {
        redirectUrl: location.pathname,
        shouldRedirectToLoginPage: true
      }
    } else {
      return {
        shouldRedirectToLoginPage: false
      }
    }
  }

  render() {
    const { shouldRedirectToLoginPage, redirectUrl } = this.state

    if (shouldRedirectToLoginPage) {
      // 仕様: マイページの場合だけはログインページにはリダイレクトさせずに新規登録ページに飛ばす
      //const redirectTo = redirectUrl === '/' ? '/signup' : '/login'
      const redirectTo = '/login'
      return (
        <Redirect
          to={{
            pathname: redirectTo,
            state: {
              isGuardedCauseNeedLoggedIn: true,
              redirectUrl: redirectUrl
            }
          }}
        />
      )
    } else {
      return <BaseRoute {...this.props} />
    }
  }
}

export default compose(
  connect(
    state => ({
      user: state.user
    }),
    dispatch => ({
      toastAction: bindActionCreators({ ...toastActionCreators }, dispatch)
    })
  ),
  withLoggedIn
)(PrivateRoute)
