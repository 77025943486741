// @flow
import { createAction } from 'redux-actions'
// import { createActionThunk } from 'redux-thunk-actions'
import produce from 'immer'

export type ErrorDialogData = {
  control: 'only-reload' | string,
  title: string,
  error?: Error
}

/*==================================
* Actions
==================================*/
//const THUNK = {}
const REDUCER = {}

// Public
REDUCER.SHOW_LOADING = 'app/REDUCER.SHOW_LOADING'
REDUCER.HIDE_LOADING = 'app/REDUCER.HIDE_LOADING'
REDUCER.INITIAL_DATA_SUCCESS = 'app/REDUCER.INITIAL_DATA_SUCCESS'
REDUCER.INITIAL_DATA_FAILURE = 'app/REDUCER.INITIAL_DATA_FAILURE'
REDUCER.SET_FOOT_UUID_INFLOWED_FROM_QR_CODE =
  'app/REDUCER.SET_FOOT_UUID_INFLOWED_FROM_QR_CODE'
REDUCER.DELETE_FOOT_UUID_INFLOWED_FROM_QR_CODE =
  'app/REDUCER.DELETE_FOOT_UUID_INFLOWED_FROM_QR_CODE'
REDUCER.SHOW_ERROR_DIALOG = 'app/REDUCER.SHOW_ERROR_DIALOG'
REDUCER.BACKURL = 'app/REDUCER.BACKURL'

// Private
//

/*==================================
* Action Creators
==================================*/
export const appActionCreators = {
  showLoading: createAction(REDUCER.SHOW_LOADING),
  hideLoading: createAction(REDUCER.HIDE_LOADING),
  showErrorDialog: createAction(REDUCER.SHOW_ERROR_DIALOG),
  initialDataSuccess: createAction(REDUCER.INITIAL_DATA_SUCCESS),
  initialDataFailure: createAction(REDUCER.INITIAL_DATA_FAILURE),
  setBackUrl: createAction(REDUCER.BACKURL),
  setFootUuidInflowedFromQrCode: createAction(
    REDUCER.SET_FOOT_UUID_INFLOWED_FROM_QR_CODE
  ),
  deleteFootUuidInflowedFromQrCode: createAction(
    REDUCER.DELETE_FOOT_UUID_INFLOWED_FROM_QR_CODE
  )
}

/*==================================
Initial State
==================================*/
type State = {
  loading: boolean,
  initialized: boolean,
  errorDialogData: ?ErrorDialogData,
  footUuidInflowedFromQrCode: ?string,
  backUrl: ?string
}

// QRからのfoot_uuid保管(暫定)
const json = localStorage.getItem('flicfitData') || '{}'
const initialFootUuidInflowedFromQrCode =
  JSON.parse(json).footUuidInflowedFromQrCode || null

const initialState = {
  loading: true,
  initialized: false,
  errorDialogData: null,
  footUuidInflowedFromQrCode: initialFootUuidInflowedFromQrCode,
  backUrl: null
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.SHOW_LOADING: {
        draft.loading = true
        break
      }
      case REDUCER.HIDE_LOADING: {
        draft.loading = false
        break
      }
      case REDUCER.INITIAL_DATA_SUCCESS: {
        draft.initialized = true
        break
      }
      case REDUCER.INITIAL_DATA_FAILURE: {
        draft.initialized = false
        break
      }
      case REDUCER.BACKURL: {
        draft.backUrl = location.pathname
        break
      }
      case REDUCER.SHOW_ERROR_DIALOG: {
        draft.errorDialogData = action.payload
        break
      }
      case REDUCER.SET_FOOT_UUID_INFLOWED_FROM_QR_CODE: {
        // QRからのfoot_uuid保管
        const json = localStorage.getItem('flicfitData') || '{}'
        const flicfitData = JSON.parse(json)
        flicfitData.footUuidInflowedFromQrCode = action.payload.footUuid
        localStorage.setItem('flicfitData', JSON.stringify(flicfitData))

        draft.footUuidInflowedFromQrCode = action.payload.footUuid
        break
      }
      case REDUCER.DELETE_FOOT_UUID_INFLOWED_FROM_QR_CODE: {
        // QRからのfoot_uuid削除
        localStorage.removeItem('flicfitData')
        draft.footUuidInflowedFromQrCode = null
        break
      }
    }
  })
}
