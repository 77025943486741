// @flow
import { createPictureUrl } from '@src/helpers/pictureUrl'
import type {
  DisplayItemInterface,
  DisplayItemWithTagInterface
} from '@src/types'

/* モデル：おすすめアイテム */
export default class RecommendationItemModel
  implements DisplayItemInterface, DisplayItemWithTagInterface {
  $key: string
  $value: any

  // 注: お気に入りアイテムとはパラメータキーが異なるものもあります
  brandName: string
  categoryName: string
  clientCode: string
  color: string
  colorId: number
  ffMakerSize: number
  material: string
  fit: 'fit' | 'loose' | 'tight' | 'outside'
  heelHeight: number
  heelTypeName: string
  itemId: number
  itemUuid: number
  jisSize: number
  jisWidth: string
  makerColor: string
  makerMaterial: string
  makerSize: string
  makerWidth: string
  patternName: string
  pictureUri: { '0': ?string }
  price: number
  productName: string
  productNumber: string
  shoeTypeName: string
  shoelace: boolean
  strap: boolean
  targetName: string
  toe: string
  unit: string
  ecUrl: ?string

  // 追加・加工パラメータ
  pictureUrl: '' | string
  pictureUrls: Array<string>
  isFavorite: boolean
  storeName: string
  isFitted: boolean

  constructor(raw: any, isFavorite?: boolean, storeName?: string) {
    Object.assign(this, {
      ...raw
    })
    if (raw.ffColorId) this.colorId = raw.ffColorId
    if (raw.ffMaterial) this.material = raw.ffMaterial

    this.pictureUrl = raw.pictureUri['0']
      ? createPictureUrl(raw.pictureUri['0'], true)
      : ''

    // 画像リスト = お気に入りアイテムと同じプロパティ名に揃え、使いやすいように配列にする
    this.pictureUrls = new Array(100)
      .fill(1)
      .map((_, i) => raw.pictureUri[i.toString()] || null)
      .filter((url: any) => url !== null)
      .map((url: ?string) => (url ? createPictureUrl(url, true) : ''))

    this.isFavorite = isFavorite || false
    this.storeName = storeName || '（店舗不明）'

    // 試着アイテムかどうか -> 判定要素がないので一律でfalseと考える
    this.isFitted = false
  }
}
