// @flow
import { createPictureUrl } from '@src/helpers/pictureUrl'
import type { DisplayFittingItemInterface } from '@src/types'

/* モデル：お気に入り（または試着）アイテム */
export default class FittingItemModel implements DisplayFittingItemInterface {
  $key: string
  $value: any
  // パラメータについて
  //
  // itemId: ただの商品ID
  //   同じ商品でも取扱店が異なる場合、itemIdは同じになるがitemUuidは違ってくる
  //   同じ商品でも別のお店のものは別項目として扱うお気に入り項目の識別子にはならない
  // itemUuid: お気に入り項目のID
  //   お気に入りリストなどの項目の識別子としてはこっちしか使えない
  //
  brandName: string
  categoryName: string
  ffColor: string
  ffMaterial: string
  color: ?string
  material: ?string
  heelHeight: number
  heelTypeName: string
  itemUuid: number
  makerColor: string
  makerMaterial: string
  makerSize: string
  makerWidth: string
  patternName: string
  pictures: { '0': ?string }
  price: number
  productName: string
  productNumber: string
  rcmdFlag: boolean
  shoeTypeName: string
  shoelace: boolean
  storeName: string
  strap: boolean
  targetName: string
  toeName: string
  unit: string
  ecUrl: ?string
  pictureUrl: string
  pictureUrls: Array<string>
  review : string
  reviewId : number
  reviewcreatedTime: string

  constructor(raw: any) {
    Object.assign(this, {
      ...raw
    })

    this.itemUuid = raw.id
    console.log("FittingModel -> ", raw)

    // {APIのURL}/{imageのパス(APIから取得できるパス)}?width=268&trim=contain
    this.pictureUrl = raw.pictures['0']
      ? createPictureUrl(raw.pictures['0'], true)
      : ''

    this.reviewcreatedTime = raw.reviewcreatedTime.split('T')[0].replace(/\-/g, '.')

    // 画像リスト = おすすめアイテムと同じプロパティ名に揃え、使いやすいように配列にする
    this.pictureUrls = new Array(100)
      .fill(1)
      .map((_, i) => raw.pictures[i.toString()] || null)
      .filter((url: any) => url !== null)
      .map((url: ?string) => (url ? createPictureUrl(url, true) : ''))
  }
}
