// @flow
import React from 'react'

import DefaultPageLayout from '@src/components/templates/DefaultPageLayout'
import Inner from '@src/components/templates/Inner'
import PageTitle from '@src/components/molecules/PageTitle'
import FormRow from '@src/components/molecules/FormRow'
import HalfButtons from '@src/components/molecules/HalfButtons'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'

//import theme from '@src/misc/theme'

type Props = {
  handleClickWithdraw: () => void,
  handleClickCancel: () => void
}

export default function Presentation({
  handleClickWithdraw,
  handleClickCancel
}: Props) {
  return (
    <DefaultPageLayout>
      <React.Fragment>
        <Inner>
          <PageTitle title="アカウント設定" />
          <FormRow label={'退会'} errorMessage={''}>
            <Text textAlign={'center'} size={14} margin={30}>
              本当に退会してよろしいですか？
            </Text>
          </FormRow>
          <HalfButtons>
            <Button type={'secondary'} onClick={handleClickCancel}>
              キャンセル
            </Button>
            <Button type={'primary'} onClick={handleClickWithdraw}>
              退会する
            </Button>
          </HalfButtons>
        </Inner>
      </React.Fragment>
    </DefaultPageLayout>
  )
}
