// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: any,
  isBorder?: boolean,
  paddingTop?: number,
  paddingBottom?: number
}

export default function({ children, ...rest }: Props) {
  return <Section {...rest}>{children}</Section>
}

const Section = styled.section`
  border-bottom: ${({ isBorder, theme }) =>
    isBorder ? `1px solid ${theme.color.lightGray}` : 'none'};
  padding-top: ${({ paddingTop }) =>
    paddingTop != undefined ? `${paddingTop}px` : '40px'};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom != undefined ? `${paddingBottom}px` : '35px'};
`
