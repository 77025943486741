// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { appActionCreators } from '@src/redux/modules/app'
import { userActionCreators } from '@src/redux/modules/user'
import { loginProcessActionCreators } from '@src/redux/modules/specifics/loginProcess'
import commonHandlers from '@src/commonHandlers'

type LoginErrorInfo = {
  message: string
}

interface Props {
  appActions: { [string]: Function };
  loginProcessActions: { [string]: Function };
  loginErrorInfo: LoginErrorInfo;
  updateLoginErrorInfo: LoginErrorInfo => any;
}

export const enhanceConnection = compose(
  connect(
    null,
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      loginProcessActions: bindActionCreators(
        { ...loginProcessActionCreators },
        dispatch
      )
    })
  ),

  withState('loginErrorInfo', 'updateLoginErrorInfo', { message: '' }),

  lifecycle({
    async componentDidMount() {
      const {
        history,
        appActions,
        loginProcessActions,
        updateLoginErrorInfo
      } = this.props

      updateLoginErrorInfo({
        message: ''
      })

      try {

        const loginResult = await loginProcessActions.loginByLineCallback()
        if (!loginResult.isSucceeded) {
          updateLoginErrorInfo({
            message: 'ログインに失敗しました'
          })
        }
      } catch (err) {
        console.log(err)
        appActionCreators.hideLoading()
        commonHandlers.handleAsyncLogicError(err)
        history.push('/login')
        return
      } finally {
        appActionCreators.hideLoading()
      }
      history.push('/')

    }
  })
)
