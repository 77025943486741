// @flow
import * as React from 'react'
import styled from 'styled-components'

import icoCheckImg from '@src/assets/img/ico_check.png'
import icoCheckSelectedImg from '@src/assets/img/ico_check_selected.png'

type Props = {
  isFavorite: boolean,
  onClick: (e: any) => void
}

export default function({ isFavorite, onClick, ...rest }: Props) {
  return (
    <CheckButton onClick={onClick} {...rest}>
      <img
        src={isFavorite ? icoCheckSelectedImg : icoCheckImg}
        alt=""
        width={24}
      />
    </CheckButton>
  )
}

const CheckButton = styled.i``
