// @flow
import produce from 'immer'
import api from '@src/api'
import { createActionThunk } from 'redux-thunk-actions'
import { normalize, schema }  from 'normalizr'

/*==================================
* Actions
==================================*/
const THUNK = {}
const REDUCER = {}

// Public
THUNK.FETCH = 'fitting/THUNK.FETCH'

// Private
REDUCER.SET = 'fitting/REDUCER.SET'

/*==================================
* Action Creators
==================================*/
export const fittingActionCreators = {
  fetch: createActionThunk(
    THUNK.FETCH,
    async ({ dispatch }): Promise<void> => {
      const result = await api.getFittingItem()
      const items = result.data.map ( e => {
        let tmpItem = {...e.item}
        tmpItem['storeName'] = e.clientInfo.storeName
        tmpItem['pictures'] = {}
        e.item.mesh.pictures.forEach((e, idx) => {
          tmpItem['pictures'][`${idx}`] = `/shoe/${e.key}`
        })
        tmpItem['reviewId'] = e.id
        tmpItem['reviewcreatedTime'] = e.createdTime.split('T')[0].replace(/\-/g, '.')
        console.log(" @@@ @@@ @@ ", tmpItem)
        return tmpItem
      })
      let reviews = {}
      let footSurvey = {}
      result.data.forEach ( e => {
        const rSchema = new schema.Entity('shoeReviews', {} , {idAttribute: 'questionId' })
        const rSchemaArray = new schema.Array(rSchema)
        const normalizedReviews = normalize(e.content.shoeReview, rSchemaArray)
        reviews[e.id] =  normalizedReviews.entities.shoeReviews

        const fsSchema = new schema.Entity('footSurvey', {} , {idAttribute: 'questionId' })
        const fsSchemaArray = new schema.Array(fsSchema)
        const normalizedFootSurvey = normalize(e.footSurvey, fsSchemaArray)
        footSurvey[e.id] =  normalizedFootSurvey.entities.footSurvey
      })




      dispatch({
        type: REDUCER.SET,
        payload: {
          reviews:  items,
          totalCount: result.totalCount,
          footSurvey: footSurvey,
          shoeReviews:reviews 
        }
      })
    }
  ),
}



/*==================================
Initial State
==================================*/
type State = {
  reviews: Array<Object>,
  shoeReviews: Object,
  footSurvey: Object,
  totalCount: number
}

const initialState = {
  reviews: [],
  shoeReviews : {},
  footSurvey: {},
  totalCount: 0
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.SET: {
        draft.reviews = action.payload.reviews
        draft.totalCount = action.payload.totalCount
        draft.shoeReviews = action.payload.shoeReviews
        draft.footSurvey = action.payload.footSurvey
        return
      }
    }
  })
}

/*==================================
* Selector
==================================*/
