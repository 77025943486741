// @flow
import React from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'
import constants from '@src/constants'

type ExternalProps = {
  children: any
}

type Props = ExternalProps

/*==================================
* enhance
==================================*/

export const enhancePresentation = compose()

/*==================================
* View
==================================*/

export default function({ children }: Props) {
  return (
    <Root>
      <main>{children}</main>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  max-width: ${constants.LAYOUT_MAX_WIDTH}px;
  min-height: 100%;
  background: #fff;
  margin: 0 auto;
`
