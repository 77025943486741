// @flow
import React from 'react'
import styled from 'styled-components'

import Inner from '@src/components/templates/Inner'
import Button from '@src/components/atoms/Button'
import CheckBoxList from '@src/components/molecules/CheckBoxList'
import FullModal from '@src/components/organisms/FullModal'

type Props = {
  modalRef: any,
  isShown: boolean,
  formRef: { current: null | HTMLFormElement },
  checkBoxes: Array<{ value: string, label: string, defaultChecked: boolean }>,
  handleSave: (e: any) => void,
  handleCancel: (e: any) => void
}

export default function Presentation({
  isShown,
  formRef,
  checkBoxes,
  handleCancel,
  handleSave
}: Props) {
  //Reference ref={modalRef} {...arguments[0]}>
  //</Reference>
  return (
    <FullModal title={'ショップ'} isShown={isShown} onClose={handleCancel}>
      <Inner>
        <form ref={formRef}>
          <CheckBoxList name={'store'} checkBoxes={checkBoxes} />
        </form>
      </Inner>
      <Footer>
        <Button type={'primary'} onClick={handleSave}>
          保存する
        </Button>
      </Footer>
    </FullModal>
  )
}

const Footer = styled(Inner)`
  border-top: 1px solid ${({ theme }) => theme.color.borderGray};
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  bottom: 0;
  background: #fff;
`
