// @flow

/* モデル：ユーザ情報 */
export default class UserInfoModel {
  nickname: string
  email: string
  birthDate: string
  gender: string
  address: string
  occupation: string
  // 画像が無い場合は空文字が入る模様
  profileImgUrl: '' | string
  friendstatus: ?string

  // 追加・加工パラメータ
  // なし

  constructor(raw: any) {
    Object.assign(this, {
      ...raw
    })
  }
}
