// @flow
import produce from 'immer'
import { createActionThunk } from 'redux-thunk-actions'
import { withProps } from 'recompose'
import {
  findHeelHeightData,
  isValueConformToTag
} from '@src/helpers/recommendTagHelper'

import RecommendationItemModel from '@src/models/RecommendationItemModel'

// export type TagKey =
//   | 'categoryName'
//   | 'shoeTypeName'
//   | 'targetName'
//   | 'heelTypeName'
//   | 'ffColorId'
//   | 'ffMaterial'
//   | 'patternName'
//   | 'toe'
//   | 'heelHeight'
//   | 'strap'
//   | 'shoelace'
//   | string
//
// const TAG_KEYS: Array<TagKey> = [
//   'targetName',
//   'categoryName',
//   'shoeTypeName',
//   'heelTypeName',
//   'ffColorId',
//   'ffMaterial',
//   'patternName',
//   'toe',
//   'heelHeight',
//   'strap',
//   'shoelace'
// ]

// クライアントサイドで絞り込むことになったので、基本的に
// キー名はおすすめアイテムのプロパティに合わせることにした。
export type TagKey =
  | 'brandName'
  | 'categoryName'
  | 'shoeTypeName'
  | 'targetName'
  | 'strap'
  | 'shoelace'
  | 'fit'
  | 'heelTypeName'
  | 'heelHeight'
  | 'colorId'
  | 'material'
  | string

const TAG_KEYS: Array<TagKey> = [
  'brandName',
  'categoryName',
  'shoeTypeName',
  'targetName',
  'heelTypeName',
  'heelHeight',
  'strap',
  'shoelace',
  'fit',
  'colorId',
  'material'
]

export type TagStandard = {
  name: string,
  id?: number,
  //parent?: number,
  heelHeightData?: {
    label: string,
    lower: null | number,
    upper: null | number
  },
  bool?: boolean
}

function createNameTypeTags(names: Array<string>) {
  // 重複を排除
  const arr = [...new Set(names)]
  // タグデータに変換
  return arr.map(name => ({
    name: name
  }))
}

function createIdTypeTags(ids: Array<number>) {
  // 重複を排除
  const arr = [...new Set(ids)]
  // タグデータに変換
  return arr.map(id => ({
    name: `${id}`,
    id: id
  }))
}

function createHeelHeightTags(values: Array<number>) {
  const rawTags = values
    .map(val => findHeelHeightData(val))
    .filter(heelHeightData => heelHeightData !== undefined)
  // 重複を排除
  const arr: Array<any> = [...new Set(rawTags)]
  // そのままだと型の都合で扱いづらいのでheelHeightDataオブジェクトとしてまとめる
  return arr.map(heelHeightData => ({
    name: heelHeightData.label,
    heelHeightData: {
      ...heelHeightData
    }
  }))
}

function createBoolTags(values: Array<boolean>) {
  // 重複を排除
  const arr = [...new Set(values)]
  // タグに変換
  return arr.map(val => ({
    name: val ? 'あり' : 'なし',
    bool: val
  }))
}

/*
 * おすすめ一覧ページでのおすすめ条件タグの選択状態を取り扱うストアです
 */

/*==================================
* Actions
==================================*/
const THUNK = {}
const REDUCER = {}

// Public
THUNK.INIT = 'recommendationCondition/THUNK.INIT'
THUNK.RESET_SELECTED_TAGS = 'recommendationCondition/THUNK.RESET_SELECTED_TAGS'
THUNK.SELECT_TAG = 'recommendationCondition/THUNK.SELECT_TAG'
THUNK.SELECT_TAG_BY_VALUE = 'recommendationCondition/THUNK.SELECT_TAG_BY_VALUE'
THUNK.UNSELECT_TAG = 'recommendationCondition/THUNK.UNSELECT_TAG'

// Private
REDUCER.INSTALL_TAGS_COLLECTION =
  'recommendationCondition/REDUCER.INSTALL_TAGS_COLLECTION'
REDUCER.SET_SELECTED_TAGS = 'recommendationCondition/REDUCER.SET_SELECTED_TAGS'
/*==================================
* Action Creators
==================================*/
export const recommendationConditionActionCreators = {
  init: createActionThunk(
    THUNK.INIT,
    async ({ dispatch, getState }): Promise<void> => {
      // おすすめ一覧データを参照し、タグ群を作成しておく
      const items: Array<RecommendationItemModel> = getState().recommendation
        .items

      // おすすめ一覧に存在する値を全部集める
      const collectedValues = {
        brandName: [],
        categoryName: [],
        targetName: [],
        shoeTypeName: [],
        heelTypeName: [],
        colorId: [],
        material: [],
        fit: [],
        heelHeight: [],
        strap: [],
        shoelace: []
      }
      const keys = Object.keys(collectedValues)
      for (let i = 0; i < items.length; i += 1) {
        keys.forEach(key => {
          let orgKey = key 
          if (key == 'colorId')  orgKey = items[i][key] ? key : 'ffColorId'
          if (key == 'material')  orgKey = items[i][key] ? key : 'ffMaterial'
          collectedValues[key][i] = items[i][orgKey]
        })
      }

      // 集めたデータをもとにタグをつくる
      // ＊ 元々は一覧のデータをもとにタグをつくってはいなかった。ここで元の形に合わせ押し込む。
      const tagsCollection = {
        brandName: createNameTypeTags(collectedValues.brandName),
        categoryName: createNameTypeTags(collectedValues.categoryName),
        targetName: createNameTypeTags(collectedValues.targetName),
        shoeTypeName: createNameTypeTags(collectedValues.shoeTypeName),
        heelTypeName: createNameTypeTags(collectedValues.heelTypeName),
        colorId: createIdTypeTags(collectedValues.colorId),
        material: createNameTypeTags(collectedValues.material),
        fit: createNameTypeTags(collectedValues.fit),
        // 以下のデータは固定にする
        heelHeight: createHeelHeightTags(collectedValues.heelHeight),
        strap: createBoolTags(collectedValues.strap),
        shoelace: createBoolTags(collectedValues.shoelace)
      }

      dispatch({
        type: REDUCER.INSTALL_TAGS_COLLECTION,
        payload: {
          tagsCollection
        }
      })
    }
  ),

  resetSelectedTags: createActionThunk(
    THUNK.RESET_SELECTED_TAGS,
    async ({ dispatch }): Promise<void> => {
      const selectedTags = {}
      TAG_KEYS.forEach(tagKey => {
        selectedTags[tagKey] = null
      })
      dispatch({
        type: REDUCER.SET_SELECTED_TAGS,
        payload: {
          selectedTags
        }
      })
    }
  ),

  selectTag: createActionThunk(
    THUNK.SELECT_TAG,
    async (
      tagKey: string,
      tagIndex: number,
      { dispatch, getState }
    ): Promise<void> => {
      const {
        selectedTags,
        tagsCollection
      } = getState().specifics.recommendationCondition

      dispatch({
        type: REDUCER.SET_SELECTED_TAGS,
        payload: {
          selectedTags: {
            ...selectedTags,
            [tagKey]: tagsCollection[tagKey][tagIndex]
          }
        }
      })
    }
  ),

  // おすすめ商品詳細下部のタグをクリックした時の処理として追加
  selectTagByValue: createActionThunk(
    THUNK.SELECT_TAG_BY_VALUE,
    async (
      tagKey: string,
      value: number | string,
      { dispatch, getState }
    ): Promise<void> => {
      const {
        selectedTags,
        tagsCollection
      } = getState().specifics.recommendationCondition

      if (!tagsCollection[tagKey]) throw Error('指定できないタグ種別')

      // 該当タグをさがしてindex番号を確保
      let tagIndex = null
      tagsCollection[tagKey].forEach((_, i) => {
        const tag = tagsCollection[tagKey][i]
        if (isValueConformToTag(value, tag, tagKey)) tagIndex = i
      })
      if (tagIndex === null) throw Error('該当するタグが存在しない')

      dispatch({
        type: REDUCER.SET_SELECTED_TAGS,
        payload: {
          selectedTags: {
            ...selectedTags,
            [tagKey]: tagsCollection[tagKey][tagIndex]
          }
        }
      })
    }
  ),

  unselectTag: createActionThunk(
    THUNK.UNSELECT_TAG,
    async (tagKey: string, { dispatch, getState }): Promise<void> => {
      const { selectedTags } = getState().specifics.recommendationCondition

      let updated = {}
      // if (tagKey === 'categoryName') {
      //   // 仕様: categoryNameタグが選択解除される場合は
      //   //      親子関係にあるshoeTypeNameタグも選択解除される
      //   updated = {
      //     ...selectedTags,
      //     ['categoryName']: null,
      //     ['shoeTypeName']: null
      //   }
      // } else {
      updated = {
        ...selectedTags,
        [tagKey]: null
      }
      // }

      dispatch({
        type: REDUCER.SET_SELECTED_TAGS,
        payload: {
          selectedTags: updated
        }
      })
    }
  )
}

/*==================================
Initial State
==================================*/
type State = {
  selectedTags: {
    [tagKey: string]: ?TagStandard
  },
  tagsCollection: {
    [tagKey: string]: Array<TagStandard>
  }
}

const initialState = {
  selectedTags: {
    ...(() => {
      const hash = {}
      TAG_KEYS.forEach(key => (hash[key] = null))
      return hash
    })()
  },
  tagsCollection: {
    ...(() => {
      const hash = {}
      TAG_KEYS.forEach(key => (hash[key] = []))
      return hash
    })()
  }
}

/*==================================
* Reducer
==================================*/
export default function reducer(
  state: State = initialState,
  action: Object = {}
) {
  return produce(state, draft => {
    switch (action.type) {
      case REDUCER.SET_SELECTED_TAGS: {
        draft.selectedTags = action.payload.selectedTags
        return
      }
      case REDUCER.INSTALL_TAGS_COLLECTION: {
        draft.tagsCollection = action.payload.tagsCollection
        return
      }
    }
  })
}

/*==================================
* Selector
==================================*/
type WithTagKeys = {
  tagKeys: Array<TagKey>
}
export const withTagKeys = withProps(
  ({}): WithTagKeys => {
    return {
      tagKeys: [...TAG_KEYS]
    }
  }
)
