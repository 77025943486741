// @flow
import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import BooleanList from './parts/BooleanList'
import StringList from './parts/StringList'
import ColorList from './parts/ColorList'
//import HeelHeightList from './parts/HeelHeightList'
import map from 'lodash/map'
import compact from 'lodash/compact'

import type {
  TagKey,
  TagStandard
} from '@src/redux/modules/specifics/recommendationCondition'

type Props = {
  tagKeys: Array<TagKey>,
  selectedTags: { [tagKey: string]: TagStandard },
  nonSelectTags: { [tagKey: string]: Array<TagStandard> },
  handleSelectTag: (selectedTagKey: TagKey, selectedTagIndex: number) => void,
  handleRemoveTag: (selectedTagKey: TagKey) => void
}

// $FlowFixMe: compose() の型付けが合わないらしい
class RecommendMenu extends Component<Props, { prevTagSize: number }> {
  ref: any

  state = {
    prevTagSize: 0
  }

  constructor(props: any) {
    super(props)
    this.ref = React.createRef()
  }

  handleSelect = (selectedTagKey: TagKey, selectedTagIndex: number) => {
    this.props.handleSelectTag(selectedTagKey, selectedTagIndex)
  }

  handleRemove = (selectedTagKey: TagKey) => {
    this.props.handleRemoveTag(selectedTagKey)
  }

  componentDidUpdate() {
    const selectTags = compact(
      map(this.props.selectedTags, (tag: TagStandard) => {
        if (tag !== null) return tag
      })
    )
    if (selectTags.length !== this.state.prevTagSize) {
      window.scrollTo(0, 0)
      this.ref.current.scrollTo(0, 0)
      this.setState({ prevTagSize: selectTags.length })
    }
  }

  render() {
    const { tagKeys, selectedTags, nonSelectTags, ...rest } = this.props

    console.log("RecommendMenu", tagKeys)
    console.log("RecommendMenu selectedTags", selectedTags)
    console.log("RecommendMenu nonSelectTags", nonSelectTags)

    return (
      <Root ref={this.ref} {...rest}>
        <Wrapper>
          {/* 選択済み項目 */}
          <Ul>
            {tagKeys.map(key => {
              if (!selectedTags[key]) {
                // 未選択なら表示しない
                return <Fragment key={`select-${key}`} />
              }

              const tag: TagStandard = selectedTags[key]
              if (key === 'shoelace' || key === 'strap') {
                return (
                  <BooleanList
                    key={`select-${key}`}
                    tag={key}
                    label={tag.bool || false}
                    onRemove={() => this.handleRemove(key)}
                  />
                )
              }
              if (key === 'heelHeight') {
                return (
                  <StringList
                    key={`select-${key}`}
                    tag={key}
                    beforeLabel={'ヒール高 '}
                    label={tag.name}
                    onRemove={() => this.handleRemove(key)}
                  />
                )
              }
              if (key === 'colorId') {
                return (
                  <ColorList
                    key={`select-${key}`}
                    colorId={tag.id || 0}
                    onRemove={() => this.handleRemove(key)}
                  />
                )
              }
              return (
                <StringList
                  key={`select-${key}`}
                  tag={key}
                  label={tag.name}
                  onRemove={() => this.handleRemove(key)}
                />
              )
            })}
          </Ul>

          {/* 未選択項目 */}
          {tagKeys.map((key: string) => {
            if (selectedTags[key]) {
              // 選択済みなら表示しない
              return <Fragment key={`non-select-${key}`} />
            }
            if (nonSelectTags[key].length === 1) {
              // 仕様: 選択できるタグが１種類しかないタグ種別のタグは選択肢として表示しない
              return <Fragment key={`non-select-${key}`} />
            }

            // // 仕様: shoeTypeNameタグだけは親子関係を元に表示制御する
            // //      選択中の親categoryNameタグのidを控えておく
            // const selectedCategoryNameTagId = selectedTags['categoryName']
            //   ? selectedTags['categoryName'].id
            //   : null

            return (
              <Ul key={`non-select-${key}`}>
                {nonSelectTags[key].map((tag: TagStandard, idx: number) => {
                  // if (key === 'shoeTypeName') {
                  //   // 仕様: shoeTypeNameタグだけは親子関係を元に表示制御する
                  //   //      選択中のcategoryNameタグが親であるタグだけを表示する
                  //   //      categoryNameタグが未選択なら全て非表示
                  //   if (
                  //     selectedCategoryNameTagId &&
                  //     tag.parent === selectedCategoryNameTagId
                  //   ) {
                  //     return (
                  //       <StringList
                  //         key={`non-select-${key}-${idx}`}
                  //         tag={key}
                  //         label={tag.name}
                  //         onSelect={() => this.handleSelect(key, idx)}
                  //       />
                  //     )
                  //   }else {
                  //     return (
                  //       <li
                  //         key={`non-select-${key}-${idx}`}
                  //         style={{ display: 'none' }}
                  //       />
                  //     )
                  //   }
                  // }
                  if (key === 'shoelace' || key === 'strap') {
                    return (
                      <BooleanList
                        key={`non-select-${key}-${idx}`}
                        tag={key}
                        label={tag.bool || false}
                        onSelect={() => this.handleSelect(key, idx)}
                      />
                    )
                  }
                  if (key === 'heelHeight') {
                    return (
                      <StringList
                        key={`non-select-${key}-${idx}`}
                        tag={key}
                        beforeLabel={'ヒール高 '}
                        label={tag.name}
                        onSelect={() => this.handleSelect(key, idx)}
                      />
                    )
                  }
                  if (key === 'colorId') {
                    return (
                      <ColorList
                        key={`non-select-${key}-${idx}`}
                        colorId={tag.id || 0}
                        onSelect={() => this.handleSelect(key, idx)}
                      />
                    )
                  }
                  if (key === 'shoeTypeName' && tag.name === 'none') {
                    // 仕様: shoeTypeNameタグで値が"none"のタグは絞り込み・商品詳細に表示しない
                    return ''
                  }
                  return (
                    <StringList
                      key={`non-select-${key}-${idx}`}
                      tag={key}
                      label={tag.name}
                      onSelect={() => this.handleSelect(key, idx)}
                    />
                  )
                })}
              </Ul>
            )
          })}
        </Wrapper>
      </Root>
    )
  }
}

export default RecommendMenu

const Root = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  padding: 0 30px;
  &:after {
    content: ' ';
    min-width: 30px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 75px;
  margin: 0 -7px;
`

const Ul = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    margin: 0 7px;
  }
`
