// @flow
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import icoArrowRightImg from '@src/assets/img/ico_arrow_right_b.png'

type Props = {
  path: string,
  text: string
}

export default function({ path, text }: Props) {
  return (
    <ArrowLink>
      <Link to={path}>
        {text}
        <i>
          <img src={icoArrowRightImg} alt="" width={13} />
        </i>
      </Link>
    </ArrowLink>
  )
}

const ArrowLink = styled.span`
  text-align: right;
  font-size: medium;
  i {
    margin-left: 0.5em;
    position: relative;
    top: -1px;
  }
`
