// @flow

type SelectOption = {
  value: string | number,
  label: string | number
}

export default class FormConstants {
  inputBlockTitles: {
    [propertyName: string]: string
  }
  selectOptions: {
    birthdayYear: Array<SelectOption>,
    birthdayMonth: Array<SelectOption>,
    birthdayDay: Array<SelectOption>,
    address: Array<SelectOption>,
    occupation: Array<SelectOption>
  }

  static get inputBlockTitles() {
    return {
      nickname: 'ユーザー名',
      email: 'メールアドレス',
      password: 'パスワード',
      birthDate: '生年月日',
      gender: '性別',
      address: 'お住まいの都道府県',
      occupation: '職業',
      stores: 'お気に入りのショップ'
    }
  }

  static get selectOptions() {
    const options = {
      birthdayYear: [],
      birthdayMonth: [],
      birthdayDay: [],
      address: [],
      occupation: []
    }
    for (let y = new Date().getFullYear(); y >= 1900; y -= 1) {
      options.birthdayYear.push({ value: y, label: y })
    }
    for (let m = 1; m <= 12; m += 1) {
      options.birthdayMonth.push({ value: m, label: m })
    }
    for (let d = 1; d <= 31; d += 1) {
      options.birthdayDay.push({ value: d, label: d })
    }
    addresss.forEach(val => {
      options.address.push({ value: val, label: val })
    })
    occupations.forEach(val => {
      options.occupation.push({ value: val, label: val })
    })
    return options
  }
}

const addresss = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県'
]
const occupations = [
  '会社員',
  '会社経営・役員',
  '自営業',
  '公務員',
  'その他の職業',
  '学生',
  '主婦',
  '無職'
]
