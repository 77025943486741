// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: any
}

export default function({ children, ...rest }: Props) {
  return <Inner {...rest}>{children}</Inner>
}

const Inner = styled.div`
  padding: 0 20px;
`
