// @flow
import { connect } from 'react-redux'
import { compose, withState, withHandlers } from 'recompose'
import withRefererableMethods from '@src/helpers/withRefererableMethods'
import analytics from '@src/analytics'
import constants from '@src/constants'

import UserFootModel from '@src/models/UserFootModel'
import FavoriteItemModel from '@src/models/FavoriteItemModel'
import type { DisplayItemInterface } from '@src/types'

type FeedbackInfo = {
  isLeftFit: boolean,
  isRightFit: boolean,
  points: {
    // L1: { tightOrLoose: null }
    [pointKey: string]: {
      tightOrLoose: null | 'tight' | 'loose'
    }
  }
}

const initialFeedbackInfo = (): FeedbackInfo => {
  const points = {}
  for (let i = 0; i < 7; i += 1) {
    points[`L${i + 1}`] = {
      tightOrLoose: null
    }
    points[`R${i + 1}`] = {
      tightOrLoose: null
    }
  }
  return {
    isLeftFit: true,
    isRightFit: true,
    points
  }
}

type Refererable = {
  showModal: (item: FavoriteItemModel | DisplayItemInterface) => void
}

type Attributes = {
  modalRef: { current: null | Refererable }
}

type Props = Attributes & {
  userFoot: UserFootModel | null,

  isShown: boolean,
  setIsShown: boolean => void,
  item: null | FavoriteItemModel,
  setItem: FavoriteItemModel => void,
  feedbackInfo: FeedbackInfo,
  updateFeedbackInfo: FeedbackInfo => void,
  currentSelectedPoint: null | string,
  setCurrentSelectedPoint: (null | string) => void,
  isCompleteFeedback: boolean,
  setIsCompleteFeedback: boolean => void
}

export const enhanceConnection = compose(
  connect(
    state => ({
      userFoot: state.user.data.foot
    }),
    null
  ),

  // モーダル表示非表示
  withState('isShown', 'setIsShown', false),
  // 表示アイテム
  withState('item', 'setItem', null),
  // フィードバック選択状態
  withState('feedbackInfo', 'updateFeedbackInfo', initialFeedbackInfo()),
  // 選択中の足ポイント
  withState('currentSelectedPoint', 'setCurrentSelectedPoint', null),
  // フィードバック完了フラグ
  withState('isCompleteFeedback', 'setIsCompleteFeedback', false),

  withHandlers({
    handleClickFit: ({ feedbackInfo, updateFeedbackInfo }: Props) => (
      whetherLR: 'L' | 'R',
      isFit: boolean
    ) => {
      const points = { ...feedbackInfo.points }
      const arr = [1, 2, 3, 4, 5, 6, 7]
      if (whetherLR === 'L') {
        if (isFit)
          arr.forEach(num => (points[`L${num}`] = { tightOrLoose: null }))

        updateFeedbackInfo({
          ...feedbackInfo,
          isLeftFit: isFit,
          points
        })
      } else {
        if (isFit)
          arr.forEach(num => (points[`R${num}`] = { tightOrLoose: null }))

        updateFeedbackInfo({
          ...feedbackInfo,
          isRightFit: isFit,
          points
        })
      }
    },

    handleClickPoint: ({ setCurrentSelectedPoint }: Props) => (
      pointKey: string
    ) => {
      setCurrentSelectedPoint(pointKey)
    },

    handleSelectTightOrLoose: ({
      feedbackInfo,
      updateFeedbackInfo,
      setCurrentSelectedPoint
    }: Props) => (pointKey: string, tightOrLoose: 'tight' | 'loose') => {
      updateFeedbackInfo({
        ...feedbackInfo,
        points: {
          ...feedbackInfo.points,
          [pointKey]: {
            tightOrLoose
          }
        }
      })
      setCurrentSelectedPoint(null)
    },

    handleSave: ({
      item,
      feedbackInfo,
      setIsCompleteFeedback
    }: Props) => async () => {
      // 送信データを取りまとめる
      const data = {}
      const arr = [1, 2, 3, 4, 5, 6, 7]

      // 「fitしない」になっている足のポイントキーの情報のみ有効
      if (!feedbackInfo.isLeftFit) {
        arr.forEach(num => {
          const tightOrLoose = feedbackInfo.points[`L${num}`].tightOrLoose
          if (!tightOrLoose) return
          data[`feedback_L${num}`] = tightOrLoose
        })
      }
      if (!feedbackInfo.isRightFit) {
        arr.forEach(num => {
          const tightOrLoose = feedbackInfo.points[`R${num}`].tightOrLoose
          if (!tightOrLoose) return
          data[`feedback_R${num}`] = tightOrLoose
        })
      }
      // data = {
      //   feedback_L1: 'tight' | 'loose'
      //   feedback_L2: 'tight' | 'loose'
      //   ...
      //   feedback_R7: 'tight' | 'loose'
      // }
      if (!item) throw Error('program error')
      analytics.sendFeedbackToGA(item.itemUuid, data)

      // 送信完了
      setIsCompleteFeedback(true)
    },

    closeModal: ({ setIsShown }: Props) => () => {
      setIsShown(false)
    }
  }),

  withRefererableMethods('modalRef', {
    showModal(_item: FavoriteItemModel | DisplayItemInterface) {
      const {
        userFoot,
        setItem,
        setIsShown,
        updateFeedbackInfo,
        setCurrentSelectedPoint,
        setIsCompleteFeedback
      } = this.props
      if (!userFoot) {
        alert('計測履歴がないためフィードバックできません')
        return
      }

      // 型キャスト（無理やり）
      const item = ((_item: any): FavoriteItemModel)

      // アクセス解析 送信
      const pageId = constants.PSEUDO_PAGE_PATH_MODAL_FEEDBACK.replace(
        ':itemUuid',
        String(item.itemUuid)
      )
      analytics.sendAnalyticsData(pageId, item.itemUuid)

      // 初期化
      setItem(item)
      updateFeedbackInfo(initialFeedbackInfo())
      setCurrentSelectedPoint(null)
      setIsCompleteFeedback(false)

      setIsShown(true)
    }
  })
)
