// @flow
import React from 'react'
// import styled from 'styled-components'

import DefaultPageLayout from '@src/components/templates/DefaultPageLayout'
import Section from '@src/components/templates/Section'
import Inner from '@src/components/templates/Inner'
import Text from '@src/components/atoms/Text'
import Button from '@src/components/atoms/Button'

type Props = {}

function NotFoundPage({  }: Props) {
  return (
    <DefaultPageLayout>
      <Section>
        <Inner>
          <Text textAlign={'center'} size={14} margin={20}>
            404 Not Found
            <br />
            指定のページは存在しません。
          </Text>
          <Text margin={20}>
            指定したURLが間違っているか、お探しのページが移動もしくは削除された可能性があります。
            <br />
            お手数ですが、トップページから再度お求めのページをお探しください。
          </Text>
          <div className={'align-center'}>
            <Button href={'/'} type={'success'}>
              flicfit トップへ
            </Button>
          </div>
        </Inner>
      </Section>
    </DefaultPageLayout>
  )
}

export default NotFoundPage
