// @flow
import React from 'react'
import styled from 'styled-components'

type Props = {
  children: any,
  name?: string,
  checked?: boolean,
  onChange?: any => void
}

export default function({ children, name, checked, onChange }: Props) {
  return (
    <Root>
      <div>
        <Checkbox>
          <input
            type="checkbox"
            name={name || ''}
            checked={checked}
            onChange={onChange}
          />
          <span>
            <i className="checkbox-checkmark fas fa-check" />
          </span>
        </Checkbox>
      </div>

      <div>{children}</div>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  margin-bottom: 15px;
  > div:nth-child(1) {
    flex-basis: 30px;
    padding: 5px 15px 0 0;
  }
  > div:nth-child(2) {
    flex-basis: auto;
    line-height: 30px;
  }
`

const Checkbox = styled.label`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 1px;
  position: relative;
  > input {
    display: none;
  }

  > span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.color.lightGray};
    border-radius: 3px;
  }
  > input:checked + span {
    border: none;
    background-color: ${({ theme }) => theme.color.yellow};
  }

  .checkbox-checkmark {
    color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 11px;
  }
  > input:checked + span .checkbox-checkmark {
    color: ${({ theme }) => theme.color.black};
  }
`
