// @flow
import React from 'react'
import styled from 'styled-components'

import DefaultPageLayout from '@src/components/templates/DefaultPageLayout'
import Inner from '@src/components/templates/Inner'
import PageTitle from '@src/components/molecules/PageTitle'
import Section from '@src/components/templates/Section'
import Block from '@src/components/templates/Block'
import BlockTitle from '@src/components/atoms/BlockTitle'
import FitShoes from '@src/components/organisms/FitShoes'
import Measured from '@src/components/molecules/Measured'
import FeetRecommendSize from '@src/components/organisms/FeetRecommendSize'
import FeetPartsSize from '@src/components/organisms/FeetPartsSize'
import FeetType from '@src/components/organisms/FeetType'
import Button from '@src/components/atoms/Button'
import Text from '@src/components/atoms/Text'
import Foot3d from '@src/components/organisms/Foot3d'

import theme from '@src/misc/theme'
import FootModel from '@src/models/FootModel'
import FavoriteItemModel from '@src/models/FavoriteItemModel'

import NotFoundPage from '@src/components/pages/NotFoundPage'

import handImg from '@src/assets/img/img_hand.png'
import memberImg from '@src/assets/img/img_member.png'
import bgMemberImg from '@src/assets/img/bg_member.png'
import icoPenImg from '@src/assets/img/ico_pen.png'

type ExternalProps = {
  is404PageShown: boolean,
  pumaUrl: String,
  fitItems: Array<FavoriteItemModel>,
  foot: ?FootModel
}

type Props = ExternalProps & {}

export default function Presentation({
  is404PageShown,
  pumaUrl,
  fitItems,
  foot
}: Props) {
  if (is404PageShown) {
    return <NotFoundPage />
  }

  if (!foot) {
    // データ取得中の場合の表示（取得中はfootの値はnullなので表示できない）
    return (
      <DefaultPageLayout>
        <Section>&nbsp;</Section>
      </DefaultPageLayout>
    )
  }

  const fitShoesItems = fitItems

  return (
    <DefaultPageLayout>
    {/*
      <Hero>
        <h1>
          ショップスタッフに
          <br />
          リストを見せて靴を試着しよう
        </h1>
        <img src={handImg} alt="" width={114} />
      </Hero>
      <Section isBorder={true} paddingBottom={10}>
        <Inner>
          <PageTitle title="試着リスト" marginBottom={10} />
          <Text
            size={11}
            textAlign={'center'}
            color={theme.color.gray}
            margin={20}
          >
            {foot.storeName}
            &nbsp;／&nbsp;全
            {fitShoesItems.length}点
          </Text>
          <TwoColumnsShoesList>
            {fitShoesItems.map(item => (
              <li key={item.itemUuid}>
                <FitShoes item={item} />
              </li>
            ))}
          </TwoColumnsShoesList>
        </Inner>
      </Section>
      */}


      {pumaUrl != null && 
      <Section paddingBottom={0}>
        <Inner>
        <RegisterBox>
            <div>
              <h3>
                PUMA公式サイトで<br />アイテムをチェック！
              </h3>
              <Button href={pumaUrl} type={'success'} external={"_blank"}>
                <img src={icoPenImg} alt="" width={24} />
                PUMA公式サイトへ
              </Button>

            </div>
          </RegisterBox>




          <PageTitle title="足カルテ" />
          <Measured
            date={foot.displayDate}
            storeName={foot.storeName}
            isNew={true}
          />
          {/* 
          <Block>
            <BlockTitle>おすすめサイズ</BlockTitle>
            <FeetRecommendSize
              recommendedLength={foot.formattedMeasurement.left.footLength}
              recommendedWidth={foot.jisWidth}
              jisWidthJa={foot.jisWidthJa}
            />
          </Block>
          */}
          <Foot3d pcdFileUrl={foot.tmpThreeUrl} marginLeft={'-20px'} />
          <Block>
            <BlockTitle>各部位のサイズ</BlockTitle>
            <FeetPartsSize measuredValues={foot.formattedMeasurement} />
          </Block>
          <Block>
            <BlockTitle>足のタイプと特徴</BlockTitle>
            <FeetType
              img={foot.toeShapeType.illustPath}
              imgWidth={112}
              name={foot.toeShapeType.name}
              text={foot.toeShapeType.text}
            />
          </Block>
        </Inner>
      </Section>
      }
      {/* <Section paddingTop={0} */}
      {pumaUrl == null && 
      <Section>
        <Inner>
          <RegisterBox>
            <h2>
              <img src={memberImg} alt="flicfit MEMBER" width={80} />
            </h2>
            <div>
              <h3>
                Flicfitにメンバー登録して
                <br />
                足カルテを活用しよう！
              </h3>
              <p>
                Flicfitにメンバー登録をすると、あなたの足カルテ
                <br />
                情報からぴったりの靴をオススメします。
                <br />
              </p>
              <Button href={'/login'} type={'success'}>
                <img src={icoPenImg} alt="" width={24} />
                ログイン・新規メンバー登録
              </Button>

            </div>
          </RegisterBox>

        </Inner>
      </Section>
      }
    </DefaultPageLayout>
  )
}

const Hero = styled.div`
  background-color: ${({ theme }) => theme.color.yellow};
  text-align: center;
  h1 {
    font-size: 18px;
    line-height: 1.7;
    margin-bottom: 15px;
  }
  img {
    position: relative;
    left: -30px;
  }
`
const TwoColumnsShoesList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  li {
    margin-bottom: 25px;
    width: 46%;
  }
`
const RegisterBox = styled.div`
  border: 2px solid ${({ theme }) => theme.color.yellow};
  border-radius: 6px;
  padding-bottom: 10px;
  h2 {
    background: no-repeat bottom right url(${bgMemberImg})
      ${({ theme }) => theme.color.yellow};
    background-size: 215px auto;
    padding: 40px 0 40px 35px;
  }
  div {
    padding: 25px 10px 0px;
    text-align: center;
  }
  div.login {
    padding: 25px 10px 30px;
    text-align: center;
  }
  div.loginbtn {
    height: 24px;
    padding: 0;
    width:inherit;
    display: table-cell;
    vertical-align: middle;
  }
  div.loginbtnoya {
    padding: 0;
    display: table;
    width:100%;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.7;
    margin-bottom: 15px;
  }
  p {
    font-size: 11px;
    margin-bottom: 15px;
  }
`