// @flow
import * as React from 'react'
//import styled from 'styled-components'
import { compose, withState } from 'recompose'
import withRefererableMethods from '@src/helpers/withRefererableMethods'
import analytics from '@src/analytics'
import constants from '@src/constants'

import ImageGallery from 'react-image-gallery'
import '@src/assets/css/image-gallery.css'

import Modal from '@src/components/organisms/Modal'
import FeetType from '@src/components/organisms/FeetType'

import { toeShapeTypes } from '@src/misc/resources'

// 足タイプのキーコードリスト
const toeShapeTypeCodeList = Object.keys(toeShapeTypes)

// 足タイプの画像リスト
const IMAGES = toeShapeTypeCodeList.map(code => ({
  original: toeShapeTypes[code].illustPath
}))

type Refererable = {
  showModal: () => void
}

type ExternalProps = {
  modalRef: { current: null | Refererable }
}

type Props = ExternalProps & {
  isShown: boolean,
  setIsShown: boolean => void,
  currentIndex: null | number,
  setCurrentIndex: number => any
}

export const enhancePresentation = compose(
  // モーダルの開閉状態
  withState('isShown', 'setIsShown', false),

  // 表示中の画像のインデックス
  withState('currentIndex', 'setCurrentIndex', null),

  withRefererableMethods('modalRef', {
    // モーダルオープン
    showModal(initialDisplayToeShapeTypeCode: string) {
      const { setIsShown, setCurrentIndex } = this.props

      // アクセス解析 送信
      const pageId = constants.PSEUDO_PAGE_PATH_MODAL_FEET_TYPES
      analytics.sendAnalyticsData(pageId)

      toeShapeTypeCodeList.forEach((code: string, i) => {
        if (code === initialDisplayToeShapeTypeCode) setCurrentIndex(i)
      })
      setIsShown(true)
    }
  })
)

const Presentation = function({
  isShown,
  setIsShown,
  currentIndex,
  setCurrentIndex
}: Props) {
  if (currentIndex === null) return ''
  const code = toeShapeTypeCodeList[currentIndex]
  const toeShapeType = toeShapeTypes[code]

  return (
    <Modal
      title={'足のタイプと特徴'}
      isShown={isShown}
      onClose={() => setIsShown(false)}
    >
      <div className={'feetTypeGallery'}>
        <ImageGallery
          items={IMAGES}
          showThumbnails={false}
          showBullets={true}
          startIndex={currentIndex}
          onSlide={index => {
            setCurrentIndex(index)
          }}
        />
        <FeetType name={toeShapeType.name} text={toeShapeType.text} />
      </div>
    </Modal>
  )
}

export default Presentation
