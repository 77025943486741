import { connect } from 'react-redux'
//import { bindActionCreators } from 'redux'
import { compose } from 'recompose'

export const enhanceConnection = compose(
  connect(
    state => ({
      toasts: state.toast.toasts
    }),
    null
  )
)
