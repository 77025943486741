// @flow

const PRESET_MESSAGES = {
  REQUIREMENTS: {
    DEFAULT: '入力必須です',
    birthdayYear: '年月日をそれぞれ選択してください',
    birthdayMonth: '年月日をそれぞれ選択してください',
    birthdayDay: '年月日をそれぞれ選択してください',
    gender: '選択してください',
    address: '選択してください',
    occupation: '選択してください'
  }
}

type Params = {
  [parameterKey: string]: string
}
type DateRequirements = {
  year: string,
  month: string,
  day: string
}

type Requirements = {
  [parameterKey: string]:
    | true
    | {
        message?: string,
        errorInfoKey?: string
      }
}
type MaxLengthLimits = {
  [parameterKey: string]: {
    value: number
  }
}
type ErrorInfo = {
  [parameterKey: string]: '' | string
}

export default class FormValidation {
  params: Params
  requirements: Requirements
  dateRequirements: DateRequirements
  maxLengthLimits: MaxLengthLimits
  errorInfo: ErrorInfo

  constructor(params: Params) {
    this.params = params

    this.requirements = {}
    this.dateRequirements = {  
      year: '',
      month: '',
      day: ''
    }
    this.maxLengthLimits = {}

    this.errorInfo = {}
  }

  setRequirements(requirements: Requirements): FormValidation {
    this.requirements = requirements
    return this
  }

  setDateRequirements(dateRequirements: DateRequirements): FormValidation {
    this.dateRequirements = dateRequirements
    return this
  }


  setMaxLengthLimits(maxLengthLimits: MaxLengthLimits): FormValidation {
    this.maxLengthLimits = maxLengthLimits
    return this
  }

  checkRequirements(): FormValidation {
    Object.keys(this.requirements).forEach(key => {
      let options = this.requirements[key]
      if (options === true) options = {}
      const errorInfoKey = options.errorInfoKey || key

      if (!this.params[key]) {
        this.errorInfo[errorInfoKey] =
          options.message ||
          PRESET_MESSAGES.REQUIREMENTS[key] ||
          PRESET_MESSAGES.REQUIREMENTS.DEFAULT
      }
    })
    return this
  }

  checkDateRequirements(): FormValidation {
    const ary = []
    ary.push(this.dateRequirements.year)
    ary.push(this.dateRequirements.month)
    ary.push(this.dateRequirements.day)
    const emptyCnt =  ary.filter(e => e == '').length 
    if ( emptyCnt != 3 && emptyCnt != 0)  {
        this.errorInfo.birthDate = PRESET_MESSAGES.REQUIREMENTS.birthdayYear

    }

    console.log(this.errorInfo)
    return this
  }

  checkMaxLengthLimits(): FormValidation {
    Object.keys(this.maxLengthLimits).forEach(key => {
      const options = this.maxLengthLimits[key]
      const maxLength = options.value

      if (this.params[key] && String(this.params[key]).length > maxLength) {
        this.errorInfo[key] = `${maxLength}文字以内で入力してください`
      }
    })
    return this
  }

  checkEquality(
    key1: string,
    key2: string,
    message: string,
    errorInfoKey?: string
  ): FormValidation {
    if (
      this.params[key1] &&
      this.params[key2] &&
      this.params[key1] !== this.params[key2]
    ) {
      this.errorInfo[errorInfoKey || key1] = message
    }
    return this
  }

  get validationErrorInfo(): ErrorInfo {
    return this.errorInfo
  }

  get hasValidationError(): boolean {
    return Object.keys(this.errorInfo).length >= 1
  }
}
