// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { appActionCreators } from '@src/redux/modules/app'
import { userActionCreators } from '@src/redux/modules/user'
import { compose, withHandlers } from 'recompose'
import commonHandlers from '@src/commonHandlers'

type Props = {
  history: any,
  appActions: any,
  userActions: any
}

export const enhanceConnection = compose(
  connect(
    null,
    dispatch => ({
      appActions: bindActionCreators({ ...appActionCreators }, dispatch),
      userActions: bindActionCreators({ ...userActionCreators }, dispatch)
    })
  ),

  withHandlers({
    handleClickWithdraw: ({ appActions, userActions }: Props) => async () => {
      appActions.showLoading()
      try {
        await userActions.deleteUser()
      } catch (err) {
        commonHandlers.handleAsyncLogicError(err)
        return
      } finally {
        appActions.hideLoading()
      }

      // 強制的に画面遷移
      window.location.href = '/login'
    },

    handleClickCancel: ({ history }: Props) => () => {
      history.goBack()
    }
  })
)
